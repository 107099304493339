import * as React from 'react';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles({
BackBtn: {
  color: '#fff',
  border: '1px solid #5F46D4',
  backgroundColor: '#5F46D4',
  margin: '2px',
'&:hover': {
    color: '#fff', 
    border: '1px solid #5F46D4',
    backgroundColor: '#5F46D4',
}},
})

const BackButton = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleGoBack = () => { 
    navigate(-1);
  };
  return(
    
    <Button
    size="small"
    className={classes.BackBtn}
    startIcon={<ArrowBackIcon style={{ fontSize: '14px' }} />}
    onClick={handleGoBack}
    >
    Back
    </Button>
  )
}
 export default BackButton;