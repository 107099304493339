import { useEffect, useReducer, useCallback } from "react";
import * as ErrorHandler from "../utils/errorHandler";
import { throwError } from "../services/apiHandler/handleSuccess/index";
import { ACTION_TYPE } from "./constants";
import useCredentials from "./useCredentials";

/**
 * @typedef State
 * @type {object}
 * @property {Boolean} loading
 * @property {object} error
 * @property {any} apiResponse
 * @property {any} data
 */

/**
 * @typedef HookConfig
 * @type {object}
 * @property {Function} selector
 * @property {Boolean} onMount
 * @property {Function} api - Api function (Required)
 * @property {Boolean} cache -
 * @property {Boolean} withAuth,
 * @property {Boolean} cancelOnUnmount
 * @property {any} initialData
 */

/**
 * @typedef HookParams
 * @type {object}
 * @property {Function} onSuccess
 * @property {Function} onFail
 *
 */

/**
 * @typedef HookReturn
 * @type {object}
 * @property {State} state
 * @property {Function} cancel
 * @property {Function} request
 * @property {Function} update
 */

const defaultHookConfig = {
  selector: (data) =>
    data && data.responseObject ? data.responseObject : data,
  onMount: false,
  cache: true,
  withAuth: true,
  cancelOnUnmount: true,
  initialData: {},
};

const defaultHookParams = { onSuccess: () => {}, onFail: () => {} };

/**
 *
 * @param {HookConfig} config
 */
const   createUseGet = (config) => {
  const hookConfig = { ...defaultHookConfig, ...config };

  const initialState = {
    loading: false,
    error: undefined,
    apiResponse: undefined,
    data: hookConfig.initialData,
  };

  function reducer(state, action) {
    switch (action.type) {
      case ACTION_TYPE.REQUEST:
        // console.log("request action called");
        return {
          ...(hookConfig.cache ? state : initialState),
          loading: true,
          error: undefined,
        };
      case ACTION_TYPE.SUCCESS:
        return {
          ...initialState,
          apiResponse: action.payload.apiResponse,
          data: action.payload.data,
        };
      case ACTION_TYPE.FAIL:
        return {
          ...(hookConfig.cache ? state : initialState),
          loading: false,
          error: action.payload,
        };
      case ACTION_TYPE.UPDATE:
        return {
          ...state,
          data: action.payload.updater(state.data),
        };
      case ACTION_TYPE.CANCEL:
        return {
          ...initialState,
          data: state.data,
        };
      default:
        return state;
    }
  }

  /**
   * @param {HookParams} hookParams
   * @returns {HookReturn}
   */
  const useGet = (passedHookParams = defaultHookParams) => {
    const hookParams = { ...defaultHookParams, ...passedHookParams };

    const [state, dispatch] = useReducer(reducer, initialState);
    const accessToken = useCredentials();
    const request = useCallback(
      async (requestParams) => {
        try {
          dispatch({ type: ACTION_TYPE.REQUEST });
          const payload = { data: requestParams };
          if (hookConfig.withAuth) {
            payload.accessToken = accessToken;
          }
          const apiResponse = await hookConfig.api(payload);
          const data = hookConfig.selector(apiResponse);
          hookParams.onSuccess({ apiResponse, data, requestParams });

          //! Handling if responseObject is null
          if (apiResponse.code === 404) {
            throwError(apiResponse);
          }

          dispatch({
            type: ACTION_TYPE.SUCCESS,
            payload: { apiResponse, data },
          });
        } catch (error) {
          if (error.message !== "cancel") {
            if (ErrorHandler.isAuthError(error)) {
              localStorage.clear();
            }
            hookParams.onFail(error);
            dispatch({
              type: ACTION_TYPE.FAIL,
              payload: ErrorHandler.createApiError(error),
            });
          } else {
            dispatch({ type: ACTION_TYPE.CANCEL });
          }
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [accessToken]
    );

    const update = useCallback(
      (updater) => {
        dispatch({ type: ACTION_TYPE.UPDATE, payload: { updater } });
      },
      [dispatch]
    );

    const cancel = useCallback(() => {
      if (hookConfig.api.cancel) {
        hookConfig.api.cancel("cancel");
        dispatch({ type: ACTION_TYPE.CANCEL });
      }
    }, [dispatch]);

    useEffect(() => {
      if (hookConfig.onMount) {
        request();
      }
      return () => {
        if (hookConfig.cancelOnUnmount) {
          cancel();
        }
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [request]);

    return { state, request, cancel, update };
  };

  return useGet;
};

export default createUseGet;
