import { fork, call, put, takeLatest, select } from "redux-saga/effects";
import { toast } from "react-toastify";

import { ROUTES } from "routes/constant";
import {
  AADHAR_VALIDATION_AND_SET_ADDRESS,
  REGISTER_MEMBER,
  ADDRESS_BY_PINCODE,
  BANK_DETAILS_BY_IFSC,
  GET_DOCUMENTS_DETAILS,
  GURANTOR,
  MEMBER_DETAILS_BY_APPLICATION_ID,
  MEMBER_DETAILS_BY_ID,
  MEMBER_LIST,
  PAYMENT_METHOD,
  UPLOAD_DOCUMENT,
  UPLOAD_DOCUMENT_URL,
  WITNESS,
  ADD_MEMBER,
  CHANGE_DOC_STATUS,
  FINALISE_APPLICATION,
  GET_PAYMENT_DETAILS,
} from "store/types";

import { dispatch } from "store/index";
import { defaultLoginParam } from "containers/auth/helper";

import * as ErrorHandler from "utils/errorHandler";

import {
  setFormData,
  updateReferalData,
  setPincodeDetails,
  setAddressDetails,
  setBankDetailsByIFSC,
  setMemberData,
  updateNomineeAddressDetail,
  updateGuarantorRequests,
  updateWitnessAndGuarantorRequests,
  setAadharData,
  setMemberApplicationData,
  setRequiredDocuments,
  updateRequiredDocuments,
  setNomineeDetails,
  updateGurdianStateAndCity,
  setActiveStep,
  setIsMemberEditable,
  resetState
} from "./reducers";
import END_POINTS from "services/endPoints";
import { values } from "lodash";
import Ujala from "services/api/ujala";
import { updateWitnessRequests } from "../../containers/members/reducers";
import { getCredentials } from "utils/helper";
import { push } from "redux-first-history";

// export function* addNewMemberFlow(data) {
//   const {
//     age,
//     formErrors,
//     memberData,
//     document,
//     filters,
//     payment,
//     current_same_as_aadhar_address,
//     permanent_same_as_current_address,
//     permanent_same_as_aadhar_address,

//     is_existing_member1,
//     witness_is_existing_member1,
//     witness_is_existing_member2,
//     guaranter_is_existing_member1,
//     guaranter_is_existing_member2,
//     ...otherObj
//   } = data;
//   try {
//     const { accessToken } = yield select(getCredentials);
//     const response = yield fetch(
//       `${process.env.REACT_APP_BASE_URL}cos-user-onboarding/member/registerMember`,
//       {
//         method: "POST",
//         body: JSON.stringify(otherObj),
//         headers: {
//           Authorization: `Bearer ${accessToken}`,
//           "Content-Type": "application/json",
//         },
//       }
//     );

//     const authResponse = yield response.json();

//     if (response.ok) {
//       if (authResponse.code === 200) {
//         toast.success(authResponse.message);
//         // dispatch(loginVerification());
//       } else {
//         toast.error(authResponse.message || "Unexpected error");
//       }
//     } else {
//       console.error(`API Error: ${response.statusText}`);
//       toast.error(`API Error: ${response.statusText}`);
//     }
//   } catch (e) {
//     toast.error(e?.message);
//     console.error("Error:", e);
//     // dispatch(loginFail(e));
//   }
// }

function* getAddressDetailsByPincodeFlow(apiData) {
  try {
    const { pincode, addType, index, thirdParam } = apiData;

    const { accessToken } = yield select(getCredentials);
    const apiPayload = { data: {} };
    apiPayload.data.pincode = pincode;
    apiPayload.accessToken = accessToken;
    const response = yield call(Ujala.getAddressDetails, apiPayload);
    if (response.code == 200) {
      const { city, state } = response.responseObject;
      const addressData = yield select((state) => state.addNewMember);
      const addressType = { ...addressData[addType] };
      addressType.city = city;
      addressType.state = state;
      yield put(setFormData({ name: addType, value: addressType }));

      if (thirdParam === "guardian") {
        dispatch(
          updateGurdianStateAndCity({ city: city, state: state, index: index })
        );
      }
      // TODO
      if (addType === "NOMINEE_DETAILS") {
        dispatch(updateNomineeAddressDetail({ index, city, state }));
      }
    }
  } catch (error) {
    toast.error(error?.message);
  }
}

function* getBankDetailsByIFSCFlow(apiData) {
  try {
    const { accessToken } = yield select(getCredentials);
    const apiPayload = {};
    apiPayload.data = apiData;
    apiPayload.accessToken = accessToken;
    const response = yield call(Ujala.getBankDetails, apiPayload);
    if (response.code == 200) {
      toast.success("OK");
      yield put(setBankDetailsByIFSC({...response.responseObject, ifsc: apiData.ifscCode }));
    } else {
      toast.error("Error");
    }
  } catch (error) {
    toast.error("Something went wrong");
  }
}

function* getMemberListSaga(apiData) {
  try {
    const { accessToken } = yield select(getCredentials);
    const apiPayload = {};
    apiPayload.data = apiData;
    apiPayload.accessToken = accessToken;
    const response = yield call(Ujala.getMemberList, apiPayload);
    if (response.code == 200) {
    } else {
      toast.error("Error");
    }
  } catch (error) {
    toast.error("Something went wrong");
  }
}

function* registerMemberSaga(apiData) {
  try {
    const {applicationId} = yield select(state=> state.addNewMember);
    const { accessToken } = yield select(getCredentials);
    const apiPayload = {};
    apiPayload.data = apiData;
    apiPayload.accessToken = accessToken;
    const response = yield call(Ujala.registerMember, apiPayload);
    if (response.code == 200) {
      if(applicationId){
        toast.success("Member updated successfully");
      }else {
        toast.success("Member registered successfully");
      }
        yield put(setFormData({name: "applicationId", value: response?.responseObject?.applicationId}))
      yield put(setActiveStep(2));
    } else {
      toast.error(response?.message);
    }
  } catch (error) {
    toast.error(error?.message);
  }
}

function* getMemberDetailsByApplicationIDSaga(apiData) {
  try {
    const { accessToken } = yield select(getCredentials);
    const apiPayload = {};
    apiPayload.data = apiData;
    apiPayload.accessToken = accessToken;
    let response = null;
    if(apiData.hasOwnProperty('memberId')){
    response = yield call(
      Ujala.getMemberDetailsByMemberNO,
      apiPayload
    );
  }else{
    response = yield call(
      Ujala.getMemberDetailsByApplicationNO,
      apiPayload
    );
  }

    if (response.code === 200) {
      dispatch(resetState());
      // debugger;
      dispatch(setMemberApplicationData(response?.responseObject));
      dispatch(setIsMemberEditable(true));
    } else {
      toast.error(response?.message);
    }
  } catch (error) {
    toast.error(error?.message);
  }
}

function* getMemberDetailsByMemberIDSaga(apiData) {
  try {
    const { accessToken } = yield select(getCredentials);
    const apiPayload = {};
    apiPayload.data = apiData;
    apiPayload.accessToken = accessToken;
    const response = yield call(
      Ujala.getMemberDetailsByApplicationNO,
      apiPayload
    );
    if (response.code === 200) {
      dispatch(setMemberApplicationData(response?.responseObject));
      dispatch(setIsMemberEditable(true));
      // toast.success("Member registered successfully");
    } else {
      toast.error(response?.message);
    }
  } catch (error) {
    toast.error(error?.message);
  }
}

function* getMemberDetailsByIDSaga(apiData) {
  try {
    const { accessToken } = yield select(getCredentials);
    const { memberId, index, label } = apiData;
    const apiPayload = {
      data: {
        memberId: memberId,
      },
    };
    apiPayload.accessToken = accessToken;
    const response = yield call(Ujala.getMemberDetailsByID, apiPayload);
    if (response.code == 200) {
      const { firstName, memberId, mobileNo, memberType, level, branchId } =
        response?.responseObject;
      if (label === "referalMemberId") {
        dispatch(
          updateReferalData({
            firstName,
            mobileNo,
            memberType,
            level,
            branchId
          })
        );
        return;
      }
      if (label === WITNESS) {
        yield put(
          updateWitnessAndGuarantorRequests({
            firstName,
            mobileNo,
            index,
            label,
          })
        );
      }
    } else {
      toast.error(response?.message);
    }
  } catch (error) {
    toast.error("Something went wrong");
  }
}

function* validateAadharAndSetAddressSaga(apiData) {
  try {
    const { accessToken } = yield select(getCredentials);
    const apiPayload = {};
    apiPayload.data = apiData;
    apiPayload.accessToken = accessToken;
    const response = yield call(
      Ujala.getMemberDetailsByAadharNumber,
      apiPayload
    );
    if (response.code == 200) {
      const { name, address, dist, dob, gender, pincode, state } =
        response?.responseObject;
      dispatch(
        setAadharData({ name, address, dist, dob, gender, pincode, state })
      );
    }
    if (response.code === 400) {
      toast.error(response?.message);
    }
  } catch (error) {
    toast.error(error?.message);
  }
}

function* getPaymentDetailsSaga() {
  try {
    const {applicationId, memberId} = yield select(state=> state.addNewMember);
    const { accessToken } = yield select(getCredentials);
    const apiPayload = {};
    apiPayload.accessToken = accessToken;
    apiPayload.data = applicationId ? { applicationId } : { memberId };
    const response = yield call(Ujala.getPaymentDetails, apiPayload);
    if (response.code == 200) {
      const payment = response?.responseObject;
      if(!payment?.totalAmount)
        payment['totalAmount'] = (Number(payment.noOfShares)*payment.shareFee)+payment.membershipFee;
      yield put(setFormData({name: "payment", value: {...payment}}))
    } else {
      toast.error("Error");
    }
  } catch (error) {
    toast.error(error?.message);
  }
}

function* submitPaymentSaga(apiData) {
  try {
    const { accessToken } = yield select(getCredentials);
    const apiPayload = {};
    apiPayload.accessToken = accessToken;
    apiPayload.data = apiData;
    const response = yield call(Ujala.submitPayment, apiPayload);
    if (response.code == 200) {
      toast.success("Payment done successfully");
      yield fork(getPaymentDetailsSaga);
    } else {
      toast.error("Error");
    }
  } catch (error) {
    toast.error(error?.message);
  }
}

function* getRequiredDocumentDetailsSaga() {
  try {
    const {applicationId} = yield select(state=> state.addNewMember);
    const { accessToken } = yield select(getCredentials);
    const apiPayload = {};
    apiPayload.data = { applicationId };
    apiPayload.accessToken = accessToken;
    const response = yield call(Ujala.getRequiredDocumentDetails, apiPayload);
    if (response.code == 200) {
      dispatch(setRequiredDocuments(response?.responseObject));
    } else {
      toast.error("Error");
    }
  } catch (error) {
    toast.error(error?.message);
  }
}

function* uploadDocumentSaga(apiData) {
  try {
    const { accessToken } = yield select(getCredentials);
    const apiPayload = {};
    apiPayload.data = apiData;
    apiPayload.accessToken = accessToken;
    const response = yield call(Ujala.uploadDocument, apiPayload);
    if (response.code == 200) {
      toast.success(response?.responseObject);
      yield fork(getRequiredDocumentDetailsSaga);
    } else {
      toast.error("Error");
    }
  } catch (error) {
    toast.error(error?.message);
  }
}

function* getDocumentURLSaga(apiData) {
  try {
    const { accessToken } = yield select(getCredentials);
    const apiPayload = {};
    apiPayload.data = apiData;
    apiPayload.accessToken = accessToken;
    const response = yield call(Ujala.getDocumentURL, apiPayload);
    if (response.code == 200) {
      const payload = {
        documentId: apiData?.documentId,
        documentURL: response.responseObject,
      };
      dispatch(updateRequiredDocuments(payload));
    } else {
      toast.error("Error");
    }
  } catch (error) {
    toast.error(error?.message);
  }
}

function* getBankDetailsByIFSCSaga(ifsc) {
  try {
    yield call(getBankDetailsByIFSCFlow, ifsc);
  } catch (error) {
    console.log(error);
  }
}

function* addMemberSaga() {
  try {
    yield put(resetState());
    dispatch(push(ROUTES.ADD_MEMBER)); 
  } catch (error) {
    console.log(error);
  }
}

function* changeDocStatusSaga(apiData) {
  try {
    const { accessToken } = yield select(getCredentials);
    const apiPayload = {};
    apiPayload.data = apiData;
    apiPayload.accessToken = accessToken;
    const response = yield call(Ujala.approveRejectDocument, apiPayload);
    if (response.code == 200) {
      toast.success(response?.responseObject);
      yield fork(getRequiredDocumentDetailsSaga);
    } else {
      throw new Error(response.message);
    }
  } catch (error) {
    console.log(error);
    toast.error(error.message || "Something went wrong !!");
  }
}

function* finaliseApplicationSaga(apiData) {
  try {
    const {applicationId} = yield select(state=> state.addNewMember);
    const { accessToken } = yield select(getCredentials);
    const apiPayload = {};
    apiPayload.data = {applicationId};
    apiPayload.accessToken = accessToken;
    const response = yield call(Ujala.finaliseApplication, apiPayload);
    if (response.code == 200) {
      toast.success(response?.responseObject);
      dispatch(push(ROUTES.VIEW_MEMBER))
    } else {
      throw new Error(response.message);
    }
  } catch (error) {
    console.log(error);
    toast.error(error.message || "Something went wrong !!");
  }
}

function* getAddressDetailsByPincodeRequest(action) {
  yield call(getAddressDetailsByPincodeFlow, action.payload);
}

function* getBankDetailsByIFSC(action) {
  yield call(getBankDetailsByIFSCFlow, action.payload);
}

function* getMemberList(action) {
  yield call(getMemberListSaga, action.payload);
}

function* getMemberDetailsByID(action) {
  yield call(getMemberDetailsByIDSaga, action.payload);
}

function* registerMember(action) {
  yield call(registerMemberSaga, action.payload);
}

function* getMemberDetailsByApplicationID(action) {
  yield call(getMemberDetailsByApplicationIDSaga, action.payload);
}

function* validateAadharAndSaveDetails(action) {
  yield call(validateAadharAndSetAddressSaga, action.payload);
}

function* getRequiredDocumentDetails() {
  yield call(getRequiredDocumentDetailsSaga);
}

function* uploadDocument(action) {
  yield call(uploadDocumentSaga, action.payload);
}

function* getDocumentURL(action) {
  yield call(getDocumentURLSaga, action.payload);
}

function* submitPaymet(action) {
  yield call(submitPaymentSaga, action.payload);
}

function* addMember(action) {
  yield call(addMemberSaga, action.payload);
}

function* changeDocStatus(action) {
  yield call(changeDocStatusSaga, action.payload);
}

function* finaliseApplication() {
  yield call(finaliseApplicationSaga);
}

function* getPaymentDetails() {
  yield call(getPaymentDetailsSaga);
}

export default function* saga() {
  yield takeLatest(ADD_MEMBER, addMember);
  yield takeLatest(CHANGE_DOC_STATUS, changeDocStatus);
  yield takeLatest(REGISTER_MEMBER, registerMember);
  yield takeLatest(FINALISE_APPLICATION, finaliseApplication);
  yield takeLatest(ADDRESS_BY_PINCODE, getAddressDetailsByPincodeRequest);
  yield takeLatest(BANK_DETAILS_BY_IFSC, getBankDetailsByIFSC);
  yield takeLatest(MEMBER_LIST, getMemberList);
  yield takeLatest(MEMBER_DETAILS_BY_ID, getMemberDetailsByID);
  yield takeLatest(
    AADHAR_VALIDATION_AND_SET_ADDRESS,
    validateAadharAndSaveDetails
  );
  yield takeLatest(
    MEMBER_DETAILS_BY_APPLICATION_ID,
    getMemberDetailsByApplicationID
  );
  yield takeLatest(GET_DOCUMENTS_DETAILS, getRequiredDocumentDetails);
  yield takeLatest(UPLOAD_DOCUMENT, uploadDocument);
  yield takeLatest(UPLOAD_DOCUMENT_URL, getDocumentURL);
  yield takeLatest(PAYMENT_METHOD, submitPaymet);
  yield takeLatest(GET_PAYMENT_DETAILS, getPaymentDetails);
}
