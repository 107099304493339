import React from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import PropTypes from "prop-types";
import Palette from "themes/palette";
import IconButton from "@mui/material/IconButton";

type EndormentType = "start" | "end";

interface TextFieldBaseProps {
  labelName?: string;
  placeHolder?: string;
  AdornmentIcon?: React.ElementType | React.ReactNode | any;
  onChange?: (value: string, name: any) => void;
  onAdormentclick?: () => void;
  showError?: string;
  name?: string;
  type?: string;
  disabled?: boolean;
  multiline?: boolean;
  getMinDate?: () => number;
  value?: any;
  index?: string;
  endormentPosition?: EndormentType;
  hasIcon?: boolean;
  maxLength?: number;
  onMouseDown?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseUp?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseLeave?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onwheel?: string;
  minRows?: number;
  isMandatory?: boolean;
}

const TextFieldBase: React.FC<TextFieldBaseProps> = (props) => {
  const {
    labelName = "",
    placeHolder = "",
    AdornmentIcon = () => <></>,
    onChange,
    onAdormentclick,
    showError = "",
    name = "",
    type = "",
    disabled = false,
    multiline = false,
    getMinDate = () => {},
    value = "",
    hasIcon = false,
    maxLength = 0,
    endormentPosition = "end",
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    minRows = 0,
    isMandatory = false
  } = props;

  const { palette } = Palette();

  const textStyle = {
    color: palette.error.main,
    fontSize: "14px",
  };

  const Adornment = hasIcon ? (
    <InputAdornment position={endormentPosition}>
      <IconButton
        onClick={onAdormentclick}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseLeave={onMouseLeave}
      >
        <AdornmentIcon fontSize="small" />
      </IconButton>
    </InputAdornment>
  ) : null;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (type === "number" && parseFloat(value) < 0) {
      return;
    }
    if (maxLength === 0 || (maxLength > 0 && value.length <= maxLength)) {
      if (onChange) {
        onChange(value, name);
      }
    }
  };

  const onWheel = (e: any) => {
    e.target.blur();
    e.stopPropagation();
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  return (
    <>
    <TextField
      minRows={minRows}
      name={name}
      label={  
      <>
        {labelName}
        {isMandatory && <sup style={textStyle}>*</sup>}
      </>
      }
      value={value}
      // placeholder={placeHolder}
      type={type}
      {...(type === "number" && { onWheel: onWheel })}
      multiline={multiline}
      disabled={disabled}
      variant="outlined"
      size="small"
      sx={{
        "& .MuiOutlinedInput-root": {
          "& .MuiOutlinedInput-input": {
            "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
              "-webkit-appearance": "none",
              margin: 0,
            },
            "-moz-appearance": "textfield",
            "&:disabled": {
              color: "#808080", 
              "-webkit-text-fill-color": "#808080", 
      
            },
          },
        },
      
        "& .MuiInputBase-input-MuiOutlinedInput-input": {
          paddingLeft: "0px!important",
        },
      }}
      fullWidth
      InputLabelProps={{ shrink: true }}
      InputProps={{
        [`${endormentPosition}Adornment`]: Adornment,
        inputProps: {
          min: getMinDate(),
        },

      }}
      onChange={handleChange} // Correctly pass the change handler
      error={Boolean(showError)}
      helperText={showError || ""}
    />
    </>
  );
};

TextFieldBase.propTypes = {
  labelName: PropTypes.string,
  placeHolder: PropTypes.string,
  AdornmentIcon: PropTypes.oneOfType([PropTypes.elementType, PropTypes.node]),
  onChange: PropTypes.func,
  onAdormentclick: PropTypes.func,
  showError: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  multiline: PropTypes.bool,
  getMinDate: PropTypes.func,
  value: PropTypes.string,
  hasIcon: PropTypes.bool,
  maxLength: PropTypes.number,
  onMouseDown: PropTypes.func,
  onMouseUp: PropTypes.func,
  onMouseLeave: PropTypes.func,
};

export default TextFieldBase;
