/* eslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import MUIDataTable from 'mui-datatables';
import IconButton from '@mui/material/IconButton';
import FilterListIcon from '@mui/icons-material/FilterList';
import CustomRow from './customRow/index';
import PopUpModalWithHeader from 'components/PopUpModalWithHeader/index';
import { makeStyles } from '@mui/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import LayersIcon from '@mui/icons-material/Layers';
import Skeleton from '@mui/material/Skeleton';
import SkeletonVariants from './skeleton';


const propTypes = {
    data: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    pageSize: PropTypes.number,
    pageSizeOptions: PropTypes.array,
    tableTitle: PropTypes.string,
    resettingSelectedRowParameters: PropTypes.array,
    enableExport: PropTypes.object,
    filterType: PropTypes.oneOf[('IN_TABLE', 'OUT_TABLE')],
    showSkeleton: PropTypes.bool,
    resizableColumns: PropTypes.bool,
    areRowsSelectable: PropTypes.bool,
    onBulkApproveClickHandler: PropTypes.func,
    tableBodyHeight: PropTypes.string,
    textLabels: PropTypes.PropTypes.oneOfType([PropTypes.elementType, PropTypes.node]),
    showSearchField: PropTypes.bool,
};

const defaultProps = {
    data: [],
    columns: [],
    pageSize: 5,
    pageSizeOptions: [5, 10, 20, 50],
    tableTitle: '',
    seprateRowOnClick: () => {},
    enableExport: {
        csv: {
            required: false,
            fileName: 'muiCsv.csv'
        },
        pdf: {
            required: false,
            fileName: 'muiPdf.pdf'
        }
    },
    filterType: 'IN_TABLE',
    showSkeleton: false,
    resizableColumns: false,
    areRowsSelectable: false,
    onBulkApproveClickHandler: () => {},
    tableBodyHeight: 'auto',
    textLabels: () => <></>,
    showSearchField: false,
};

const useStyles = makeStyles({
    tableContainer: {
        '& h4': {
            textAlign: 'center',
            color: '#707477',
            fontWeight: '500',
            fontSize: '25px',
            position: 'relative',
            '&::after': {
                content: '" "',
                position: 'absolute',
                width: 100,
                height: 2,
                background: '#497aa3',
                bottom: 0,
                left: '50%',
                transform: 'translate(-50%, 10px)'
            }
        },
        '& ::-webkit-scrollbar': {
            width: 5
        },

        /* Track */
        '& ::-webkit-scrollbar-track': {
            background: ' #f1f1f1'
        },

        /* Handle */
        '& ::-webkit-scrollbar-thumb': {
            background: '#8888885e'
        },

        /* Handle on hover */
        '& ::-webkit-scrollbar-thumb:hover': {
            background: '#555',
            cursor: 'pointer'
        },

        '& th': { border: 0 },
        '& td': { border: 0 },
        '& table': { border: 0 },
        '& tr': { border: '1px solid rgba(224, 224, 224, 1)' }
    }
});

const getMuiTheme = () =>
    createTheme({
        components: {
            MUIDataTableHeadCell: {
                styleOverrides: {
                    root: {
                        fontWeight: 'bold',
                        paddingTop: 0,
                        paddingBottom: 0
                    }
                }
            }
        }
    });

const MaterialTableInner = (materialProps) => {
    const {
        data,
        columns,
        pageSize,
        pageSizeOptions,
        tableTitle,
        filterRequired,
        FormRender,
        formikProps,
        filterType,
        page,
        onChangePage,
        count,
        loading,
        enableExport,
        onChangeRowsPerPage,
        resizableColumns,
        areRowsSelectable,
        onBulkApproveClickHandler,
        tableBodyHeight,
        textLabels,
        showSkeleton,
        showSearchField,
        ...restProps
    } = materialProps;
    const classes = useStyles();
    const [openFilterModal, setOpenFilterModal] = useState(false);
    
    return (
        <ThemeProvider theme={getMuiTheme()}>
            <div {...(loading && { style: { pointerEvents: 'none' } })} className={classes.tableContainer}>
                {loading && (
                    <div style={{ position: 'relative', width: '100%' }}>
                        <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                height: '100%',
                                width: '100%',
                                zIndex: 999
                            }}
                        >
                            <LinearProgress />
                        </div>
                    </div>
                )}

                <MUIDataTable
                    options={{
                        filter: false,
                        search: showSearchField,
                        responsive: 'vertical', // vertical, standard, simple
                        elevation: 10,
                        jumpToPage: true,
                        print: enableExport.pdf.required, // possible values: true, false, disabled
                        draggableColumns: {
                            enabled: true
                        },
                        download: enableExport.csv.required, // to disable csv download option
                        downloadOptions: {
                            filename: enableExport.csv.fileName,
                            // separator: ';', by default it creates separate column for each value
                            filterOptions: {
                                useDisplayedColumnsOnly: true,
                                useDisplayedRowsOnly: true
                            }
                        },
                        textLabels: {
                            body: {
                           
                            noMatch: loading ? <Skeleton variant="text" duration={0.5}/> : 'sorry, no matching record found'
                         
                            },
                          }, 
                        selectableRows: areRowsSelectable ? 'single' : 'none', // to show checkbox for row selection
                        selectableRowsHideCheckboxes: false,
                        viewColumns: false,
                        onRowSelectionChange: (currentRowsSelected, allRows, rowsSelected) => {
                            console.log(currentRowsSelected, allRows, rowsSelected, 'xxxxxxxxxxxxxxxxx onRowSelectionChange');
                        },
                        onRowsDelete: (rowsDeleted) => {
                            console.log(rowsDeleted, 'xxxxxxxxxxxxxxxxx rowsDeleted');
                        },
                        onChangePage: (pageNumber) => {
                            console.log(pageNumber, 'xxxxxxxxxxxxxxxxx onChangePage');
                            onChangePage(pageNumber);
                        },
                        onChangeRowsPerPage: (data) => {
                            onChangeRowsPerPage(data);
                        },
                        onSearchChange: (searchText) => {
                            console.log(searchText, 'xxxxxxxxxxxxxxxxx onSearchChange');
                        },
                        onColumnSortChange: (column, direction) => {
                            console.log(column, direction, 'xxxxxxxxxxxxxxxxx onColumnSortChange');
                        },
                        onViewColumnsChange: (column, action) => {
                            console.log(column, action, 'xxxxxxxxxxxxxxxxx onViewColumnsChange');
                        },
                        onFilterChange: (column, filters) => {
                            console.log(column, filters, 'xxxxxxxxxxxxxxxxx onFilterChange');
                        },
                        onCellClick: (cellIndex, rowIndex) => {
                            console.log(cellIndex, rowIndex, 'xxxxxxxxxxxxxxxxx onCellClick'); // gives data of clicked cell index
                        },
                        onRowClick: (rowData, rowState) => {
                            console.log(rowData, rowState, 'xxxxxxxxxxxxxxxxx onRowClick'); // gives data of clicked row
                        },
                        onTableChange: (action, tableState) => {
                            console.log(action, tableState, 'xxxxxxxxxxxxxxxxx onTableChange'); // gives complete info about table upon any action on table
                        },



                        customToolbar: () => {
                            return (
                                <Box component="span">
                                    {filterRequired && filterType === 'IN_TABLE' && (
                                        <IconButton
                                            title="Filter"
                                            sx={(theme) => ({
                                                '&:hover': { color: 'primary.main' }
                                            })}
                                            onClick={() => setOpenFilterModal(true)}
                                        >
                                            <FilterListIcon />
                                        </IconButton>
                                    )}

                                    {areRowsSelectable && (
                                        <IconButton
                                            title="Bulk Action"
                                            sx={(theme) => ({
                                                '&:hover': { color: 'primary.main' }
                                            })}
                                            onClick={() => onBulkApproveClickHandler()}
                                        >
                                            <LayersIcon />
                                        </IconButton>
                                    )}
                                </Box>
                            );
                        },

                        // customRowRender: (data, dataIndex, rowIndex) => <span style={{ display: 'contents' }}>{data}</span>,

                        customRowRender: (rowData, dataIndex, rowIndex) => {
                            // if (showSkeleton || loading) {
                            //     return <SkeletonVariants />;
                            //   } else {
                            return (
                                <CustomRow
                                    data={rowData}
                                    customProps={restProps}
                                    completeData={data}
                                    dataIndex={dataIndex}
                                    rowIndex={rowIndex}
                                    areRowsSelectable={areRowsSelectable}
                                />
                            );
                            //   }
                        },
                        // fixedHeader: true, // fixed header
                        // fixedSelectColumn: true, // fixed header
                        tableBodyHeight: tableBodyHeight, // fixed header
                        rowsPerPage: pageSize,
                        count: count,
                        rowsPerPageOptions: pageSizeOptions,
                        resizableColumns: resizableColumns, // to resize columns
                        draggableColumns: {
                            enabled: false // to enable drag
                        }
                    }}
                    columns={columns}
                    data={page > 0 ? [...new Array(pageSize * page).fill(''), ...data] : data}
                    title={tableTitle}
                />

                <PopUpModalWithHeader
                    open={openFilterModal}
                    handleClose={() => setOpenFilterModal(false)}
                    modalStyleprops={{ minWidth: 320 }}
                    headerTitle="Filters"
                >
                    <div style={{ padding: 10 }}>
                        <FormRender
                            handleSubmit={formikProps.handleSubmit}
                            handleReset={formikProps.handleReset}
                            formValues={formikProps.values}
                            setFieldValue={formikProps.setFieldValue}
                            page={page}
                            pageSize={pageSize}
                            setOpenFilterModal={setOpenFilterModal}
                        />
                    </div>
                </PopUpModalWithHeader>

                {loading && (
                    <>
                        <div style={{ position: 'relative', width: '100%' }}>
                            <div
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    height: '100%',
                                    width: '100%'
                                }}
                            >
                                <LinearProgress />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </ThemeProvider>
    );
};

MaterialTableInner.propTypes = propTypes;
MaterialTableInner.defaultProps = defaultProps;

export default MaterialTableInner;
