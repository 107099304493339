import * as React from "react";

// Third party
import { useDispatch, useSelector } from "react-redux";
import Box from "components/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";

// Projects
import { PersonalDetails } from "./PersonalDetails";
import Documents from "./components/Documents/index";
import BankAndNominee from "./Officials";
import { formValidator } from "utils/formValidator";
import {
  personalDetailsValidationSchema,
  bankAndNomineeDetailsValidationSchema,
  paymentSchema,
} from "./addMemberSchemaValidation";
import { saveErrors, setActiveStep } from "./reducers";
import { REGISTER_MEMBER, MEMBER_DETAILS_BY_APPLICATION_ID, FINALISE_APPLICATION } from "store/types";
import { useParams } from "react-router-dom";
import Payment from "./Payment";
import Header from "layouts/drawerAndHeader/Header/Header";
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';

const steps = ["Personal", "Official", "Payment", "Documents"];

const useStyles = makeStyles({
  thankYouText: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    pt: 2,
    minHeight: "300px",
  },
  buttonContainer: {
    width: "500px",
    display: "flex",
    gap: "16px",
    msFlexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  previousButtonStyle: {
    fontSize: "12px",
    fontWeight: 400,
    fontFamily: "Poppins",
  },
  nextButtonStyle: {
    fontSize: "12px",
    fontWeight: 400,
    fontFamily: "Poppins",
    background: "#1F7A8C",
  },
  btnAlignment: {
    marginTop: '20px',
    padding: "0px 8px 4px 6px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  nextNumber: {
    color: "#fff",
    border: "1px solid #1F7A8C",
    backgroundColor: "#1F7A8C",
    marginLeft: "5px",
    "&:hover": {
      color: "#fff",
      border: "1px solid #1F7A8C",
      backgroundColor: "#1F7A8C",
    },
  },
});

export default function LinearStepper() {
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const [errors, setErrors] = React.useState(null);
  const { id } = useParams();

  const title = [
    { label: "Member" },
    { label: "Transaction" },
    { label: "Add Member" },
  ];

  React.useEffect(() => {
    if (id) {
      dispatch({
        type: MEMBER_DETAILS_BY_APPLICATION_ID,
        payload: { applicationId: id },
      });
    }
  }, [id]);

  const classess = useStyles();
  const dispatch = useDispatch();

  const addNewMember = useSelector((state: any) => state.addNewMember);

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    if (addNewMember?.activeStep === 1) {
      const {
        formErrors,
        saveAddressDetails,
        memberList,
        witness_is_existing_member1,
        witness_is_existing_member2,
        guaranter_is_existing_member1,
        guaranter_is_existing_member2,
        activeStep,
        document,
        filters,
        payment,
        NOMINEE_DETAILS,
        is_existing_member1,
        nimineeAge,
        current_same_as_aadhar_address,
        totalPercentage,
        permanent_same_as_aadhar_address,
        permanent_same_as_current_address,
        ...otherProps
      } = addNewMember;
      dispatch({ type: REGISTER_MEMBER, payload: otherProps });
    }
  };

  const validationArray = [
    personalDetailsValidationSchema,
    bankAndNomineeDetailsValidationSchema,
    paymentSchema,
  ];

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    
    if(addNewMember.activeStep >= validationArray.length){
      dispatch({ type: FINALISE_APPLICATION });
      return;
    }

    const isFormValid : boolean = await formValidator({
      formValuesToBeValidated: addNewMember,
      validationSchema: validationArray[addNewMember?.activeStep],
      errorCallBackFunction: (formErr: any) => {
        setErrors(formErr);
        dispatch(saveErrors(formErr));
      },
    });
    if (isFormValid) {
        dispatch(setActiveStep(addNewMember?.activeStep + 1));
          handleNext();
        dispatch(saveErrors({}));
    }
  };

  const handleBack = () => {
    dispatch(setActiveStep(addNewMember?.activeStep - 1));
  };

  const formContent = (step: any) => {
    switch (step) {
      case 0:
        return <PersonalDetails errors={addNewMember.formErrors} />;
      case 1:
        return <BankAndNominee errors={addNewMember.formErrors} />;
      case 2:
        return <Payment />;
      case 3:
        return <Documents />;
      default:
        return <div>404: Not Found</div>;
    }
  };

  const getNextLabel = () => {
    if(addNewMember?.activeStep === steps.length - 1){
      return "Finish";
    } else if(addNewMember.activeStep === 1 && (!addNewMember.applicationId && !addNewMember.memberId)) {
      return "Save to Generate Application No."
    } else if(addNewMember.activeStep === 1 && (addNewMember.applicationId || addNewMember.memberId)) {
      return "Update & Next"
    }
    else {
      return "Next";
    }
  } 

  return (
    <Box p={1}>
      <Header title={title} />
      <Box mb={2} sx={{ display: "flex", justifyContent: "center" }}>
       <Box sx={{ maxWidth: "800px", flexGrow: 1 }}>
          <Stepper
            activeStep={addNewMember?.activeStep}
            sx={{
              "&.MuiStepConnector-line": {
                borderTopWidth: "2px !important",
              },
              "&.css-236lul-MuiSvgIcon-root-MuiStepIcon-root.Mui-active": {
                color: "#5c7b98",
              },
            }}
          >
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <Box p={0} m={0}>
                    <StepLabel {...labelProps} >
                      <Typography
                        sx={{
                          color: "#4F566B",
                          fontWeight: 400,
                          fontSize: "14px",
                        }}
                      >
                        {label}
                      </Typography>
                    </StepLabel>
                  </Box>
                </Step>
              );
            })}
          </Stepper>
       </Box>
        <Box show={addNewMember.applicationId} style={{ position: "absolute", right: 20 }}>
        <Typography sx={{ color: "#4F566B", fontWeight: 600, fontSize: "16px" }}>{`Application ID: ${addNewMember.applicationId}`}</Typography>
        </Box>
      </Box>

        <>
          <div>
            <form onSubmit={handleSubmit}>
              <Box>{formContent(addNewMember?.activeStep)}</Box>
                <Box className={classess.btnAlignment}>
                  <Button
                    size="medium"
                    className={classess.previousButtonStyle}
                
                    variant="outlined"
                    color="inherit"
                    disabled={addNewMember?.activeStep === 0}
                    onClick={handleBack}
                  >
                    Previous
                  </Button>
                  <Button
                    className={classess.nextNumber}
                    size="medium"
                    endIcon={<ArrowForwardRoundedIcon style={{ fontSize: "14px" }} />}
                    variant="contained"
                    type="submit"
                  >
                    {getNextLabel()}
                  </Button>
                </Box>
            </form>
          </div>
        </>
    </Box>
  );
}
