import { Box } from "@mui/material";
import React from "react";
import LinearStepper from "./LinearStepper";

function AddNewMember() {
  return (
    <>
      <Box>
        <LinearStepper />
      </Box>
    </>
  );
}

export default AddNewMember;
