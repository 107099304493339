import React from "react";
import { Box, Checkbox, FormControlLabel, Grid, Stack } from "@mui/material";
import TextFieldBase from "components/TextField";
import { useDispatch, useSelector } from "react-redux";

//Third party components
import Select from "components/Select";
import Address from "./components/Address";
import {
  setFormData,
  setSalutation,
  setMarriedStatus,
  setNoOfChildren,
  setGenderVal,
  setBloodGroupVal,
  setReligoiusData,
  setQualificationVal,
  setOcuupationVal,
  clearErrors,
} from "./reducers";
import ShowErrorMessage from "./showErrorMessage";
import BankDetails from "./components/BankDetails";
import {
  BLOOD_GROUPS,
  GENDERS,
  MARITAL_STATUS,
  NO_OF_CHILDRENS,
  Occupation,
  QUALIFICATIONS,
  RELIGIONS,
  SALUTATIONS,
  SPACING,
} from "./constant";
import CustomBox from "../../components/Box/CustomBox";
import { AADHAR_VALIDATION_AND_SET_ADDRESS } from "store/types";
import InputDatePicker from "components/DatePicker";

export const PersonalDetails = ({ errors }: any) => {
  const dispatch = useDispatch();
  const addNewMember = useSelector((state: any) => state.addNewMember);

  const selectGenderChange = (value: any) => {
    dispatch(setGenderVal(value?.value));
  };

  const selectRelogionChange = (value: any) => {
    dispatch(setReligoiusData(value?.value));
  };

  const selectSaluationChange = (value: any) => {
    dispatch(setSalutation(value?.value));
    dispatch(clearErrors({ name: "salutation" }));
  };

  const selectMarriedStatusChange = (value: any) => {
    dispatch(setMarriedStatus(value?.value));
    dispatch(clearErrors({ name: "maritalStatus" }));
  };

  const selectNoOfChildrenChange = (value: any) => {
    dispatch(setNoOfChildren(value?.value));
  };

  const selectBloodGroupChange = (value: any) => {
    dispatch(setBloodGroupVal(value?.value));
    dispatch(clearErrors({ name: "bloodGroup" }));
  };

  const selectQualificationChange = (value: any) => {
    dispatch(setQualificationVal(value?.value));
    dispatch(clearErrors({ name: "highestQualification" }));
  };

  const selectOccupationChange = (value: any) => {
    dispatch(setOcuupationVal(value?.value));
    dispatch(clearErrors({ name: "maritalStatus" }));
  };

  const handleInputChnage = (value: string, name: any) => {
    if (name === "aadhaarNo" && value?.length === 12) {
      const payload = {
        aadhaarNo: value,
      };
      dispatch({ type: AADHAR_VALIDATION_AND_SET_ADDRESS, payload: payload });
    }
    if (name === "aadhaarNo" && value?.length === 13) return;
    if (name === "mobileNumber" && value?.length === 11) return;
    if (name === "alternateMobileNumber" && value?.length === 11) return;
    if (name === "panNo" && value?.length === 11) return;

    dispatch(
      setFormData({
        name: name,
        value: name === "panNo" ? value.toLocaleUpperCase() : value,
      })
    );
    dispatch(clearErrors({ name: name }));
  };

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setFormData({
        name: "isSms",
        value: event.target.checked === true ? "Y" : "N",
      })
    );
  };

  return (
    <>
      <Stack spacing={2}>
        <CustomBox label={"Basic Details"}>
          <Grid container spacing={SPACING}>
            {addNewMember?.applicationId && (
              <Grid item xs={12} sm={4} md={3} lg={2}>
                <TextFieldBase
                  disabled
                  name="applicationId"
                  placeHolder={"ApplicationId"}
                  labelName={"ApplicationId"}
                  type="number"
                  value={addNewMember.applicationId}
                />
              </Grid>
            )}

          {addNewMember?.memberId && (
              <Grid item xs={12} sm={4} md={3} lg={2}>
                <TextFieldBase
                  disabled
                  name="memberId"
                  placeHolder={"MemberId"}
                  labelName={"MemberId"}
                  type="number"
                  value={addNewMember.memberId}
                />
              </Grid>
            )}

            <Grid item xs={12} sm={4} md={3} lg={2}>
              <TextFieldBase
                isMandatory={true}
                name="aadhaarNo"
                placeHolder={"Aadhar Number"}
                labelName={"Aadhar Number"}
                type="number"
                onChange={(value, name) => handleInputChnage(value, name)}
                value={addNewMember.aadhaarNo}
                showError={errors && errors.hasOwnProperty("aadhaarNo")}
              />
              {errors && errors.hasOwnProperty("aadhaarNo") && (
                <ShowErrorMessage message={errors.aadhaarNo} />
              )}
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2}>
              <Select
                isMandatory={true}
                name="salutation"
                multi={false}
                labelName={"Salutation"}
                onChangeSelect={selectSaluationChange}
                data={SALUTATIONS}
                value={addNewMember?.salutation}
                showError={errors && errors.hasOwnProperty("salutation")}
              />

              {errors && errors.hasOwnProperty("salutation") && (
                <ShowErrorMessage message={errors.salutation} />
              )}
            </Grid>

            <Grid item xs={12} sm={4} lg={4}>
              <TextFieldBase
                isMandatory={true}
                name="fullName"
                placeHolder={"Full Name"}
                labelName={"Full Name"}
                onChange={(value, name) => handleInputChnage(value, name)}
                value={addNewMember.fullName}
                showError={errors && errors.hasOwnProperty("fullName")}
              />
              {errors && errors.hasOwnProperty("fullName") && (
                <ShowErrorMessage message={errors.fullName} />
              )}
            </Grid>

            <Grid item xs={12} sm={4} md={3} lg={2}>
              <Select
                isMandatory={true}
                multi={false}
                name="gender"
                labelName={"Gender"}
                value={addNewMember?.gender}
                data={GENDERS}
                onChangeSelect={selectGenderChange}
                showError={errors && errors.hasOwnProperty("gender")}
              />
              {errors && errors.hasOwnProperty("gender") && (
                <ShowErrorMessage message={errors.gender} />
              )}
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2}>
              <InputDatePicker
                labelName="Date Of Birth"
                disableFuture={true}
                name="dob"
                placeHolder={"DOB"}
                onChange={(val) => {
                  const { value, name } = val;
                  handleInputChnage(value, name);
              }}
                value={addNewMember?.dob}
              />
              {errors && errors.hasOwnProperty("dob") && (
                <ShowErrorMessage message={errors.dob} />
              )}
            </Grid>

            <Grid item xs={12} sm={4} md={3} lg={2}>
              <InputDatePicker
               isMandatory={true}
              labelName="Joining Date"
                error={errors?.hasOwnProperty("joiningDate")}
                disableFuture={true}
                name="joiningDate"
                placeHolder={"Joining Date"}
                onChange={(val) => {
                  const { value, name } = val;
                  handleInputChnage(value, name);
              }}
                value={addNewMember.joiningDate}
              />
              {errors && errors.hasOwnProperty("joiningDate") && (
                <ShowErrorMessage message={errors.joiningDate} />
              )}
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2}>
              <Select
                multi={false}
                name="religion"
                labelName={"Religion"}
                value={addNewMember?.religion}
                data={RELIGIONS}
                onChangeSelect={selectRelogionChange}
                showError={errors && errors.hasOwnProperty("religion")}
              />
            </Grid>
            <Grid item xs={12} sm={4} lg={4}>
              <TextFieldBase
               isMandatory={true}
                name="emailId"
                type="email"
                placeHolder={"Email Id"}
                labelName={"Email Id"}
                onChange={(value, name) => handleInputChnage(value, name)}
                value={addNewMember.emailId}
                showError={errors && errors.hasOwnProperty("emailId")}
              />
              {errors && errors.hasOwnProperty("emailId") && (
                <ShowErrorMessage message={errors.emailId} />
              )}
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2}>
              <TextFieldBase
               isMandatory={true}
                name="mobileNumber"
                placeHolder={"Mobile No."}
                labelName={"Mobile No."}
                type="number"
                onChange={(value, name) => handleInputChnage(value, name)}
                value={addNewMember.mobileNumber}
                showError={errors && errors.hasOwnProperty("mobileNumber")}
              />
              {errors && errors.hasOwnProperty("mobileNumber") && (
                <ShowErrorMessage message={errors.mobileNumber} />
              )}
            </Grid>

            <Grid item xs={12} sm={4} md={3} lg={2}>
              <TextFieldBase
               isMandatory={true}
                name="alternateMobileNumber"
                placeHolder={"Alternate Mobile No."}
                labelName={"Alternate Mobile No."}
                type="number"
                onChange={(value, name) => handleInputChnage(value, name)}
                value={addNewMember.alternateMobileNumber}
                showError={
                  errors && errors.hasOwnProperty("alternateMobileNumber")
                }
              />
              {errors && errors.hasOwnProperty("alternateMobileNumber") && (
                <ShowErrorMessage message={errors.alternateMobileNumber} />
              )}
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2}>
              <Select
                name="bloodGroup"
                multi={false}
                labelName={"Blood group"}
                data={BLOOD_GROUPS}
                value={addNewMember?.bloodGroup}
                onChangeSelect={selectBloodGroupChange}
              />
              {errors && errors.hasOwnProperty("bloodGroup") && (
                <ShowErrorMessage message={errors.bloodGroup} />
              )}
            </Grid>

            <Grid item xs={12} sm={4} md={3} lg={2}>
              <TextFieldBase
               isMandatory={true}
                name="panNo"
                placeHolder={"PAN Number"}
                labelName={"PAN Number"}
                onChange={(value, name) => handleInputChnage(value, name)}
                value={addNewMember.panNo}
                showError={errors && errors.hasOwnProperty("panNo")}
              />
              {errors && errors.hasOwnProperty("panNo") && (
                <ShowErrorMessage message={errors.panNo} />
              )}
            </Grid>

            <Grid item xs={12} sm={4} md={3} lg={2}>
              <Select
               isMandatory={true}
                name="highestQualification"
                multi={false}
                labelName={"Qualification"}
                data={QUALIFICATIONS}
                value={addNewMember?.highestQualification}
                onChangeSelect={selectQualificationChange}
                showError={
                  errors && errors.hasOwnProperty("highestQualification")
                }
              />
              {errors && errors.hasOwnProperty("highestQualification") && (
                <ShowErrorMessage message={errors.highestQualification} />
              )}
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2}>
              <Select
               isMandatory={true}
                name="occupationType"
                multi={false}
                labelName={"Occupation"}
                data={Occupation}
                value={addNewMember?.occupationType}
                onChangeSelect={selectOccupationChange}
                showError={errors && errors.hasOwnProperty("occupationType")}
              />
              {errors && errors.hasOwnProperty("occupationType") && (
                <ShowErrorMessage message={errors.occupationType} />
              )}
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2}>
              <TextFieldBase
              isMandatory={true}
                name="familyIncome"
                placeHolder={"Ex. 12,00,000"}
                labelName={"Family income (Rs) "}
                type="number"
                onChange={(value, name) => handleInputChnage(value, name)}
                value={addNewMember.familyIncome}
                showError={errors && errors.hasOwnProperty("familyIncome")}
              />
              {errors && errors.hasOwnProperty("familyIncome") && (
                <ShowErrorMessage message={errors.familyIncome} />
              )}
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2}>
              <Select
               isMandatory={true}
                name="maritalStatus"
                multi={false}
                labelName={"Marital status"}
                data={MARITAL_STATUS}
                onChangeSelect={selectMarriedStatusChange}
                value={addNewMember.maritalStatus}
                showError={errors && errors.hasOwnProperty("maritalStatus")}
              />
              {errors && errors.hasOwnProperty("maritalStatus") && (
                <ShowErrorMessage message={errors.maritalStatus} />
              )}
            </Grid>

            {addNewMember?.maritalStatus !== "UnMarried" && (
              <>
                <Grid item xs={12} sm={4} md={3} lg={2}>
                  <Select
                    name="childCount"
                    multi={false}
                    labelName={"No. of Children"}
                    data={NO_OF_CHILDRENS}
                    onChangeSelect={selectNoOfChildrenChange}
                    value={addNewMember?.childCount}
                  />
                  {errors && errors.hasOwnProperty("childCount") && (
                    <ShowErrorMessage message={errors.childCount} />
                  )}
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2}>
                  <InputDatePicker
                  labelName="Date Of Marriage"
                    error={errors?.hasOwnProperty("dom")}
                    disableFuture={true}
                    name="dom"
                    onChange={(val) => {
                      const { value, name } = val;
                      handleInputChnage(value, name);
                  }}
                    value={addNewMember.dom}
                  />
                  {errors && errors.hasOwnProperty("dom") && (
                    <ShowErrorMessage message={errors.dom} />
                  )}
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={4} md={3} lg={2.5}>
              <FormControlLabel
                control={
                  <Checkbox
                    disableRipple
                    name="isSms"
                    onChange={handleCheck}
                    checked={addNewMember?.isSms === "Y" ? true : false}
                    // value={addNewMember?.isSms}
                    size="small"
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="SMS Service Required"
              />
            </Grid>
          </Grid>
        </CustomBox>
        <CustomBox style={{}} label={"Address"}>
          <Address />
        </CustomBox>

        <CustomBox style={{}} label={"Bank Account"}>
          <BankDetails />
        </CustomBox>
        <Box
          sx={{
            height: "60px",
          }}
        ></Box>
      </Stack>
    </>
  );
};
