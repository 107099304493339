import * as React from "react";
import { lazy } from "react";
// import * as React from 'react';

// project import
import Loadable from "components/Loadable";

import { ProtectedRoute } from "./ProtectedRoute";
import { ROUTES } from "./constant";
import AppDrawer from "layouts/drawerAndHeader";
import AddNewMember from "containers/members/AddNewMember";
import SavingAccount from "containers/savingAccount";
import OpenProductAccount from "containers/savingAccount/OpenProductAccount";

// render - samplepage

const SamplePage = Loadable(
  lazy(() => import("containers/extra-pages/SamplePage"))
);

const PromoteScreen = Loadable(
  lazy(() => import("containers/memberMaster/PromoteScreen"))
);

const EditMember = Loadable(lazy(() => import("containers/EditMember")));

const EditApplication = Loadable(
  lazy(() => import("containers/EditApplication"))
);

const Viewproduct = Loadable(
  lazy(() => import("containers/productMaster/Viewproduct"))
);

const ProductMasterScreen = Loadable(
  lazy(() => import("containers/productMaster/ProductMasterScreen"))
);

const Home = Loadable(lazy(() => import("containers/extra-pages/Home")));

const GovernmentStructure = Loadable(lazy(() => import("containers/structure/master/govStructure")));
const AddPinCode = Loadable(lazy(() => import("containers/structure/master/govStructure/pincode/addNewPinCode")));

const MainRoutes = {
  path: "/",
  element: (
    <ProtectedRoute>
      <AppDrawer />
    </ProtectedRoute>
  ),
  children: [
    {
      path: ROUTES.HOME,
      element: <ProtectedRoute>{/* <Home /> */}</ProtectedRoute>,
    },
    {
      path: "/promote-member/:id",
      element: (
        <ProtectedRoute>
          <PromoteScreen />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTES.ADD_MEMBER,
      element: (
        <ProtectedRoute>
          <AddNewMember />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTES.VIEW_MEMBER,
      element: (
        <ProtectedRoute>
          <EditMember />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTES.VIEW_APPLICATION,
      element: (
        <ProtectedRoute>
          <EditApplication />
        </ProtectedRoute>
      ),
    },

    {
      path: ROUTES.INVESTMENT_TRANSACTION_CREATE_PRODUCT,
      element: (
        // <ProtectedRoute>
        <OpenProductAccount />
        // </ProtectedRoute>
      ),
    },
    {
      path: ROUTES.VIEW_PRODUCT,
      element: (
        <ProtectedRoute>
          <Viewproduct />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTES.ADD_PRODUCT,
      element: (
        <ProtectedRoute>
          {/* <AddProduct /> */}
          <ProductMasterScreen />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTES.GOV_STRUCTURE,
      element: (
        <ProtectedRoute>
          <GovernmentStructure />
        </ProtectedRoute>
      ),
    },
    {
      path: ROUTES.PINCODE,
      element: (
        <ProtectedRoute>
          <AddPinCode />
        </ProtectedRoute>
      ),
    },
  ],
};

export default MainRoutes;