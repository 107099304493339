// ==============================|| OVERRIDES - INPUT Filled ||============================== //

export default function InputFilled(theme) {
    return {
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    backgroundColor: '#F4F6F8',
                    borderBottom: 0,
                    '&.MuiFilledInput-root:focus': {
                        backgroundColor: '#F4F6F8',
                        borderBottom: 0
                    },
                    '&.MuiFilledInput-root:hover': {
                        backgroundColor: '#F4F6F8',
                        borderBottom: 0
                    },
                    '&.MuiFilledInput-root:hover:after': {
                        backgroundColor: 'red',
                        borderBottom: 0
                    },
                    '&.MuiFilledInput-root:before': {
                        borderBottom: 0
                    },
                    '&.MuiFilledInput-root:hover:before': {
                        // background-color: rgba(0, 0, 0, 0.09);
                        backgroundColor: 'transparent',
                        borderBottom: 0
                    },
                    '&.MuiFilledInput-root:hover:before': {
                        // background-color: rgba(0, 0, 0, 0.09);
                        backgroundColor: 'transparent',
                        borderBottom: 0
                    },
                    '& .MuiFilledInput-root.Mui-focused': {
                        borderBottom: 0,
                        backgroundColor: '#F4F6F8'
                    },
                    '& .MuiFilledInput-input': {
                        height: '42px',
                        paddingTop: 5
                    },
                    '& .MuiFilledInput-input:focus': {
                        backgroundColor: 'transparent'
                        // padding: 0
                        // paddingTop: 20,
                        // paddingLeft: 10
                    }
                }
            }
        }
    };
}
