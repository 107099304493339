import React from "react";
import Grid2 from '@mui/material/Unstable_Grid2';
import { Typography, Box, Divider, Card } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    divider: {
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #ccc',
        borderColor: 'divider',
        borderRadius: 1,
        bgcolor: 'background.paper',
        color: 'text.secondary',
        '& svg': {
          m: 1,
        },
        '& hr': {
          mx: 0.5,
        },
      },
      textAlignment: {
        textAlign: 'center',
      },
      wrapper: {
        border: '0.5px solid #D9DADE',
        borderRadius: '8px',
        boxShadow: 'none'

      },
      mainContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-evenly',
      },
      gridItemPadding: {
        '&.css-27y2fm-MuiGrid2-root':{
            padding: '0px'
        }
      },
      dividerStyle: {
        height: 'px',
        margin: '10px 0px'
      },
      employeeNameText: {
        font: 'DM Sans',
        fontWeight: 500,
        fontSize: '15px',
        color: '#17181A'
      },
      employeeDetailsHeader: {
        font: 'DM Sans',
        fontWeight: 400,
        fontSize: '10px',
        color: '#626F86',
      },
      employeeDetails: {
        font: 'DM Sans',
        fontWeight: 400,
        fontSize: '12px',
        color: '#2E3033',
      }
});

const EmployeeProfileCard = ({data}) => {
    const classes = useStyles();

    return(
        <Box sx= {{width: '100%', padding: 2}}>
          <Grid2 container spacing={2} sx={{justifyContent: 'space-evenly'}}>
          <Grid2 md={12} xs={12}>
         <Card className={classes.wrapper}> 
        
        <Grid2 style={{ display: 'flex', flexDirection: 'row', padding: '4px 8px', marginTop: '5px'}} >
        <Typography className={classes.employeeNameText}>
        {data?.employeeName}
        </Typography>
            <Typography style={{paddingLeft: '5px', marginTop: '2px', fontSize: '12px', color: '#2E3033'}}>
           ({data?.employeeId})
            </Typography>
            </Grid2>
      <Divider style={{margin: '6px'}}/>

      <Grid2 container justifyContent="space-between" style={{margin: '0px', padding: '2px 8px'}}>
        <Grid2 item md={2} xs={12} >
          <Typography className={classes.employeeDetailsHeader}>
            Location
            </Typography>
      <Typography className={classes.employeeDetails} >{data?.location ? data?.location : '--'}</Typography>
        </Grid2>
        <Divider orientation="vertical" flexItem className={classes.dividerStyle}/>
        <Grid2 item md={2} xs={12} >
          <Typography className={classes.employeeDetailsHeader}>
            Date of Joining
            </Typography>
          <Typography className={classes.employeeDetails}>
          {data?.dateOfJoining ? data?.dateOfJoining : '--'}
            </Typography>
        </Grid2>
        <Divider orientation="vertical" flexItem className={classes.dividerStyle}/>
        <Grid2 item md={2} xs={12} >
          <Typography className={classes.employeeDetailsHeader}>
         Date Of Resignation
         </Typography>
          <Typography className={classes.employeeDetails}>
         {data?.dateOfResignation ? data?.dateOfResignation : '--'}
         </Typography>
        </Grid2>
        <Divider orientation="vertical" flexItem className={classes.dividerStyle}/>
        <Grid2 item md={2} xs={12} >
           <Typography className={classes.employeeDetailsHeader}>
           Reason Of Leaving
         </Typography>
         <Typography className={classes.employeeDetails}>
         {data?.resignReason ? data?.resignReason : '--'}
         </Typography>
        </Grid2>
        <Divider orientation="vertical" flexItem className={classes.dividerStyle}/>
        <Grid2 item md={2} xs={12} >
           <Typography className={classes.employeeDetailsHeader}>
           Date of Leaving
         </Typography>
         <Typography className={classes.employeeDetails}>
         {data?.dol ? data?.dol : '--'}
         </Typography>
        </Grid2>
      </Grid2>
      </Card>
    </Grid2>
    </Grid2>
        </Box>
    );
}
export default EmployeeProfileCard;