import axios from "axios";
import handleSuccess from "./handleSuccess";
import handleErrors from "./handleError";

export { default as DownloadFile } from "./fileDownload";

axios.interceptors.response.use(handleSuccess, handleErrors);

/**
 * @typedef BaseApiParams
 * @type {object}
 * @property {any} data - data to be sent through api
 * @property {string} accessToken - access token to be sent
 * @property {string} endPoint -
 */

/**
 * @typedef ApiResponse
 * @type {object}
 * @property {object} responseObject - can be null
 * @property {number} code
 * @property {string} message
 */

/**
 * @typedef MultipartExtraParam
 * @type {object}
 * @property {function} onUploadProgress
 */

/**
 * @typedef MultipartApiParams
 * @type {MultipartExtraParam & BaseApiParams}
 */

/**
 * @param {string} url - Url endpoint
 * @param {BaseApiParams} apiParams - Api Parameters
 * @return {Promise}
 */
export function post(url, { data, accessToken, params, endPoint = "" }) {
  const { cancel, cancelToken } = genCancelToken();

  const request = axios.post(endPoint + url, data, {
    params,
    cancelToken,
    headers: createHeader({ accessToken }),
  });
  request.cancel = cancel;
  return request;
}
/**
 * @param {string} url - Url endpoint
 * @param {MultipartApiParams}
 * @param {string} endPoint - Optional base url
 * @return {Promise}
 */
export function multipart(
  url,
  { data, onUploadProgress = () => {}, accessToken, endPoint }
) {
  const { cancel, cancelToken } = genCancelToken();
  const body = createFileFormData(data);

  const request = axios.post(endPoint + url, body, {
    cancelToken,
    headers: createHeader({
      accessToken,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
    onUploadProgress: (progressEvent) => {
      onUploadProgress(progressEvent);
    },
  });
  //   console.log("multipart---->", url, { data, onUploadProgress }, endPoint);
  request.cancel = cancel;
  return request;
}

// this function should be used in a scneario where you want to get response as blob (POST Request)
export function multipartBlob(
  url,
  { data, onUploadProgress = () => {}, accessToken, endPoint }
) {
  const { cancel, cancelToken } = genCancelToken();
  const body = createFileFormData(data);
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(endPoint + url, body, {
        cancelToken,
        responseType: "blob",
        headers: createHeader({
          accessToken,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }),
        onUploadProgress: (progressEvent) => {
          onUploadProgress(progressEvent);
        },
      });
      const reader = new window.FileReader();
      reader.readAsDataURL(response);
      reader.onload = function () {
        resolve(reader.result);
      };
    } catch (e) {
      reject(e);
    }
  });
}

/**
 * @param {string} url - Url endpoint
 * @param {MultipartApiParams}
 * @param {string} endPoint - Optional base url
 * @return {Promise}
 */
export function multipartPut(
  url,
  { data, onUploadProgress = () => {}, accessToken, endPoint }
) {
  const { cancel, cancelToken } = genCancelToken();
  const body = createFileFormData(data);

  const request = axios.put(endPoint + url, body, {
    cancelToken,
    headers: createHeader({
      accessToken,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
    onUploadProgress: (progressEvent) => {
      onUploadProgress(progressEvent);
    },
  });
  //   console.log("multipart---->", url, { data, onUploadProgress }, endPoint);
  request.cancel = cancel;
  return request;
}

/**
 * @param {string} url - Url endpoint
 * @param {BaseApiParams} apiParams - Api Parameters
 * @return {Promise<ApiResponse>}
 */
export function get(url, { data, accessToken, endPoint = "" }) {
  const { cancel, cancelToken } = genCancelToken();
  const request = axios.get(endPoint + url, {
    cancelToken,
    headers: createHeader({ accessToken }),
    params: data,
  });
  request.cancel = cancel;
  return request;
}

export function getBlob(url, { data, accessToken, endPoint = "" }) {
  const { cancelToken } = genCancelToken();
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(endPoint + url, {
        responseType: "blob",
        cancelToken,
        headers: createHeader({ accessToken }),
        params: data,
      });
      const reader = new window.FileReader();
      reader.readAsDataURL(response);
      reader.onload = function () {
        resolve(reader.result);
      };
    } catch (e) {
      reject(e);
    }
  });
}

/**
 * @param {string} url - Url endpoint
 * @param {BaseApiParams} apiParams - Api Parameters
 * @return {Promise<ApiResponse>}
 */
export function deleteCall(url, { data, accessToken, endPoint = "" }) {
  const { cancel, cancelToken } = genCancelToken();
  const request = axios.delete(endPoint + url, {
    cancelToken,
    headers: createHeader({ accessToken }),
    data,
  });
  request.cancel = cancel;
  return request;
}

/**
 * @param {string} url - Url endpoint
 * @param {BaseApiParams} apiParams - Api Parameters
 * @return {Promise<ApiResponse>}
 */
export function deleteCallParams(url, { data, accessToken, endPoint = "" }) {
  const { cancel, cancelToken } = genCancelToken();
  const request = axios.delete(endPoint + url, {
    cancelToken,
    headers: createHeader({ accessToken }),
    params: data,
  });
  request.cancel = cancel;
  return request;
}

/**
 * @param {string} url - Url endpoint
 * @param {BaseApiParams} apiParams - Api Parameters
 * @return {Promise}
 */
export function put(url, { data, accessToken, params, endPoint = "" }) {
  const { cancel, cancelToken } = genCancelToken();
  const request = axios.put(endPoint + url, data, {
    params,
    cancelToken,
    headers: createHeader({ accessToken }),
  });
  request.cancel = cancel;
  return request;
}

export function createHeader({ accessToken, headers = {} }) {
  const header = {
    // 'Content-Type': 'application/json',
    // 'Access-Control-Allow-Origin': '*',
    ...headers,
  };
  if (accessToken) {
    header.Authorization = `Bearer ${accessToken}`;
  }

  return header;
}

function genCancelToken() {
  let cancel;
  const cancelToken = new axios.CancelToken((c) => {
    cancel = c;
  });
  return { cancel, cancelToken };
}

function createFileFormData(data) {
  const body = new FormData();
  Object.keys(data).forEach((key) => {
    body.append(key, data[key]);
  });

  return body;
}
