import { Box } from "components";
import React, { useEffect, useState } from "react";
import SavingAccount from "./index";
import Header from "layouts/drawerAndHeader/Header/Header";
import { useDispatch, useSelector } from "react-redux";
import { setFilterData, setFormData } from "./reducers";
import DataTable from "components/SideDataTable";
import Investment from "services/api/investment";
import {
  GET_PRODUCT_DETAILS,
  GET_PRODUCT_DETAILS_BY_ACCOUNTNUMBER,
} from "store/types";

function OpenProductAccount() {
  const [filter, setFilter] = useState(0);
  const dispatch = useDispatch();
  const savingAccount = useSelector((state: any) => state.savingAccount);
  const drawer = useSelector((state: any) => state.drawer);

  const handleBankDetailsChange = (value: string, name: any) => {
    dispatch(setFilterData({ name: name, value: value }));
  };

  const handleOpenTable = () => {
    setFilter((prev) => prev + 1);
  };

  const handleClickRow = (rowData: any) => {
    dispatch(setFormData({ name: "isAddNewProduct", value: true }));
    dispatch(setFormData({ name: "isProductDetailsView", value: true }));
    dispatch({
      type: GET_PRODUCT_DETAILS_BY_ACCOUNTNUMBER,
      payload: { accountNumber: rowData?.value },
    });
  };

  const title = [
    { label: "Member" },
    { label: "Transaction" },
    { label: "Member" },
  ];

  const columns = [
    { id: "value", label: "Account No." },
    { id: "id", label: "Member ID" },
  ];

  useEffect(() => {
    dispatch({
      type: GET_PRODUCT_DETAILS,
      payload: {
        productCategoryId: savingAccount?.productCategoryId,
        productId: savingAccount?.productId,
      },
    });
  }, [savingAccount?.productCategoryId, savingAccount?.productId]);

  return (
    <Box style={{ display: "flex", flexDirection: "row", paddingTop: 0 }}>
      <Header title={title} />

      {drawer?.isMiniDrawerOpen && (
        <Box>
          <DataTable
            key={filter}
            sampleData={[]}
            onRowClick={handleClickRow}
            hookConfig={{ api: Investment?.getInvestmentMaster }}
            initialParams={savingAccount?.filters}
            columns={columns}
            PAGE_SIZE={30}
          />
        </Box>
      )}

      <SavingAccount handleOpenTable={handleOpenTable} />
    </Box>
  );
}

export default OpenProductAccount;
