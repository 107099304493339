export const calculateAge = (dateOfBirth: string): number => {
    // Parse the date of birth string into a Date object
    const birthDate = new Date(dateOfBirth);
    // Get the current date
    const today = new Date();
    // Calculate the age in milliseconds
    const ageInMilliseconds = today.getTime() - birthDate.getTime();
    // Convert milliseconds to years (approximation)
    const ageInYears = Math.floor(
      ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25)
    );
    return ageInYears;
  };