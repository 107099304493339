import React from 'react';
import { Drawer, List, ListItem, ListItemIcon } from '@mui/material';
import { HomeOutlined, DashboardOutlined, PeopleAltOutlined } from '@mui/icons-material';
import DrawerHeader from './DrawerHeader';
import { useSelector } from 'react-redux';
import { MenuItemss } from '../constant';
import {icons, IconName}  from './iconConstant';

interface MiniDrawerProps {
  onHover?: () => void;
  // icon?: IconName;
  // isMiniDrawerOpen: boolean;
}

interface MenuItem {
  label: string;
  icon?: IconName; 
  id?: number | undefined;
}

const MiniDrawer: React.FC<MiniDrawerProps> = ({ onHover }) => {
  

  const drawer = useSelector((state: any) => state.drawer);
  return (
    <Drawer
      variant="permanent"
      onMouseEnter={onHover}
      ModalProps={{ keepMounted: true }}
      sx={{
        width: drawer.isMiniDrawerOpen ? 64 : 0,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawer.isMiniDrawerOpen ? 64 : 0,
          transition: 'width 0.3s',
        },
      }}
    >
      <DrawerHeader isMiniDrawerOpen={drawer.isMiniDrawerOpen}/> 
            <List>
        {drawer?.sideMenuList?.map((menuItem: MenuItem) => {
          const Icons = menuItem.icon ? icons[menuItem.icon] : null;
          // const keys = Object.keys(drawer?.openMenus);
          // const Id = menuItem.id?.toString()
          // const isActiveMenu = menuItem.id !== undefined && keys.includes(menuItem.id.toString());
          // const isEqual = menuItem.icon === icons[menuItem.icon];


          return (
            <ListItem
               key={menuItem.label}
              //  sx={{
              //   backgroundColor: isActiveMenu ? 'rgba(0, 0, 0, 0.1)' : 'transparent',
              //  }}
    >
      <ListItemIcon 
      // sx={{ color: isActiveMenu ? '#1F7A8C' : '#7C8081' }}
      >
        {Icons ? <Icons /> : null}
      </ListItemIcon>
    </ListItem>
  );
})}
      </List>

    </Drawer>
  );
};

export default MiniDrawer;

