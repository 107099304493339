import * as _ from "lodash";
import sanitizeError from "./sanitizeError";

export const defaultErrorMessage = "Please try again";

/**
 *
 * @param {Error} error
 */
function handleErrors(error) {
  const message =
    sanitizeError(error?.response?.data?.message, defaultErrorMessage) ||
    defaultErrorMessage;
  const code = error.code || null;
  const data = null;
  const status = _.get(error, "status", _.get(error, "response.status", 701));

  const errorObject = { message, code, data, status };

  if (status == "401") {
    localStorage.clear();
    // window.location.reload();
  }
  return Promise.reject(errorObject);
}

export const uiError = (error) => (defaultMessage) => {
  const errorCodesToPrevent = [701, 702];

  if (
    error &&
    error.status &&
    error.message &&
    !errorCodesToPrevent.includes(Number(error.status))
  ) {
    return error.message;
  }

  return defaultMessage;
};

export default handleErrors;
