import React from 'react';
import Routes from 'routes';
import ThemeCustomization from 'themes';
import ScrollTop from 'components/ScrollTop';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css'
// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App:React.FC = () => (
    <ThemeCustomization>
        <ScrollTop>
            <ToastContainer
                position="top-right"
                autoClose={1500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <Routes />
        </ScrollTop>
    </ThemeCustomization>
);

export default App;
