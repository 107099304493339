import React, { useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import PropTypes, { element, elementType } from "prop-types";
import { makeStyles } from "@mui/styles";
import Palette from "themes/palette";
import moment from 'moment';
const useStyles = makeStyles({
  root: {
    "& .MuiInputBase-input": {
      paddingTop: 7.5,
      paddingBottom: 7.5,
    },
  },
});

const propTypes = {
  labelName: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  inputFormat: PropTypes.string,
  error: PropTypes.string,
  maxDate: PropTypes.string,
  minDate: PropTypes.string,
  placeHolder: PropTypes.string,
  disableFuture: PropTypes.bool,
  onChange: PropTypes.func,
  isMandatory: PropTypes.bool,
  //   views: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const defaultProps = {
  labelName: "",
  onChange: () => {},
  name: "",
  value: "",
  inputFormat: "YYYY-MM-DD",
  error: "",
  maxDate: "",
  minDate: "",
  placeHolder: "Select Date",
  disableFuture: false,
  isMandatory: false
};

const InputDatePicker = (props) => {
  const {
    labelName,
    onChange,
    name,
    value,
    inputFormat,
    error,
    maxDate,
    minDate,
    placeHolder,
    disableFuture,
    isMandatory
    // views,
  } = props;
  const classes = useStyles();

  const handleChange = (newValue) => {
    onChange({ value: moment(newValue?.$d).format('YYYY-MM-DD'), name });
  };

  const shouldShowError = () => {
    if (error) return true;
    return false;
  };

  const { palette } = Palette();

  const textStyle = {
    color: palette.error.main,
    fontSize: "14px",
  };


  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
           label={  
            <>
              {labelName}
              {isMandatory && <sup style={textStyle}>*</sup>}
            </>
            }
          className={classes.root}
          value={value ? dayjs(value, inputFormat) : ""}
          inputFormat={inputFormat}
          onChange={(newValue) => handleChange(newValue)}
          // views={views}
          maxDate={maxDate ? dayjs(maxDate, inputFormat) : ""}
          minDate={minDate ? dayjs(minDate, inputFormat) : ""}
          disableFuture={disableFuture}
          renderInput={(props) => (
            <TextField
            fullWidth
              {...props}
              value={value}
              // placeholder={placeHolder} 
              InputLabelProps={{ shrink: true }}
              error={!!error}
              {...(shouldShowError() && { helperText: error })}
            />
          )}
        />
      </LocalizationProvider>
    </>
  );
};

InputDatePicker.propTypes = propTypes;
InputDatePicker.defaultProps = defaultProps;

export default InputDatePicker;
