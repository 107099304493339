import React, { FormEvent, useEffect, useState } from "react";
import { Box, Button, Stack } from "components";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import SelectProduct from "./SelectProduct";
import ProductDetails from "./ProductDetails";
import CommissionAgent from "./CommissionAgent";
import ProductNominee from "./nominee";
import {
  CREATE_SAVING_ACCOUNT,
  GET_COMMISSION_AGENT_DETAILS,
  GET_PRODUCT_MASTER_DETAILS,
} from "store/types";
import ProductMemberDetails from "./MemberDetails";
import FilterCom from "./Filter";
import { formValidator } from "utils/formValidator";
import { productMemberDetailsSchema } from "./productSchemaValidation";
import { setFormData } from "./reducers";

const useStyles = makeStyles({
  saveBtnContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "10px",
  },
  nextNumber: {
    color: "#fff",
    border: "1px solid #1F7A8C",
    backgroundColor: "#1F7A8C",
    marginLeft: "5px",
    maxWidth: "80px",
    width: "100%",
    "&:hover": {
      color: "#fff",
      border: "1px solid #1F7A8C",
      backgroundColor: "#1F7A8C",
    },
  },
});

function SavingAccount({ handleOpenTable }: any) {
  const savingAccount = useSelector((state: any) => state.savingAccount);
  const dispatch = useDispatch();
  const classess = useStyles();

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const isFormValid: boolean = await formValidator({
      formValuesToBeValidated: savingAccount,
      validationSchema: productMemberDetailsSchema,
      errorCallBackFunction: (formErr: any) => {
        console.log("formError", formErr);

        dispatch(setFormData({ name: "validationError", value: formErr }));
      },
    });

    if (isFormValid) {
      dispatch({ type: CREATE_SAVING_ACCOUNT, payload: { savingAccount } });
    }
  };

  const payload = {
    memberId: savingAccount?.member?.id,
    index: 0,
    label: "productMasterDetails",
  };

  useEffect(() => {
    const memberDetailspayload = {
      memberId: savingAccount?.member?.id,
      index: 0,
      label: "productMasterDetails",
    };

    const commissionAgentpayload = {
      memberId: savingAccount?.commssionAgentForAccount?.id,
      index: 0,
      label: "product_commissionAgent",
    };

    if (savingAccount?.member?.id) {
      dispatch({
        type: GET_PRODUCT_MASTER_DETAILS,
        payload: memberDetailspayload,
      });
    }

    if (savingAccount?.commssionAgentForAccount?.id) {
      dispatch({
        type: GET_COMMISSION_AGENT_DETAILS,
        payload: commissionAgentpayload,
      });
    }
  }, [savingAccount?.member, savingAccount?.commssionAgentForAccount]);

  const shouldRenderProductSection = savingAccount?.isAddNewProduct === true;
  const shouldRenderFilter = savingAccount?.isAddNewProduct === false;

  return (
    <Box p={2}>
      <form onSubmit={handleSubmit}>
        <Stack spacing={2}>
          {shouldRenderFilter && (
            <FilterCom handleOpenTable={handleOpenTable} />
          )}
          {shouldRenderProductSection && (
            <>
              <ProductMemberDetails />
              <SelectProduct />
              <ProductDetails />
              <CommissionAgent />
              <ProductNominee />
              {/* <FormFooter> */}

              <Box className={classess.saveBtnContainer}>
                <Button
                  disabled={savingAccount?.isProductDetailsView}
                  className={classess.nextNumber}
                  size="medium"
                  variant="contained"
                  type="submit"
                >
                  Save
                </Button>
              </Box>

              {/* </FormFooter> */}
            </>
          )}
        </Stack>
      </form>
    </Box>
  );
}

export default SavingAccount;
