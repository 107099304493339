

import React, { useEffect } from 'react';
import { AppBar, IconButton,  Typography, Box,  Toolbar , Breadcrumbs, Button} from '@mui/material';
import { ArrowBack as ArrowBackIcon, Logout as LogoutIcon } from '@mui/icons-material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import PageTitle from './PageTitle';
import { useSelector } from 'react-redux';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router';

interface HeaderProps {
  title: { label: string }[];
  children?: React.ReactNode;

}

const Header: React.FC<HeaderProps> = ({ title }) => {
 
  const drawer = useSelector((state: any) => state.drawer);
  const navigate = useNavigate();
  const handleBack = () => {
        navigate(-1);
  };

  const handleLogout = () => {
    console.log('#######################');
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        // backgroundColor: '#FFFFFF',
        backgroundColor: '#E0F2F1',
        color: '#022B3A',
        zIndex: (theme) => theme.zIndex.drawer + 1,
        transition: 'margin-left 0.3s',
        width: `calc(100% - ${!drawer.isMiniDrawerOpen ? '220px' :'62px'})`,
        boxShadow: 'none', 
        borderBottom: '1px solid #F3F3F3',
      }}
    >
        <Box style={{display: 'flex',width: '100%', marginBottom: '5px'}}>
        <Box style={{display: 'flex', width: '75%'}}>
        
        <IconButton
        aria-label="back"
        onClick={handleBack}
       
        sx={{
          color: '#022B3A',
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: 'transparent',
          },
        }}
      >
        <ArrowBackIcon  style={{ fontSize: '16px' }} />
      </IconButton>
  
        <Breadcrumbs  aria-label="breadcrumb" style={{ marginTop: '8px' }} separator={<KeyboardDoubleArrowRightIcon/>}>
          {title?.map((breadcrumb : any, index : number) => (
            <Box sx = {{border: '1px solid #E3E3E3', borderRadius: 1.5, padding: '1px 8px'}}>
            <Typography key={index} sx={{
              // color: '#7C8081',  
              color: index === title.length - 1 ? '#1F7A8C' : '#7C8081',
              fontFamily: 'Poppins',
         fontWeight: 400,
         fontSize: '10px',
         lineHeight: '20px',}}>
              {breadcrumb.label}
            </Typography>
            </Box>            
          ))}
          
        </Breadcrumbs> 
      
    </Box>
        <Box style={{width: '25%',   display: 'flex',
  flexDirection: 'row',
  justifyContent: 'end',}}>
            <Box sx = {{border: '1px solid #E3E3E3', borderRadius: 1.5, padding: '0px 4px',  height: '24px', marginTop: '8px'}}>
                <Typography  sx={{color: '#7C8081',  fontFamily: 'Poppins',
         fontWeight: 500,
         fontSize: '10px',
         lineHeight: '20px',
         marginTop: '1px'
         }}>
              Ragini Kumari | 123456
            </Typography>
            </Box>
            </Box> 
    
        <Box sx = {{border: '1px solid #E3E3E3', borderRadius: 1.5, height: '24px', marginTop: '8px', marginLeft: '5px'}}>
        <IconButton
        onClick={handleLogout}
        sx={{  color: '#022B3A',  padding: 0 ,backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: 'transparent',
          },
           height: 0,
        }}
      >
       <PowerSettingsNewIcon 
       style={{ fontSize: '20px' }} 
       />
      </IconButton>
  </Box>
        </Box>
      </AppBar>
  );
};

export default Header;

