import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';

const propTypes = {
    leftIcon: PropTypes.oneOfType([PropTypes.elementType, PropTypes.node]),
    headerTitle: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired,
    headerColor: PropTypes.string
};

const defaultProps = {
    leftIcon: <CloseIcon />,
    headerTitle: 'Modal',
    headerColor: 'transparent'
};

const useStyles = makeStyles({
    appHeader: {
        boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.05), 0px 4px 16px rgba(0, 0, 0, 0.05)'
    },
    headerTitle: {
        font: 'DM Sans',
        fontWeight: 400,
        fontSize: '20px',
        color: '#2E3033'
    }
});

const PopUpHeader = (props) => {
    const { leftIcon, headerTitle, handleClose, headerColor } = props;
    const classes = useStyles();
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" color={headerColor} className={classes.appHeader}>
                <Toolbar variant="dense">
                    <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 1 }} onClick={handleClose}>
                        {leftIcon || <CloseIcon />}
                    </IconButton>
                    <Typography className={classes.headerTitle}>
                        {headerTitle}
                    </Typography>
                </Toolbar>
            </AppBar>
        </Box>
    );
};
PopUpHeader.propTypes = propTypes;
PopUpHeader.defaultProps = defaultProps;
export default PopUpHeader;
