/* eslint-disable no-unused-vars */
import { useState, useCallback, useEffect } from "react";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Typography } from "@mui/material";
import Box from "components/Box";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Button from "@mui/material/Button";
import Dropzone from "react-dropzone";
import Grid2 from "@mui/material/Unstable_Grid2";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { makeStyles } from "@mui/styles";
import { Tooltip } from "@mui/material";
import { CloudUploadOutlined } from "@ant-design/icons";
import { ReactComponent as UploadDocumentImg } from "assets/images/PromoteMember/UploadDocumentImg.svg";
import { ReactComponent as SuccessCheckBox } from "assets/images/icons/SuccsessCheckbox.svg";

import Palette from "themes/palette";
import Preview from "./Preview";
import { UPLOAD_DOCUMENT_URL } from "store/types";

const useStyles = makeStyles(() => ({
  centerText: {
    textAlign: "center",
    justifyContent: "center",
    marginTop: "10px",
    color: "#1F7A8C",
  },
  button: {
    backgroundColor: "#F7F7F7",
    color: "#fff",
    border: "1px solid #F7F7F7",
    "&:hover": {
      backgroundColor: "#fff",
      color: "#F7F7F7",
    },
  },
  approveButton: {
    backgroundColor: "#F7F7F7",
    color: "green",
    border: "1px solid #F7F7F7",
    "&:hover": {
      backgroundColor: "#fff",
      color: "#F7F7F7",
    },
  },
  rejectButton: {
    backgroundColor: "#F7F7F7",
    color: "red",
    border: "1px solid #F7F7F7",
    "&:hover": {
      backgroundColor: "#fff",
      color: "#F7F7F7",
    },
  },
  boxStyledDropZone: {
    backgroundColor: "#F2F2F3",
    borderRadius: "6px",
    height: "218px",
    display: "flex",
    width: "100%",
  },
  dragAndDroptextStyle: {
    fontWeight: 400,
    font: "DM Sans",
    fontSize: "16px",
    color: "#333333",
  },
  browsetextStyle: {
    fontWeight: 700,
    font: "DM Sans",
    fontSize: "16px",
    color: "#5F46D4",
    textDecoration: "underline",
    marginLeft: "5px",
  },
  deleteBtn: {
    fontSize: "10px",
    padding: "0px 0px 8px 0px",
    color: "#E41D1D",
    "&:hover": {
      backgroundColor: "transparent",
      color: "#E41D1D",
    },
  },
  uploadedFileStyle: {
    font: "Mulish",
    fontWeight: 400,
    fontSize: "12px",
    color: "#0F0F0F",
    padding: "5px 15px",
  },
  supportedFormatText: {
    font: "Mulish",
    fontWeight: 400,
    fontSize: "10px",
    color: "#676767",
    textAlign: "center",
  },
}));

const SingleFileUploader = (props) => {
  const dispatch = useDispatch();
  const saveImages = props.value || [];
  const [showImage, setShowImage] = useState(false);
  const [myDetails, setMyDetails] = useState("");
  const [open, setOpen] = useState(false);
  const { palette } = Palette();
  const classes = useStyles();

  const handleShowImageUp = () => {
    const doc = props.value;
    setMyDetails({ name: props.docName, preview: doc });
    setShowImage((prev) => !prev);
  };

  const handleGetURL = () => {
    props.getDocUrl();
    handleShowImageUp();
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length === 0) {
        return;
      }
      const newFile = acceptedFiles[0];
      if (saveImages.length > 0) {
        // If there is already a file, replace it with the new one
        props.onChange([newFile], props.name);
      } else {
        // If no file is present, add the new file
        props.onChange([...saveImages, newFile], props.name);
      }

      const reader = new FileReader();
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        const binaryStr = reader.result;
        // You can do something with binaryStr if needed
      };
      reader.readAsArrayBuffer(newFile);
    },
    [saveImages, props]
  );

  const deleteImage = (idx) => {
    let imagesPresent = Array.from(saveImages);
    imagesPresent.splice(idx, 1);
    props.onChange(imagesPresent, props.name);
  };

  const getDocName = () => {
    let name = '';
    name = props.docName;
    name = name?.length > 20 ? name.substring(0, 20) + "..." : name;
    return name;
  }

  const onApproveClick = () => {
    props.onApproveClick(props.docId)
  }

  const onRejectClick = () => {
    props.onRejectClick(props.docId)
  }

  return (
    <>
      {showImage && (
        <Preview
          myDetails={myDetails}
          showImage={showImage}
          setShowImage={setShowImage}
        />
      )}
      {props.type === "button" && (
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <Box style={{ display: "flex" }}>
          <Dropzone onDrop={onDrop}>
            {({ getRootProps, getInputProps }) => (
              <Box {...getRootProps()}>
                <Box>
                  <input {...getInputProps()} />
                  <Button
                    sx={{ minWidth: "140px" }}
                    fullWidth
                    variant="contained"
                    size={"small"}
                    className={classes.button}
                    startIcon={
                      <UploadDocumentImg style={{ fontSize: "14px" }} />
                    }
                  >
                    <Typography sx={{ color: "#1F7A8C" }}>
                      Choose File
                    </Typography>
                  </Button>
                </Box>
              </Box>
            )}
          </Dropzone>

          {/* show uploaded file name */}
          <Box sx={{ marginTop: "5px" }}>
            <Tooltip title={props.docName} placement="top">
              <Typography className={classes.uploadedFileStyle}>
                {getDocName()}
              </Typography>
            </Tooltip>
          </Box>

          <Box show={props.docName}>
            <Button
              variant="contained"
              size={"small"}
              className={classes.button}
              onClick={() => {
                  handleGetURL(props?.doc?.documentId);
              }}
              sx={{ marginTop: "5px", fontSize: "14px" }}
            >
              <Typography
                sx={{
                  color: "#1F7A8C"
                }}
              >
                View
              </Typography>
            </Button>
          </Box>
          </Box>

          <Box show={props.docName} style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
          <Box show={props?.isVerified !== "Y"}>
            <Button
                variant="contained"
                size={"small"}
                className={classes.approveButton}
                onClick={onApproveClick}
                sx={{ marginTop: "5px", fontSize: "14px", marginRight: "10px" }}
              >
                <Typography
                  sx={{
                    color: "green"
                  }}
                >
                  Approve
                </Typography>
            </Button>
            </Box>

            <Box>
            <Button
              variant="contained"
              size={"small"}
              className={classes.rejectButton}
              onClick={onRejectClick}
              sx={{ marginTop: "5px", fontSize: "14px", marginRight: "10px" }}
            >
              <Typography
                sx={{
                  color: "red"
                }}
              >
                Reject
              </Typography>
            </Button>
            </Box>
              <Box
                sx={{
                  display: "flex",
                  marginRight: "10px",
                  width: "20px"
                }}
              >
                <Box sx={{ display: "flex", gap: "12px" }} show={props?.isVerified === "Y"}>
                  <SuccessCheckBox />
                </Box>
              </Box>
          </Box>
        </Box>
      )}

      {/* {props.type === "box" && (
        <>
          <Box className={classes.boxStyledDropZone}>
            <Dropzone accept={props.accept} onDrop={onDrop} multiple={true}>
              {({ getRootProps, getInputProps }) => (
                <>
                  <Grid2 container style={{ width: "100%", height: "40px" }}>
                    <Grid2
                      xs={12}
                      md={12}
                      {...getRootProps()}
                      style={{
                        borderRadius: "8px",
                        marginTop: 4,
                        display: "grid",
                        justifyContent: "center",
                      }}
                    >
                      <input {...getInputProps()} />
                      <Box style={{ alignItems: "justify" }}>
                        <CloudUploadOutlined
                          style={{
                            color: "#5F46D4",
                            fontSize: "50px",
                            marginLeft: "65px",
                          }}
                        />
                      </Box>
                      <Box style={{ alignItems: "justify", display: "flex" }}>
                        <Typography className={classes.dragAndDroptextStyle}>
                          Drag and Drop or
                        </Typography>
                        <Typography className={classes.browsetextStyle}>
                          Browse
                        </Typography>
                      </Box>
                      <Box style={{ alignItems: "justify" }}>
                        <Typography className={classes.supportedFormatText}>
                          {`Supported Format: ${props.accept}`}
                        </Typography>
                      </Box>
                    </Grid2>
                    <>
                      {saveImages.length !== 0 ? (
                        <Box
                          style={{
                            display: "flex",
                            width: "100%",
                            border: "1px solid green",
                            height: "30px",
                            margin: "25px 0px 0px 15px",
                            borderRadius: "4px",
                            backgroundColor: "#fff",
                          }}
                        >
                          <Box
                            style={{
                              width: "90%",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <Tooltip
                              title={saveImages[0]?.name}
                              placement="top"
                            >
                              <Typography className={classes.uploadedFileStyle}>
                                {saveImages[0]?.name}
                              </Typography>
                            </Tooltip>
                          </Box>
                          <Box
                            style={{
                              width: "10%",
                              paddingLeft: "0px 5px",
                            }}
                          >
                            <IconButton
                              variant="outlined"
                              className={classes.deleteBtn}
                              onClick={() => deleteImage(saveImages[0])}
                            >
                              <DeleteForeverIcon />
                            </IconButton>
                          </Box>
                        </Box>
                      ) : null}
                    </>
                  </Grid2>
                </>
              )}
            </Dropzone>
          </Box>
        </>
      )} */}
    </>
  );
};
export default SingleFileUploader;
