
import React from 'react';
import { Box, Typography, IconButton, useTheme } from '@mui/material';
import ProjectIcon from '@mui/icons-material/Work'; 
import image from 'assets/images/ujalaLogo/ujalaLogo.png';


interface DrawerHeaderProps {
  isMiniDrawerOpen: boolean; 
 
}

const DrawerHeader: React.FC<DrawerHeaderProps> = ({isMiniDrawerOpen}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        // padding: theme.spacing(1),
        // backgroundColor: theme.palette.background.paper,
        padding: '8px 14px 8px 10px',
        backgroundColor: '#1C6E6B',
        height: '42px'
      }}
    >
   
      {/* <IconButton
        edge="start"
        // sx={{
        //   marginRight: theme.spacing(2),
        // }}
      >
        <ProjectIcon />
      </IconButton> */}
       <Box
            component="img"
            src={image}
            alt="Right Grid Image"
            style={{padding: '2px'}}
          />
      {!isMiniDrawerOpen && (
        <Typography sx={{fontSize: '14px', color: '#fff'}} noWrap>
         Ujala Co-operative Society
        </Typography>
       )} 
    </Box>
  );
};

export default DrawerHeader;

