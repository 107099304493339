import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import TextFieldBase from "components/TextField";
import { makeStyles } from "@mui/styles";
import Select from "components/Select";
import { NO_OF_NOMINEES, SPACING } from "containers/members/constant";
import ShowErrorMessage from "containers/members/showErrorMessage";
import { calculateAge } from "utils/calculateAge";
import { CustomBox, InputDatePicker } from "components";
import Investment from "services/api/investment";

import {
  setNomineeDetails,
  clearErrors,
  setTotalNomineePercentage,
  updateNomineeDetail,
  setFormData,
} from "containers/savingAccount/reducers";
import {
  GET_ENTROLLED_PRODUCT_LIST,
  GET_NOMINEE_DETAILS_BY_MEMBERID_AND_PRODUCTID,
} from "store/types";

const useStyles = makeStyles({
  minorNomineeTextLabel: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "italic",
    color: "#022B3A",
  },
  nomineeTextLabel: {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "15px",
    textAlign: "left",
    color: "#1F7A8C",
  },
});

const ProductNominee = ({ errors }: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const savingAccount = useSelector((state: any) => state.savingAccount);

  const handleNomineeInputChange = (
    value: string,
    name: any,
    index?: any,
    thirdParam?: string
  ) => {
    if (!name) return;
    if (name === "name") {
      dispatch(setNomineeDetails({ name, value, index, thirdParam }));
    }
    if (name === "pincode" && value?.length === 6) {
      dispatch({
        type: "ADDRESS_BY_PINCODE",
        payload: {
          pincode: value,
          addType: "NOMINEE_DETAILS",
          index: index,
          thirdParam: thirdParam,
        },
      });
    }

    if (name === "pincode" && value?.length > 6) return;
    if (name === "contactNo" && value?.length > 10) return;

    if (name === "nomPercentage" && parseInt(value) > 100) return;
    dispatch(setNomineeDetails({ name, value, index, thirdParam }));
    dispatch(clearErrors({ name: `nomineeDetails[${index}].${name}` }));

    if (thirdParam === "guardian") {
      dispatch(
        clearErrors({ name: `nomineeDetails[${index}][guardian]?.${name}` })
      );
    }
  };

  const selectNoOfNomineeChange = (value: any) => {
    dispatch(setFormData({ name: "noOfNominees", value: value?.value }));
    dispatch(updateNomineeDetail({ nomineeCount: value.value }));
  };

  useEffect(() => {
    if (savingAccount?.totalPercentage === 100) {
      dispatch(clearErrors({ name: "totalPercentage" }));
    }
    dispatch(setTotalNomineePercentage({}));
  }, [savingAccount?.nominee]);

  const selectProductName = (value: any) => {
    dispatch(
      setFormData({
        name: "selectedEntrolledProductValue",
        value: value?.value,
      })
    );
    dispatch(
      setFormData({ name: "selectedEntrolledProductId", value: value?.id })
    );
    dispatch({
      type: GET_NOMINEE_DETAILS_BY_MEMBERID_AND_PRODUCTID,
      payload: {
        memberId: savingAccount?.memberId?.id,
        productId: value?.id,
      },
    });
  };

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === "isNomineeExit") {
      dispatch({
        type: GET_ENTROLLED_PRODUCT_LIST,
        payload: { memberId: savingAccount?.member?.id },
      });
      dispatch({
        type: GET_NOMINEE_DETAILS_BY_MEMBERID_AND_PRODUCTID,
        payload: {
          memberId: savingAccount?.member?.id,
          productId: savingAccount?.isSelfExistingNominee
            ? null
            : savingAccount?.selectedEntrolledProductId,
        },
      });
      dispatch(
        setFormData({
          name: "isNomineeExit",
          value: event.target.checked,
        })
      );
    }

    if (event.target.name === "isSelfExistingNominee") {
      dispatch({
        type: GET_NOMINEE_DETAILS_BY_MEMBERID_AND_PRODUCTID,
        payload: {
          memberId: savingAccount?.member?.id,
          productId: event.target.checked
            ? null
            : savingAccount?.selectedEntrolledProductId,
        },
      });

      dispatch(
        setFormData({
          name: "isSelfExistingNominee",
          value: event.target.checked,
        })
      );
    }
  };

  useEffect(() => {
    if (savingAccount?.totalPercentage === 100) {
      dispatch(clearErrors({ name: "totalPercentage" }));
    }
    dispatch(setTotalNomineePercentage({}));
  }, [savingAccount?.nomineeDetails]);

  return (
    <CustomBox style={{ marginBottom: "12px" }} label={"Nominee Detail"}>
      <Grid container spacing={SPACING} sx={{ marginBottom: "32px" }}>
        {/* <Grid item xs={12} sm={6} md={6} lg={3}>
          <FormControlLabel
            control={
              <Checkbox
                disableRipple
                name="isNomineeExit"
                onChange={handleCheck}
                checked={savingAccount?.isNomineeExit}
                size="small"
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label="Choose Existing Nominee"
          />
        </Grid> */}

        {/* {savingAccount?.isNomineeExit ? (
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <FormControlLabel
              control={
                <Checkbox
                  disableRipple
                  name="isSelfExistingNominee"
                  onChange={handleCheck}
                  checked={savingAccount?.isSelfExistingNominee}
                  size="small"
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Main Nominee"
            />
          </Grid>
        ) : null} */}

        {/* {savingAccount?.isNomineeExit ? (
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <FormControlLabel
              control={
                <Checkbox
                  disableRipple
                  name="isProductPageNominee"
                  onChange={handleCheck}
                  checked={savingAccount?.isSelfExistingNominee}
                  size="small"
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Product Based Nominee"
            />
          </Grid>
        ) : null} */}

        {/* {savingAccount?.isNomineeExit &&
        savingAccount?.isSelfExistingNominee === false ? (
          <Grid item xs={12} sm={6} md={6} lg={2}>
            <Select
              name="selectedOwnProductName"
              multi={false}
              labelName={"Product Name"}
              api={Investment.getEnrolledProductList}
              params={{
                memberId: savingAccount?.memberId?.id,
              }}
              data={savingAccount?.productNameList}
              onChangeSelect={selectProductName}
              value={savingAccount?.selectedEntrolledProductValue}
            />
            <ShowErrorMessage
              message={savingAccount.validationError?.["totalPercentage"]}
            />
          </Grid>
        ) : null} */}

        <Grid item xs={12} sm={6} md={6} lg={2}>
          <Select
            disabled={
              savingAccount?.isProductDetailsView

              // savingAccount?.isNomineeExit ||
              // savingAccount?.isSelfExistingNominee
            }
            name="noOfNominees"
            multi={false}
            labelName={"No. of Nominee"}
            data={NO_OF_NOMINEES}
            onChangeSelect={selectNoOfNomineeChange}
            value={savingAccount?.noOfNominees}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={2}>
          <TextFieldBase
            disabled
            type="number"
            name="totalPercentage"
            placeHolder={"Total Percentage"}
            labelName={"Total Percentage(%)"}
            onChange={handleNomineeInputChange}
            value={savingAccount.totalPercentage}
          />
          <ShowErrorMessage
            message={savingAccount?.validationError?.["totalPercentage"]}
          />
        </Grid>
      </Grid>

      {savingAccount?.nomineeDetails?.map((nomineeMember: any, index: any) => (
        <React.Fragment key={index}>
          <Grid container spacing={SPACING} style={{ marginBottom: "16px" }}>
            <Grid item xs={12} sm={4} md={3} lg={3}>
              <TextFieldBase
                name={"fullName"}
                placeHolder={"Full Name"}
                labelName={"Full Name"}
                onChange={(value, name) =>
                  handleNomineeInputChange(value, name, index)
                }
                value={savingAccount.nomineeDetails[index].fullName}
                showError={savingAccount.validationError?.hasOwnProperty(
                  `nomineeDetails[${index}].name`
                )}
                disabled={savingAccount?.isProductDetailsView}
              />

              <ShowErrorMessage
                message={
                  savingAccount.validationError?.[
                    `nomineeDetails[${index}].name`
                  ]
                }
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2}>
              <TextFieldBase
                type="number"
                name="nomPercentage"
                // placeHolder={"60"}
                labelName={"Percentage(%)"}
                onChange={(value, name) =>
                  handleNomineeInputChange(value, name, index)
                }
                value={savingAccount.nomineeDetails[index].nomPercentage}
                showError={savingAccount.validationError?.hasOwnProperty(
                  `nomineeDetails[${index}].nomPercentage`
                )}
                disabled={savingAccount?.isProductDetailsView}
              />
              <ShowErrorMessage
                message={
                  savingAccount.validationError?.[
                    `nomineeDetails[${index}].nomPercentage`
                  ]
                }
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2}>
              <TextFieldBase
                name="relation"
                placeHolder={"Relationship"}
                labelName={"Relationship"}
                onChange={(value, name) =>
                  handleNomineeInputChange(value, name, index)
                }
                value={savingAccount.nomineeDetails[index].relation}
                showError={savingAccount.validationError?.hasOwnProperty(
                  `nomineeDetails[${index}].relation`
                )}
                disabled={savingAccount?.isProductDetailsView}
              />
              <ShowErrorMessage
                message={
                  savingAccount.validationError?.[
                    `nomineeDetails[${index}].relation`
                  ]
                }
              />
            </Grid>

            <Grid item xs={12} sm={4} md={3} lg={2}>
              <InputDatePicker
                disableFuture={true}
                name="dob"
                placeHolder={"DOB"}
                onChange={(value: string) =>
                  handleNomineeInputChange(value, "dob", index)
                }
                value={savingAccount.nomineeDetails[index].dob}
              />
              <ShowErrorMessage
                message={
                  savingAccount.validationError?.[
                    `nomineeDetails[${index}].dob`
                  ]
                }
              />
            </Grid>

            <Grid item xs={12} sm={4} md={3} lg={2}>
              <TextFieldBase
                type="number"
                name="nominee_age"
                placeHolder={"e.g 35"}
                labelName={"Age(Y)"}
                value={calculateAge(
                  savingAccount.nomineeDetails[index].dob
                ).toString()}
                disabled={true}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={3} lg={2}>
              <TextFieldBase
                type="number"
                name="pincode"
                placeHolder={"Pincode"}
                labelName={"Pincode"}
                onChange={(value, name) =>
                  handleNomineeInputChange(value, name, index)
                }
                value={savingAccount.nomineeDetails[index].pincode}
                showError={savingAccount.validationError?.hasOwnProperty(
                  `nomineeDetails[${index}].pincode`
                )}
                disabled={savingAccount?.isProductDetailsView}
              />
              <ShowErrorMessage
                message={
                  savingAccount.validationError?.[
                    `nomineeDetails[${index}].pincode`
                  ]
                }
              />
            </Grid>

            <Grid item xs={12} sm={4} md={3} lg={2}>
              <TextFieldBase
                name="city"
                placeHolder={"City"}
                labelName={"City"}
                onChange={(value, name) =>
                  handleNomineeInputChange(value, name, index)
                }
                value={savingAccount.nomineeDetails[index].city}
                showError={savingAccount.validationError?.hasOwnProperty(
                  `nomineeDetails[${index}].city`
                )}
                disabled={savingAccount?.isProductDetailsView}
              />
              <ShowErrorMessage
                message={
                  savingAccount.validationError?.[
                    `nomineeDetails[${index}].city`
                  ]
                }
              />
            </Grid>

            <Grid item xs={12} sm={4} md={3} lg={2}>
              <TextFieldBase
                name="state"
                placeHolder={"State"}
                labelName={"State"}
                onChange={(value, name) =>
                  handleNomineeInputChange(value, name, index)
                }
                value={savingAccount.nomineeDetails[index].state}
                showError={savingAccount.validationError?.hasOwnProperty(
                  `nomineeDetails[${index}].state`
                )}
                disabled={savingAccount?.isProductDetailsView}
              />
              <ShowErrorMessage
                message={
                  savingAccount.validationError?.[
                    `nomineeDetails[${index}].state`
                  ]
                }
              />
            </Grid>

            <Grid item xs={12} sm={4} md={3} lg={6}>
              <TextFieldBase
                name="address"
                placeHolder={"Current Address"}
                labelName={"Current Address"}
                value={savingAccount.nomineeDetails[index].address}
                onChange={(value, name) =>
                  handleNomineeInputChange(value, name, index)
                }
                showError={savingAccount.validationError?.hasOwnProperty(
                  `nomineeDetails[${index}].address`
                )}
                disabled={savingAccount?.isProductDetailsView}
              />
              <ShowErrorMessage
                message={
                  savingAccount.validationError?.[
                    `nomineeDetails[${index}].address`
                  ]
                }
              />
            </Grid>

            {calculateAge(savingAccount.nomineeDetails[index].dob) < 18 ? (
              <>
                <Grid item xs={12}>
                  <Typography className={classes.minorNomineeTextLabel}>
                    Nominee is minor i.e. less than 18, Please fill below
                    details.
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={4} md={3} lg={2}>
                  <TextFieldBase
                    name="relationShip"
                    placeHolder={"RelationShip"}
                    labelName={"RelationShip"}
                    onChange={(value, name) =>
                      handleNomineeInputChange(value, name, index, "guardian")
                    }
                    value={
                      savingAccount.nomineeDetails[index]?.guardian
                        ?.relationShip
                    }
                    showError={errors && errors.hasOwnProperty("guardianName")}
                    disabled={savingAccount?.isProductDetailsView}
                  />
                  <ShowErrorMessage
                    message={
                      savingAccount?.validationError
                        ? savingAccount?.validationError[
                            `nomineeDetails[0].guardian.relationShip`
                          ]
                        : ""
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={4} md={3} lg={2}>
                  <TextFieldBase
                    name="name"
                    placeHolder={"Guardian Name"}
                    labelName={"Guardian Name"}
                    onChange={(value, name) =>
                      handleNomineeInputChange(value, name, index, "guardian")
                    }
                    value={savingAccount.nomineeDetails[index]?.guardian?.name}
                    showError={errors && errors.hasOwnProperty("guardianName")}
                    disabled={savingAccount?.isProductDetailsView}
                  />
                  <ShowErrorMessage
                    message={
                      savingAccount.validationError?.[
                        `nomineeDetails[${index}].guardian?.name`
                      ]
                    }
                  />
                  <ShowErrorMessage
                    message={
                      savingAccount?.validationError
                        ? savingAccount?.validationError[
                            `nomineeDetails[0].guardian.name`
                          ]
                        : ""
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={4} md={3} lg={2}>
                  <TextFieldBase
                    type="number"
                    name="contactNo"
                    placeHolder={"Contact No"}
                    labelName={"Contact No"}
                    onChange={(value, name) =>
                      handleNomineeInputChange(value, name, index, "guardian")
                    }
                    value={
                      savingAccount.nomineeDetails[index]?.guardian?.contactNo
                    }
                    showError={errors && errors.hasOwnProperty("guardianName")}
                    disabled={savingAccount?.isProductDetailsView}
                  />
                  <ShowErrorMessage
                    message={
                      savingAccount?.validationError
                        ? savingAccount?.validationError[
                            `nomineeDetails[0].guardian.contactNo`
                          ]
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2}>
                  <InputDatePicker
                    disableFuture={true}
                    name="dob"
                    placeHolder={"DOB"}
                    onChange={(value: string) =>
                      handleNomineeInputChange(value, "dob", index, "guardian")
                    }
                    value={savingAccount.nomineeDetails[index]?.guardian?.dob}
                  />
                  <ShowErrorMessage
                    message={
                      savingAccount?.validationError
                        ? savingAccount?.validationError[
                            `nomineeDetails[0].guardian.dob`
                          ]
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2}>
                  <TextFieldBase
                    type="number"
                    name="pincode"
                    placeHolder={"Pincode"}
                    labelName={"Pincode"}
                    onChange={(value, name) =>
                      handleNomineeInputChange(value, name, index, "guardian")
                    }
                    value={
                      savingAccount.nomineeDetails[index]?.guardian?.pincode
                    }
                    disabled={savingAccount?.isProductDetailsView}
                  />
                  <ShowErrorMessage
                    message={
                      savingAccount?.validationError
                        ? savingAccount?.validationError[
                            `nomineeDetails[0].guardian.pincode`
                          ]
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2}>
                  <TextFieldBase
                    name="state"
                    placeHolder={"State"}
                    labelName={"State"}
                    onChange={(value, name) =>
                      handleNomineeInputChange(value, name, index, "guardian")
                    }
                    value={savingAccount.nomineeDetails[index]?.guardian?.state}
                    disabled={savingAccount?.isProductDetailsView}
                  />
                  <ShowErrorMessage
                    message={
                      savingAccount?.validationError
                        ? savingAccount?.validationError[
                            `nomineeDetails[0].guardian.state`
                          ]
                        : ""
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={4} md={3} lg={2}>
                  <TextFieldBase
                    name="city"
                    placeHolder={"City"}
                    labelName={"City"}
                    value={savingAccount.nomineeDetails[index]?.guardian?.city}
                    onChange={(value, name) =>
                      handleNomineeInputChange(value, name, index, "guardian")
                    }
                    disabled={savingAccount?.isProductDetailsView}
                  />
                  <ShowErrorMessage
                    message={
                      savingAccount?.validationError
                        ? savingAccount?.validationError[
                            `nomineeDetails[0].guardian.city`
                          ]
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={4}>
                  <TextFieldBase
                    name="address"
                    placeHolder={"Current Address"}
                    labelName={"Current Address"}
                    onChange={(value, name) =>
                      handleNomineeInputChange(value, name, index, "guardian")
                    }
                    value={
                      savingAccount.nomineeDetails[index]?.guardian?.address
                    }
                    disabled={savingAccount?.isProductDetailsView}
                  />
                  <ShowErrorMessage
                    message={
                      savingAccount?.validationError
                        ? savingAccount?.validationError[
                            `nomineeDetails[0].guardian.address`
                          ]
                        : ""
                    }
                  />
                </Grid>
              </>
            ) : (
              <></>
            )}
          </Grid>
          {savingAccount?.nomineeDetails?.length > 1 && (
            <Divider
              style={{
                marginBottom: "16px",
                borderWidth: "1.5px",
              }}
            />
          )}
        </React.Fragment>
      ))}
    </CustomBox>
  );
};

export default ProductNominee;
