
import React from 'react';
import PropTypes from 'prop-types'; 
import {Box,Typography} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
const useStyles = makeStyles({
    loaderView:{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '80vh',
      flexDirection: 'column',
      
      },
      loaderText:{
        marginTop:4,
        letterSpacing:3,
        color:"#5F46D4"
      }
});

const propTypes={
    loaderText: PropTypes.string.isRequired
};

const defaultProps = {
    loaderText: 'Please Wait ...'
}

const LoaderWithText = ({loaderText}) => {
    const classes = useStyles();
  return (
  
    <Box className={classes.loaderView} >
    <CircularProgress style={{color:"#5F46D4" }}/>
    <Typography className={classes.loaderText} variant="body1">{loaderText}</Typography>
    </Box>
  )
};

LoaderWithText.propTypes = propTypes;
LoaderWithText.defaultProps = defaultProps;

export default LoaderWithText
