/* eslint-disable */
import React from 'react';
import Grid2 from '@mui/material/Unstable_Grid2';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

const useStyles = makeStyles(() => ({
  actionButtons: {
    marginLeft: 'auto',
    marginBottom: 5,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  listItem: {
    marginTop: 10,
  },
  paper: {
    width: '100%',
    marginTop: 5,
    padding: 10,
  },
}));

const SkeletonVariants = () => {
  const classes = useStyles();
  return (
    <Grid2
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className={classes.container}
    >
      <Paper elevation={2} className={classes.paper}>
        <Box className={classes.actionButtons}>
          <Skeleton width={380} visible={true} height={64} />
        </Box>
        <Grid2
          container
          direction="row"
          justifyContent="space-between"
          // alignItems="flex-end"
          // className={classes.welcomeBackground}
        >
          <Grid2 item xs={12} sm={3}>
            <Skeleton width={150} visible={true} variant="text" />
          </Grid2>

          <Grid2 item xs={12} sm={3}>
            <Skeleton width={150} visible={true} variant="text" />
          </Grid2>
          <Grid2 item xs={12} sm={3}>
            <Skeleton width={150} visible={true} variant="text" />
          </Grid2>
          <Grid2 item xs={12} sm={3}>
            <Skeleton width={150} visible={true} variant="text" />
          </Grid2>
        </Grid2>

        {new Array(5).fill(' ').map((el, index) => {
          return (
            <Grid2
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
              className={classes.listItem}
              spacing={2}
              key={index}
            >
              <Grid2 item xs={12} sm={3}>
                <Skeleton width={80} visible={true} variant="text" />
              </Grid2>

              <Grid2 item xs={12} sm={3}>
                <Skeleton width={80} visible={true} variant="text" />
              </Grid2>
              <Grid2 item xs={12} sm={3}>
                <Skeleton width={80} visible={true} variant="text" />
              </Grid2>
              <Grid2 item xs={12} sm={3}>
                <Skeleton width={80} visible={true} variant="text" />
              </Grid2>
            </Grid2>
          );
        })}
      </Paper>

      <Paper elevation={2} className={classes.paper}>
        <Grid2
          container
          direction="row"
          justifyContent="flex-end"
          // alignItems="flex-end"
          className={classes.footer}
        >
          <Grid2 item xs={12} sm={1}>
            <Skeleton width={80} visible={true} height={40} />
          </Grid2>

          <Grid2 item xs={12} sm={1}>
            <Skeleton width={80} visible={true} height={40} />
          </Grid2>
          <Grid2 item xs={12} sm={1}>
            <Skeleton width={80} visible={true} height={40} />
          </Grid2>
        </Grid2>
      </Paper>
    </Grid2>
  );
};

export default SkeletonVariants;
