// third-party
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { createReduxHistoryContext } from "redux-first-history";
import { createBrowserHistory } from "history";

// project import
import rootReducer, { rM, createRH } from './reducers/index';
import rootSaga from './sagas/index';

// ==============================|| REDUX TOOLKIT - MAIN STORE ||============================== //
const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware, rM];

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        }).concat(middlewares)
});

sagaMiddleware.run(rootSaga);

// reload reducers during hot reload

//   if (process.env.NODE_ENV !== 'production' && module.hot) {
//     module.hot.accept('./reducers', () => store.replaceReducer(rootReducer))
//   }

let persistor = persistStore(store);

const { dispatch } = store;

const history = createRH(store);

export { store, dispatch, persistor, history };
