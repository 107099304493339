import React, {  useCallback, useEffect, useRef } from 'react';
import { Box, Toolbar ,Drawer} from '@mui/material';

import SideMenu from './Drawer/MainDrawer';
import Header from './Header/Header';
import { Outlet } from 'react-router-dom';
import  MiniDrawer  from './Drawer/MiniDrawer';
import { useDispatch, useSelector } from 'react-redux';
import { 
  openMiniDrawer,
  closeMiniDrawer,
  openLargeTemporaryDrawer,
  closeLargeTemporaryDrawer
 } from './reducers';
import { useLocation } from 'react-router-dom';
// import PerfectScrollbar from "react-perfect-scrollbar";
// import "react-perfect-scrollbar/dist/css/styles.css";
interface AppDrawerProps {
  title: { label: string }[];
}
const AppDrawer: React.FC<AppDrawerProps> = ({ title }) => {
  const drawer = useSelector((state: any) => state.drawer);

  const { isDrawerTemporary, isMiniDrawerOpen } = drawer;

  const dispatch = useDispatch();
  const location = useLocation();
  const previousLocationRef = useRef<string | null>(null);


useEffect(() => {
  previousLocationRef.current = location.pathname;
}, [location]);

  const handleMiniDrawerHover = useCallback(() => {
    dispatch(closeMiniDrawer());
    dispatch(openLargeTemporaryDrawer());
  }, [ isDrawerTemporary, isMiniDrawerOpen]);
  
  const handleDrawerLeave = useCallback(() => {

    if(isDrawerTemporary){ // it means mini drawer is enabled in background
      dispatch(openMiniDrawer());
      dispatch(closeLargeTemporaryDrawer());
    }
  }, [ isDrawerTemporary, isMiniDrawerOpen]);
console.log(isMiniDrawerOpen, '$%%%%%%%%$$$$$$$$$$$$');
  return (
    <Box style={{ display: 'flex', width: '100%' }}>
      { isMiniDrawerOpen ?
            <Box>
            <MiniDrawer
              onHover={handleMiniDrawerHover}
            />
          </Box>
    :
          <Box>
          <Drawer
            variant={ isDrawerTemporary ? "temporary" : "permanent" }
            open={!isMiniDrawerOpen}
            ModalProps={{ keepMounted: true }}
            sx={{
              width: !isMiniDrawerOpen ? 220 : 0,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: !isMiniDrawerOpen ? 220 : 0,
                transition: 'width 0.3s',
                overflow: 'hidden',
                height: '100vh',
                backgroundColor: '#E0F2F1',
                // backgroundColor: '#1C6E6B',
              },
              '& .drawer-content': {
                height: '100%',
                overflow: 'hidden',
              }
            }}
            onClose={handleDrawerLeave}
          >
            <SideMenu isMiniDrawerOpen={isMiniDrawerOpen} />
          </Drawer> 
          </Box>
    }

        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
    
    <Header  title={[]} />  
       {/* <PerfectScrollbar style={{height: '100vh'}}> */}
        <Box
        component="main"
        sx={{
          width: "100%",
          height: "100vh", // this may be buggy
          flexGrow: 1,
          backgroundColor: "#fff",
          
         transition: 'margin-left 0.3s',       
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
      {/* </PerfectScrollbar> */}
    </Box>
    </Box>
    // </Box>
  );
};

export default AppDrawer;