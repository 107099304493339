import React from "react";

import { Grid } from "@mui/material";
import TextFieldBase from "components/TextField";
import { useDispatch, useSelector } from "react-redux";

import {
  clearErrors,
  setAddressByType,
  setBankDetails,
} from "containers/members/reducers";
import { ADDRESS_BY_PINCODE, BANK_DETAILS_BY_IFSC } from "store/types";
import { SPACING } from "containers/members/constant";
import ShowErrorMessage from "containers/members/showErrorMessage";

function BankDetails() {
  const dispatch = useDispatch();

  const addNewMember = useSelector((state: any) => state.addNewMember);

  const handleBankDetailsChange = (value: string, name: any) => {
    if (name === "accountNumber" && value.length === 18) return;
    if (name === "ifsc" && value.length === 12) return;
    dispatch(
      setBankDetails({
        name: name,
        value: name === "ifsc" ? value.toLocaleUpperCase() : value,
      })
    );
    if (name === "ifsc" && value.length === 11) {
      dispatch({ type: BANK_DETAILS_BY_IFSC, payload: { ifscCode: value } });
    }
    dispatch(clearErrors({ name: `bankDetails.${name}` }));
  };

  return (
    <div>
      <Grid container spacing={SPACING}>
        <Grid item xs={12} sm={4} md={3} lg={2}>
          <TextFieldBase
          isMandatory={true}
            type="number"
            name="accountNumber"
            labelName={"Account No."}
            placeHolder={"Account No."}
            onChange={handleBankDetailsChange}
            value={addNewMember.bankDetails?.accountNumber}
            showError={
              addNewMember?.formErrors &&
              addNewMember?.formErrors.hasOwnProperty(
                "bankDetails.accountNumber"
              )
            }
          />
          {addNewMember?.formErrors &&
            addNewMember?.formErrors.hasOwnProperty(
              "bankDetails.accountNumber"
            ) && (
              <ShowErrorMessage
                message={addNewMember?.formErrors["bankDetails.accountNumber"]}
              />
            )}
        </Grid>

        <Grid item xs={12} sm={4} md={3} lg={2}>
          <TextFieldBase
          isMandatory={true}
            name="accountHolderName"
            placeHolder={"Account Holder Name"}
            labelName={"Account Holder Name"}
            onChange={handleBankDetailsChange}
            value={addNewMember.bankDetails?.accountHolderName}
            showError={
              addNewMember?.formErrors &&
              addNewMember?.formErrors.hasOwnProperty(
                "bankDetails.accountHolderName"
              )
            }
          />
          {addNewMember?.formErrors &&
            addNewMember?.formErrors.hasOwnProperty(
              "bankDetails.accountHolderName"
            ) && (
              <ShowErrorMessage
                message={
                  addNewMember?.formErrors["bankDetails.accountHolderName"]
                }
              />
            )}
        </Grid>
        <Grid item xs={12} sm={4} lg={2}>
          <TextFieldBase
          isMandatory={true}
            name="ifsc"
            placeHolder={"IFSC"}
            labelName={"IFSC"}
            onChange={handleBankDetailsChange}
            value={addNewMember.bankDetails?.ifsc}
            showError={
              addNewMember?.formErrors &&
              addNewMember?.formErrors.hasOwnProperty("bankDetails.ifsc")
            }
          />
          {addNewMember?.formErrors &&
            addNewMember?.formErrors.hasOwnProperty("bankDetails.ifsc") && (
              <ShowErrorMessage
                message={addNewMember?.formErrors["bankDetails.ifsc"]}
              />
            )}
        </Grid>
        <Grid item xs={12} sm={4} lg={2}>
          <TextFieldBase
          isMandatory={true}
            name="bankName"
            placeHolder={"Bank Name"}
            labelName={"Bank Name"}
            onChange={handleBankDetailsChange}
            value={addNewMember.bankDetails?.bankName}
            showError={
              addNewMember?.formErrors &&
              addNewMember?.formErrors.hasOwnProperty("bankDetails.bankName")
            }
          />
          {addNewMember?.formErrors &&
            addNewMember?.formErrors.hasOwnProperty("bankName.bankDetails") && (
              <ShowErrorMessage
                message={addNewMember?.formErrors["bankDetails.bankName"]}
              />
            )}
        </Grid>

        <Grid item xs={12} sm={4} lg={2}>
          <TextFieldBase
          isMandatory={true}
            name="branchName"
            placeHolder={"branchName"}
            labelName={"branchName"}
            onChange={handleBankDetailsChange}
            value={addNewMember.bankDetails?.branchName}
            showError={
              addNewMember?.formErrors &&
              addNewMember?.formErrors.hasOwnProperty("bankDetails.branchName")
            }
          />
          {addNewMember?.formErrors &&
            addNewMember?.formErrors.hasOwnProperty(
              "bankDetails.branchName"
            ) && (
              <ShowErrorMessage
                message={addNewMember?.formErrors["bankDetails.branchName"]}
              />
            )}
        </Grid>
      </Grid>
    </div>
  );
}

export default BankDetails;
