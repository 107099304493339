import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MenuItem } from './types';  // Import the MenuItem type
interface SideMenuState {
  sideMenuList: MenuItem[];
  activeMenuItem: string;
  openMenus: Record<string, boolean>;

  isMiniDrawerOpen: boolean;
  isDrawerTemporary: boolean;
  showTable: boolean;
  
}
const initialState: SideMenuState = {
  sideMenuList: [],
  activeMenuItem: '',
  openMenus: {},
  isMiniDrawerOpen: false,
  isDrawerTemporary: false,
  showTable: false,
};
const sideMenuSlice = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    saveSideMenuList(state, action: PayloadAction<MenuItem[]>) {
      state.sideMenuList = action.payload;
    },
    setDrawerItems(state, action) {

      const Id = action.payload;
  
      const isOpen = state.openMenus[Id];
      state.openMenus = {
        ...state.openMenus,
        [Id]: !isOpen,
      };
      state.activeMenuItem = Id;
    },
    setActiveMenuItem (state, action) {
      state.activeMenuItem = action.payload;
    },
    setShowTable (state, action) {
      state.showTable = action.payload;
},
    openMiniDrawer (state) {
      state.isMiniDrawerOpen = true
    },
    closeMiniDrawer (state) {
      state.isMiniDrawerOpen = false
    },
    openLargeTemporaryDrawer (state) {
      state.isDrawerTemporary = true
    },
    closeLargeTemporaryDrawer (state) {
      state.isDrawerTemporary = false
    },

  },
});
export const { 
  saveSideMenuList,
  setDrawerItems,
  setActiveMenuItem,
  setShowTable,
  openMiniDrawer,
  closeMiniDrawer,
  openLargeTemporaryDrawer,
  closeLargeTemporaryDrawer,

 } = sideMenuSlice.actions;
export default sideMenuSlice.reducer;