import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, CustomBox, Grid, TextFieldBase } from "components";
import { SPACING } from "containers/members/constant";
import { clearErrors, setCommissionAgentData, setFormData } from "./reducers";
import {
  GET_COMMISSION_AGENT_DETAILS,
  GET_PRODUCT_MASTER_DETAILS,
} from "store/types";
import SearchableSelectBase from "../../components/SearchableSelect/index";
import Ujala from "services/api/ujala";
import ShowErrorMessage from "containers/members/showErrorMessage";

function ProductMemberDetails() {
  const [params, setParams] = useState("");
  const savingAccount = useSelector((state: any) => state.savingAccount);
  const dispatch = useDispatch();

  const handleInputChnage = (value: string, name: any) => {
    if (name === "memberId" && value?.length > 2) {
      dispatch({
        type: GET_COMMISSION_AGENT_DETAILS,
        payload: { memberId: savingAccount?.commissionAgent?.memberId },
      });
    }
    dispatch(setCommissionAgentData({ name, value }));
  };

  const onTextInputChange = (value: any) => {
    setParams(value);
  };

  const onSearchableSelect = (selected: any, index?: any, label?: string) => {
    const payload = {
      memberId: selected?.id,
      index: index,
      label: "productMasterDetails",
    };

    if (selected?.id) {
      dispatch({
        type: GET_PRODUCT_MASTER_DETAILS,
        payload: payload,
      });
    }
    dispatch(setFormData({ name: "member", value: selected }));
    dispatch(clearErrors({ name: "member" }));
  };

  return (
    <CustomBox label="Member Details">
      <Grid container spacing={SPACING}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <SearchableSelectBase
            disabled={savingAccount?.isProductDetailsView}
            labelName={"MemberId"}
            onTextInputChange={onTextInputChange}
            onChangeSelect={(value) => onSearchableSelect(value, "", "")}
            placeHolder={"MemberId"}
            params={{ memberId: params }}
            callApiOnFirstRender={true}
            api={Ujala.getMemberList}
            value={savingAccount.member}
          />
          <Box sx={{ marginTop: "6px" }}>
            {savingAccount?.validationError &&
              savingAccount?.validationError.hasOwnProperty("member") && (
                <ShowErrorMessage
                  message={savingAccount?.validationError?.member}
                />
              )}
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextFieldBase
            disabled
            name="name"
            placeHolder={"MemberName"}
            labelName={"MemberName"}
            onChange={handleInputChnage}
            value={savingAccount?.productMemberDetails.name}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextFieldBase
            disabled
            name="mobileNo"
            placeHolder={"Mobile Number"}
            labelName={"Mobile Number"}
            onChange={handleInputChnage}
            value={savingAccount?.productMemberDetails.mobileNo}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextFieldBase
            disabled
            name="type"
            placeHolder={"Member Type"}
            labelName={"Member Type"}
            onChange={handleInputChnage}
            value={savingAccount?.productMemberDetails.type}
          />
        </Grid>
      </Grid>
    </CustomBox>
  );
}

export default ProductMemberDetails;
