import React from 'react';
import { 
  AssignmentOutlined as AssignmentOutlinedIcon, 
  AccountBalanceOutlined as AccountBalanceOutlinedIcon, 
  AccountTreeOutlined as AccountTreeOutlinedIcon, 
  GroupAddOutlined as GroupAddOutlinedIcon,
  AddchartOutlined as AddchartOutlinedIcon 
} from '@mui/icons-material';

// Defined type that includes all possible icon keys

export type IconName = 'AssignmentOutlined' | 
                'AccountBalanceOutlined' | 
                'AccountTreeOutlined' | 
                'GroupAddOutlined' | 'AddchartOutlined';

// icons mapping
export const icons: Record<IconName, React.ComponentType<any>> = {
  'AssignmentOutlined': AssignmentOutlinedIcon,
  'AccountBalanceOutlined': AccountBalanceOutlinedIcon,
  'AccountTreeOutlined': AccountTreeOutlinedIcon,
  'GroupAddOutlined': GroupAddOutlinedIcon,
  'AddchartOutlined' : AddchartOutlinedIcon
};

const IconComponent = ({ iconName }: { iconName: IconName }) => {
  const Icon = icons[iconName];
  return <Icon />;
};

export default IconComponent;


