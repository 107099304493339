/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useEffect } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import Dialog from '@mui/material/Dialog';

const PreviewBase = ({ myDetails, showImage, setShowImage }) => {
    const [viewDetails, setViewDetails] = useState();
    useEffect(() => {
        if (myDetails && myDetails?.name.includes('pdf')) {
            setViewDetails(true);
        } else {
            setViewDetails(false);
        }
    }, [myDetails]);
    return (
        <Dialog
            open={showImage}
            onClose={() => setShowImage(!showImage)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
                '& .MuiDialog-container': {
                    '& .MuiPaper-root': {
                        width: '60%',
                        height: '80%' // Set your width here
                    }
                }
            }}
        >
            {viewDetails ? (
                <iframe src={myDetails?.preview} webkitAllowFullScreen mozallowfullscreen allowFullScreen height="100%" width="100%" />
            ) : (
                <img
                    src={myDetails.preview}
                    alt="details"
                    style={{ width: '100%', height: '100%' }}
                    onLoad={() => {
                        URL.revokeObjectURL(myDetails?.preview);
                    }}
                />
            )}
        </Dialog>
    );
};
export default PreviewBase;
