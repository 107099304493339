import { INVESTMENT_ENDPOINT } from "services/endPoints";
import {
  get as getAxios,
  DownloadFile as downloadFileService,
  post as postAxios,
  multipart,
} from "../apiHandler";

const endPoint = `${process.env.REACT_APP_BASE_URL}${INVESTMENT_ENDPOINT}`;

const get = (url, { accessToken, data }) => {
  return getAxios(url, { accessToken, data, endPoint });
};
const post = (url, { accessToken, data, params }) => {
  return postAxios(url, { accessToken, data, endPoint, params });
};

const downloadFile = (url, { accessToken, data }, nativeProps) => {
  return downloadFileService(url, { accessToken, data, endPoint }, nativeProps);
};

const uploadFile = (url, { accessToken, data }) => {
  return multipart(url, { accessToken, data, endPoint });
};
export default class Investment {
  static getProductTypes({ accessToken, data }) {
    const getProductTypesRequest = post("/product/getProductTypes", {
      accessToken,
      data,
    });
    
    Investment.getProductTypes.cancel = getProductTypesRequest.cancel;
    return getProductTypesRequest;
  }

  static getProductList({ accessToken, data }) {
    const getProductListRequest = post("/product/getProductMasters", {
      accessToken,
      data,
    });
    Investment.getProductList.cancel = getProductListRequest.cancel;
    return getProductListRequest;
  }

  static getMenuList({ accessToken, data }) {
    const getMenuListRequest = post("/menu/getMenuList", {
      accessToken,
      data,
    });
    Investment.getMenuList.cancel = getMenuListRequest.cancel;
    return getMenuListRequest;
  }

  // SAVING ACCOUNT

  static getProductCategoryTypeList({ accessToken, data }) {
    const getProductCategoryTypeListRequest = post("/product/getProductTypes", {
      accessToken,
      data,
    });
    Investment.getProductCategoryTypeList.cancel =
      getProductCategoryTypeListRequest.cancel;
    return getProductCategoryTypeListRequest;
  }

  static getProductNameList({ accessToken, data }) {
    const getProductNameListRequest = post(
      "/investmentAccount/getProductNamesList",
      {
        accessToken,
        data,
      }
    );
    Investment.getProductNameList.cancel = getProductNameListRequest.cancel;
    return getProductNameListRequest;
  }

  // TODO:
  static getSelectedProductDetails({ accessToken, data }) {
    const getSelectedProductDetailsRequest = post(
      "/investmentAccount/getProductDetail",
      {
        accessToken,
        data,
      }
    );
    Investment.getSelectedProductDetails.cancel =
      getSelectedProductDetailsRequest.cancel;
    return getSelectedProductDetailsRequest;
  }

  static getCommisssionAgentDetails({ accessToken, data }) {
    const getCommisssionAgentDetailsRequest = post(
      "/investmentAccount/getCommissionAgentMember",
      {
        accessToken,
        data,
      }
    );
    Investment.getCommisssionAgentDetails.cancel =
      getCommisssionAgentDetailsRequest.cancel;
    return getCommisssionAgentDetailsRequest;
  }

  static getInvestmentMaster({ accessToken, data }) {
    const getInvestmentMasterRequest = post("/investment/getInvestmentMaster", {
      accessToken,
      data,
    });
    Investment.getInvestmentMaster.cancel = getInvestmentMasterRequest.cancel;
    return getInvestmentMasterRequest;
  }

  static getEnrolledProductList({ accessToken, data }) {
    const getEnrolledProductListRequest = post(
      "/investmentAccount/getEnrolledProductList",
      {
        accessToken,
        data,
      }
    );
    Investment.getEnrolledProductList.cancel =
      getEnrolledProductListRequest.cancel;
    return getEnrolledProductListRequest;
  }

  static getNomineeDetailsByMemberIdAndProductId({ accessToken, data }) {
    const getNomineeDetailsByMemberIdAndProductIdtRequest = post(
      "/investmentAccount/NomineeDetailsByMemberId",
      {
        accessToken,
        data,
      }
    );
    Investment.getNomineeDetailsByMemberIdAndProductId.cancel =
      getNomineeDetailsByMemberIdAndProductIdtRequest.cancel;
    return getNomineeDetailsByMemberIdAndProductIdtRequest;
  }
  static openProductAccount({ accessToken, data }) {
    const openProductAccountRequest = post("/investmentAccount/openAccount", {
      accessToken,
      data,
    });
    Investment.openProductAccount.cancel = openProductAccountRequest.cancel;
    return openProductAccountRequest;
  }

  static getProductDetailsByAccountNumber({ accessToken, data }) {
    const getProductDetailsByAccountNumberRequest = post(
      "/investment/getInvestmentDetails",
      {
        accessToken,
        data,
      }
    );
    Investment.getProductDetailsByAccountNumber.cancel =
      getProductDetailsByAccountNumberRequest.cancel;
    return getProductDetailsByAccountNumberRequest;
  }
}
