/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { TableBody, TableViewCol, TableBodyRow, TableBodyCell } from 'mui-datatables';
import AddIcon from '@mui/icons-material/Add';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { TableCell, TableRow } from '@mui/material';
import * as _ from 'lodash';
import Checkbox from '@mui/material/Checkbox';

const CustomRow = (props) => {
    const { actions = [], actionButtonToolTip = [], onActionClick, getBulkActionData } = props.customProps;
    const tableRowRef = useRef();
    const [showAction, setShowAction] = useState(false);
    const [checked, setChecked] = useState(false);

    const handleChange = (event) => {
        setChecked(event.target.checked);
        getBulkActionData({ rowData: props.completeData[props.rowIndex], status: event.target.checked });
    };

    const getActionIcon = (iconType) => {
        switch (iconType) {
            case 'delete':
                return <DeleteRoundedIcon color="error" />;

            case 'add':
                return <AddIcon />;

            case 'edit':
                return <EditOutlinedIcon style={{ color: '#5F46D4' }} />;

            case 'download':
                return <DownloadRoundedIcon style={{ color: '#5F46D4' }} />;

            case 'view':
                return <VisibilityIcon style={{ color: '#5F46D4' }} />;

            default:
                return <EditIcon style={{ color: '#DD4B39' }} />;
        }
    };
    
    return (
        <Grid
            style={{ display: 'contents', position: 'relative' }}
            onMouseOver={() => {
                setShowAction(true);
            }}
            onMouseLeave={() => setShowAction(false)}
        >
            {showAction && (
                <Grid
                    align="center"
                    style={{
                        width: '100%',
                        position: 'absolute',
                        left: 0,
                        backgroundColor: 'rgba(241,241,241, 0.7)',
                        flex: 1,
                        height: tableRowRef.current.clientHeight
                    }}
                >
                    <Grid
                        style={{
                            height: '100%',
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        {actions.map((item, actionButtonIndex) => {
                            return (
                                <IconButton
                                    title={
                                        actionButtonToolTip.length === actions.length
                                            ? actionButtonToolTip[actionButtonIndex].toUpperCase()
                                            : item.toUpperCase()
                                    }
                                    onClick={() =>
                                        onActionClick({
                                            rowData: props.completeData[props.rowIndex],
                                            rowIndex: props.rowIndex,
                                            itemIndex: props.dataIndex,
                                            actionType: item
                                        })
                                    }
                                    key={actionButtonIndex}
                                >
                                    {getActionIcon(item)}
                                </IconButton>
                            );
                        })}
                    </Grid>
                </Grid>
            )}

            <TableRow hover={true} ref={tableRowRef}>
                {props.areRowsSelectable && (
                    <TableCell>
                        <Checkbox checked={checked} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} />
                    </TableCell>
                )}

                {props.data.map((singleRow, index) => {
                    return <TableCell slign="right">{singleRow}</TableCell>;
                
                })}
            </TableRow>
        </Grid>
    );
};

export default CustomRow;
