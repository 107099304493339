// @ts-ignore
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Stack,Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import axios from 'axios';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import createUseGet from 'hooks/createUseGet';
import Ujala from 'services/api/ujala';
import CircularProgress from '@mui/material/CircularProgress';


interface RowData {
  id: string;
  name: string;
  [key: string]: any;
}

interface Column {
  id: string;
  label: string;
  // align?: 'left' | 'right' | 'center'; 
}
interface DataTableProps {
  sampleData: { id: string; name: string }[];
  onRowClick: (rowData: any) => void;
  initialParams: Record<string, any>;
  hookConfig: { api: (params: any) => Promise<any> };
  columns: Column[];
  PAGE_SIZE: number;
};

const tableHeaderStyle = {
  fontFamily: 'Poppins',
  fontWeight: 500,
  fontSize: '10px',
  lineHeight: '15px',
  color: '#022B3A',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const tableRowTextStyle = {
  fontFamily: 'Poppins',
  fontWeight: 400,
  fontSize: '10px',
  lineHeight: '15px',
  color: '#022B3A',
  '&:hover': {
    color: '#FFFFFF',
  },
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

// const PAGE_SIZE = 17;

const DataTable: React.FC<DataTableProps> = ({ onRowClick, sampleData, initialParams, hookConfig, columns, PAGE_SIZE }) => {
  const [data, setData] = useState<RowData[]>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const scrollbarRef = useRef<PerfectScrollbar | null>(null);

// @ts-ignore
  const useGet = createUseGet({
    api: hookConfig.api
});

const { state , request } = useGet({
  // @ts-ignore
  onSuccess: (response: any) => {
    const newData = response?.data?.list || [];
    setPageNumber(prev=>prev+1);
    setData(prevData => [...prevData, ...newData]);

    if (newData.length < PAGE_SIZE) {
      setHasMore(false);
    }
  },
  // @ts-ignore
  onFail: (error) => {
    console.error('errorrrrrrrrrr', error);
  }
});

  const fetchData = useCallback(async () => {
    if (!hasMore || loading) return;

    setLoading(true);
    await request({
      ...initialParams,
      pageNumber,
      pageSize: PAGE_SIZE,
    }).finally(() => {
      setLoading(false);
    });
  }, [initialParams, pageNumber, loading, hasMore, hookConfig]);

  useEffect(() => {
    fetchData();
  }, []);

  const handleScroll = (container: any) => {
    if (container) {
      const { scrollTop, scrollHeight, clientHeight } = container;
      console.log({'scrollTop':scrollTop, 'scrollHeight': scrollHeight, 'clientHeight' : clientHeight}, '22222222222222222222222' ,
        scrollHeight - scrollTop <= clientHeight + 1, {'scrollTop <= clientHeight + 1': scrollTop <= clientHeight + 1}
      );
      
      // Check if scroll is at the bottom
      if (scrollHeight - scrollTop <= clientHeight + 1 && hasMore) { // Added a small tolerance for precision
        console.log('Reached the bottom of the scroll container');
        fetchData();
      }
    }
  };
  return (
    <div style={{ border: '1px solid #E3E3E3', width: '170px', marginRight: '8px' }}>

   <PerfectScrollbar
    ref={scrollbarRef}
    onScrollY={handleScroll}
    style={{ height: '89vh'}} 
  >
        <Table >
          <TableHead style={{ backgroundColor: '#E3E3E3', height: '34px' }}>
            <TableRow>
              {columns?.map((column) => (
                <TableCell key={column.id} sx={{ padding: '0px 8px' }}>
                  <Typography sx={tableHeaderStyle}>{column.label}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {data?.map((row, index) => (
              <TableRow
                key={row.id}
                sx={{
                  backgroundColor: '#FFFFFF',
                  '&:hover': {
                    backgroundColor: '#1F7A8C',
                  },
                  padding: '10px',
                }}
                onClick={() => onRowClick(row)}
              >
                {columns?.map((column) => (
                  <TableCell sx={{ padding: '8px' }}>
                   
                    <Typography sx={tableRowTextStyle}>{row[column.id]}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
    <div style={{alignItems: 'center', padding: '20px'}}>{data && data.length === 0 && 'No Data Found...'}</div>
        </PerfectScrollbar>
        </div>
  );
};

export default DataTable;