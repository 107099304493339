import React from "react";
import AddressItem from "./AddressItem";
import AddressCheckbox from "./AddressCheckBox";

const AddressComponent = () => {
  return (
    <>
      <AddressItem type="aadhaarAddress" label="Aadhar/ Permament Address" />
      <AddressCheckbox
        name="current_same_as_aadhar_address"
        copyFrom={"aadhaarAddress"}
        copyTo={"currentAddress"}
        type="current_same_as_aadhar_address"
        label="Same as Aadhar Address"
      />
      <AddressItem type="currentAddress" label="Current Address" />
    </>
  );
};

export default AddressComponent;
