import { CustomBox, Grid, InputDatePicker, TextFieldBase } from "components";
import { SPACING } from "containers/members/constant";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, setProductDetails } from "./reducers";
import ShowErrorMessage from "containers/members/showErrorMessage";

function ProductDetails() {
  const savingAccount = useSelector((state: any) => state.savingAccount);
  const dispatch = useDispatch();
  const selectProductCategory = () => {};

  const handleInputChnage = (value: string, name: any) => {
    dispatch(setProductDetails({ name, value }));
    dispatch(clearErrors({ name: `productDetail.${name}` }));
  };

  return (
    <CustomBox label="Product Details">
      {savingAccount?.productCategoryValue === "FD" ? (
        <Grid container spacing={SPACING}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <InputDatePicker
              //   error={errors?.hasOwnProperty("joiningDate")}
              disableFuture={true}
              name="creationDate"
              placeHolder={"Creation Date"}
              onChange={(value: string) =>
                handleInputChnage(value, "creationDate")
              }
              value={savingAccount?.productDetail?.creationDate}
            />
            {savingAccount?.validationError &&
              savingAccount?.validationError.hasOwnProperty(
                "productDetail.creationDate"
              ) && (
                <ShowErrorMessage
                  message={
                    savingAccount?.validationError["productDetail.creationDate"]
                  }
                />
              )}
          </Grid>
          {savingAccount?.isProductDetailsView === true ? (
            <>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <InputDatePicker
                  disableFuture={true}
                  name="closingDate"
                  placeHolder={"Closing Date"}
                  onChange={(value: string) =>
                    handleInputChnage(value, "closingDate")
                  }
                  value={savingAccount?.productDetail?.closingDate}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextFieldBase
                  disabled
                  name="valuePercentage"
                  type="number"
                  placeHolder={"₹5000"}
                  labelName={"Value Percentage"}
                  onChange={handleInputChnage}
                  value={savingAccount?.productDetail?.valuePercentage}
                />
              </Grid>
            </>
          ) : null}

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextFieldBase
              name="amount"
              type="number"
              placeHolder={"₹5000"}
              labelName={"Amount"}
              onChange={handleInputChnage}
              value={savingAccount?.productDetail.amount}
            />

            {savingAccount?.validationError?.["productDetail.amount"] && (
              <ShowErrorMessage
                message={savingAccount?.validationError["productDetail.amount"]}
              />
            )}
          </Grid>

          {/* <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextFieldBase
                disabled
                name="percentageAmount"
                type="number"
                placeHolder={"₹5000"}
                labelName={"Percentage Amount "}
                onChange={handleInputChnage}
                value={savingAccount?.productDetail?.percentageAmount}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextFieldBase
                disabled
                name="tillDatePercentageAmount"
                type="number"
                placeHolder={"₹5000"}
                labelName={"Till Date % Amount"}
                onChange={handleInputChnage}
                value={savingAccount?.productDetail?.tillDatePercentageAmount}
              />
            </Grid> */}

          {/* <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextFieldBase
                disabled
                name="preMatureClosingAmount"
                type="number"
                placeHolder={"₹5000"}
                labelName={"Pre-mature Closing Amt"}
                onChange={handleInputChnage}
                value={savingAccount?.productDetail?.preMatureClosingAmount}
              />
            </Grid> */}

          {/* <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextFieldBase
                disabled
                name="preMature Charges"
                type="number"
                placeHolder={"₹5000"}
                labelName={"Pre- mature Charges"}
                onChange={handleInputChnage}
                value={savingAccount?.productDetail?.preMatureCharges}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextFieldBase
                disabled
                name="preMature Charges"
                type="number"
                placeHolder={"₹5000"}
                labelName={"Bond"}
                onChange={handleInputChnage}
                value={savingAccount?.productDetail?.preMatureCharges}
              />
            </Grid> */}
        </Grid>
      ) : (
        <Grid container spacing={SPACING}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <InputDatePicker
              //   error={errors?.hasOwnProperty("joiningDate")}
              disableFuture={true}
              name="creationDate"
              placeHolder={"Creation Date"}
              onChange={(value: string) =>
                handleInputChnage(value, "creationDate")
              }
              value={savingAccount?.productDetail?.creationDate}
            />
            {savingAccount?.validationError &&
              savingAccount?.validationError.hasOwnProperty(
                "productDetail.creationDate"
              ) && (
                <ShowErrorMessage
                  message={
                    savingAccount?.validationError["productDetail.creationDate"]
                  }
                />
              )}
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextFieldBase
              name="amount"
              type="number"
              placeHolder={"₹5000"}
              labelName={"Amount"}
              onChange={handleInputChnage}
              value={savingAccount?.productDetail.amount}
            />
            {savingAccount?.validationError?.["productDetail.amount"] && (
              <ShowErrorMessage
                message={savingAccount?.validationError["productDetail.amount"]}
              />
            )}
          </Grid>

          {/* <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextFieldBase
                disabled
                name="valuePercentage"
                type="number"
                placeHolder={"₹5000"}
                labelName={"Value Percentage"}
                onChange={handleInputChnage}
                value={savingAccount?.productDetail?.valuePercentage}
              />
            </Grid> */}
        </Grid>
      )}
    </CustomBox>
  );
}

export default ProductDetails;
