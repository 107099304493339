import { fork, call, put, takeLatest, select } from "redux-saga/effects";
import { toast } from "react-toastify";
import { IS_PRODUCT_FOR_SPECIFI_CLIENT, CREATE_OR_EDIT_PRODUCTMASTER, VIEW_PRODUCTMASTER, ADD_SPECIFICATION} from "store/types";
import {setIsClientSpecific, setProductDetails, setisProductEditMode} from "./reducers"

import {viewProductDataUpdate, setisProductViewMode, addToSpecificCLientArray, clearSpecificClientFields} from "./reducers"

import { getCredentials } from "utils/helper";
import { dispatch } from "store/index";

import { ROUTES } from "routes/constant";
interface ResponseItem {
    fieldId: string; 
}

// function* generateEmptySpecifications():any {
//     const productMaster = yield select(state=>state.productMaster);
//     const specs = productMaster.apiResponseClientSpecific.specification;
    
//     let emptySpecs: { [key: string]: object } = {};
//     specs.forEach((item: ResponseItem) => {
//         emptySpecs[item.fieldId] = {};
//     });

//     const payload = {
//         specification: emptySpecs,
//       }
    
//     console.log("wefrgtythrgefd", payload)

//     yield put(setProductDetails({ name: 'onChangeClientSpecific', value: payload }));
// }

function* isProductForSpecifiClientSaga():any{
    const { accessToken } = yield select(getCredentials);
    try{
        const response: Response = yield call(
            fetch, `${process.env.REACT_APP_BASE_URL}/cos-investment-service/product/getSpecificClientsData`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`
                },
            }
        );
        const isProdSpecificResponse:any = yield response.json();
        if(isProdSpecificResponse.code !== 200) {
            // toast.error(isProdSpecificResponse.message);
            console.log("fergthyjthrgef")
        } else {
            yield put(setIsClientSpecific(isProdSpecificResponse.responseObject));
            
            // yield fork(generateEmptySpecifications);
            toast.success(isProdSpecificResponse.message);
        }
    } catch(e:any){
        toast.error(e.message || "Something went wrong !!");
    }
}

function* registerProductSaga(action:any):any{
    const { accessToken } = yield select(getCredentials);
    // try{
    //     const response = yield call(Ujala.createOrEditProductMaster, apiData)
    //     const apiResponse = yield response.json()
    //     if (response.code == 200) {
    //         toast.success("Member registered successfully");
    //     } else {
    //         toast.error(apiResponse?.message);
    //     }
    // }catch (error) {
    //     toast.error(error);
    // }

    console.log("wqefrgtgefwd", action.payload.usefulPayload)
    try{
        const response: Response = yield call(
            fetch, `${process.env.REACT_APP_BASE_URL}/cos-investment-service/product/createOrEditProductMaster`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`
                },
                body: JSON.stringify(action.payload.usefulPayload),
            }
        );
        const isregisterProductSaga:any = yield response.json();
        if(isregisterProductSaga.code !== 200) {
            toast.error(isregisterProductSaga.message);
        } else {
            yield put(setisProductViewMode(false));
            toast.success(isregisterProductSaga.message);
            action.payload.navigate(ROUTES.VIEW_PRODUCT)
        }

    } catch(e:any){
        toast.error(e.message || "Something went wrong !!")
    }

}

function* viewProductMasterSaga(apiData:any):any{
    const { accessToken } = yield select(getCredentials);
    try{
        const response: Response = yield call(
            fetch, `${process.env.REACT_APP_BASE_URL}/cos-investment-service/product/getProductMasterByProductId`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`
                },
                body: JSON.stringify(apiData),
            }
        );
        const viewProductMasterData:any = yield response.json();
        if(viewProductMasterData.code !== 200) {
            toast.error(viewProductMasterData.message);
        } else {
            yield put(setisProductViewMode(true));
            yield put(viewProductDataUpdate(viewProductMasterData.responseObject));
            yield put (setisProductEditMode(true));
            toast.success(viewProductMasterData.message);
        }

    } catch(e:any){
        toast.error(e.message || "Something went wrong !!")
    }

}

function* isProductForSpecifiClient(){
    yield fork(isProductForSpecifiClientSaga)
}

function* registerProduct(action:any){
    console.log("efrtyhrgefds", action)
    yield fork(registerProductSaga, action)
}

function* viewProductMaster(action:any){
    yield fork(viewProductMasterSaga, action.payload);
}

function* addSpecificationSaga():any {
    yield put(addToSpecificCLientArray());
    yield put(clearSpecificClientFields());
    // yield fork(generateEmptySpecifications);

}

function* addSpecification(){
    yield fork(addSpecificationSaga);
}

export default function* saga(){
    yield takeLatest(ADD_SPECIFICATION, addSpecification);
    yield takeLatest(IS_PRODUCT_FOR_SPECIFI_CLIENT, isProductForSpecifiClient);
    yield takeLatest(CREATE_OR_EDIT_PRODUCTMASTER, registerProduct)
    yield takeLatest(VIEW_PRODUCTMASTER, viewProductMaster);
}