import React, { useState, useMemo, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import InputLabel from '@mui/material/InputLabel';
import Palette from 'themes/palette';
import createUseGet from 'hooks/createUseGet';
import PropTypes from 'prop-types';

const propTypes = {
    api: PropTypes.func,
    disabled: PropTypes.bool,
    labelName: PropTypes.string,
    isMandatory: PropTypes.bool
};
const defaultProps = {
    api: () => {},
    disabled: false,
    labelName: '',
    isMandatory: false
};
const Tags = (props) => {
    const { api, labelName, disabled, isMandatory } = props;
    const { palette } = Palette();

    const textStyle = {
        color: palette.error.main,
        fontSize: '16px',
        fontWeight: 'bold'
    };
    const chipSize = {
        height: '20px'
    };

    const [options, setOptions] = useState([]);

    const useGet = createUseGet({
        api
    });

    const { state, request } = useGet({
        onSuccess: ({ data: apiData }) => {
            console.log('api called searchable', apiData);
            setOptions([...apiData]);
        },
        onFail: (e) => {
            console.log('error is', e);
        }
    });

    useEffect(() => {
        if (api && !disabled) {
            request();
        }
    }, []);

    const handleChange = (event, newValue) => {
        props.onChange([...newValue], props.name);
    };

    function debounce(func, timeout = 500) {
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                func.apply(this, args);
            }, timeout);
        };
    }

    const searchForKeyWord = debounce((text) => {
        console.log('user input =>', text);
        if (text.length >= 3) request({ search: text });
    });

    return (
        <>
            <InputLabel sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '36px' }}>
                <Typography>
                    {labelName}
                    {isMandatory ? <sup style={textStyle}>*</sup> : undefined}
                </Typography>
                <Tooltip title="Delete" placement="bottom-end">
                    <IconButton sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <ErrorOutlineIcon fontSize="small" color="primary" />
                    </IconButton>
                </Tooltip>
            </InputLabel>

            <Autocomplete
                multiple
                variant="outlined"
                fullWidth
                limitTags={3}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                getOptionLabel={(option) => option.value}
                options={options}
                loading={state.loading}
                onInputChange={(e, text) => {
                    searchForKeyWord(text);
                }}
                onChange={handleChange}
                disableClearable={true}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        paddingTop: '5.3px!important',
                        paddingBottom: '5.3px!important'
                    },
                    '& .MuiAutocomplete-popupIndicator': {
                        width: '30px!important',
                        height: '30px!important',
                        padding: '0px!important'
                    }
                }}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip
                            variant="outlined"
                            label={option.value}
                            sx={{
                                '& .MuiChip-deleteIcon': {
                                    padding: '2.5px'
                                }
                            }}
                            style={chipSize}
                            color="primary"
                            {...getTagProps({ index })}
                        />
                    ))
                }
                renderInput={(params) => {
                    return <TextField {...params} variant="outlined" size="small" />;
                }}
            />
        </>
    );
};
Tags.propTypes = propTypes;
Tags.defaultProps = defaultProps;
export default Tags;
