import { fork, all } from "redux-saga/effects";

import authenticationSaga from "containers/auth/sagas";
import fetchMemberPromoteDetailsSaga from "containers/memberMaster/Saga";
import addMemberSaga from "containers/members/sagas";
import drawerSaga from "layouts/drawerAndHeader/sagas";
import savingAccountSaga from "containers/savingAccount/sagas";
import productMasterSaga from "containers/productMaster/Saga";

const authSaga = [fork(authenticationSaga)];
const promoteMemberSaga = [fork(fetchMemberPromoteDetailsSaga)];
const memberSaga = [fork(addMemberSaga)];
const productSaga = [fork(productMasterSaga)];
const drawerrSaga = [fork(drawerSaga)];
const product_savingAccountSaga = [fork(savingAccountSaga)];

export default function* rootSaga() {
  yield all([
    ...authSaga,
    ...promoteMemberSaga,
    ...memberSaga,
    ...productSaga,
    ...drawerrSaga,
    ...product_savingAccountSaga,
  ]);
}
