/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import * as _ from 'lodash';
import PropTypes from 'prop-types';
import MaterialTable from './materialTable';
import createUseGet from 'hooks/createUseGet';
import { toast } from 'react-toastify';

const PAGE_SIZE = 10;

const payload = ({ values = {}, pageNumber, pageSize, ...apiParam }) => ({
    ...values,
    pageNumber: pageNumber + 1,
    pageSize,
    ...apiParam
});

const FormDefault = () => {
    return <div>Please provide custom form</div>;
};

const propTypes = {
    columns: PropTypes.array,
    hookConfig: PropTypes.object,
    variant: PropTypes.string,
    expandableRender: PropTypes.func,
    errorKey: PropTypes.string,
    checkboxRequired: PropTypes.bool,
    getSelectedCheckBoxes: PropTypes.func,
    separateRowOnClick: PropTypes.func,
    data: PropTypes.array,
    actions: PropTypes.array,
    onActionClick: PropTypes.func,
    refreshTable: PropTypes.bool,
    rowIdToBeSearched: PropTypes.string,
    apiParam: PropTypes.object,
    filterRequired: PropTypes.bool,
    validationSchema: PropTypes.object,
    initialValues: PropTypes.object,
    Form: PropTypes.oneOfType([PropTypes.elementType, PropTypes.node]),
    showApiSuccessMessage: PropTypes.shape({
        show: PropTypes.bool.isRequired,
        message: PropTypes.string
      }),
    showSkeleton: PropTypes.bool
};

const defaultProps = {
    checkboxRequired: false,
    getSelectedCheckBoxes: () => {},
    separateRowOnClick: () => {},
    data: [],
    actions: [],
    onActionClick: () => {},
    refreshTable: false,
    rowIdToBeSearched: '',
    apiParam: {},
    filterRequired: false,
    columns: [],
    validationSchema: {},
    initialValues: {},
    Form: FormDefault,
    hookConfig: { api: '' },
    showApiSuccessMessage: { show: true, message: 'Data Fetched Successfully ...' },
    showSkeleton: false
};

const MaterialTableListBase = (props) => {
    const {
        columns,
        hookConfig,
        variant,
        expandableRender,
        errorKey,
        getSelectedCheckBoxes,
        checkboxRequired,
        separateRowOnClick,
        data,
        actions,
        onActionClick,
        refreshTable,
        rowIdToBeSearched,
        apiParam,
        filterRequired,
        validationSchema,
        initialValues,
        Form,
        showApiSuccessMessage,
        showSkeleton,
        ...restProps
    } = props;

    const filterRef = useRef();

    const [selectedRows, setSelected] = useState([]);

    const useGet = createUseGet({
        api: hookConfig.api
    });

    // state, request, list, totalCount

    const { state, request } = useGet({
    
        onSuccess: () => {
            if (showApiSuccessMessage.show === true) {
              if (!showApiSuccessMessage.message) {
                toast.success(defaultProps.showApiSuccessMessage.message);
              } else {
                toast.success(showApiSuccessMessage.message);
              }
            }

          },
        onFail: (error) => {
            console.log(error, 'error');
          
        }
    });

    useEffect(() => {
        if (!hookConfig.api || showSkeleton) {
            return;
        }
        request(
            payload({
                pageSize: PAGE_SIZE,
                pageNumber: 0,
                ...apiParam,
                ...initialValues // this is to pass initial filter values to search api
            })
        );
    }, [request, refreshTable]);

    return (
        <>
            <MaterialTable.component
                allSelector={getSelectedCheckBoxes}
                data={!hookConfig.api ? data : _.get(state, 'data.list', [])}
                apiFixedParam={apiParam}
                onTableAction={request}
                loading={state.loading}
                totalCount={!hookConfig.api ? data.length : _.get(state, 'data.totalCount', 0)}
                validationSchema={validationSchema}
                initialValues={initialValues}
                filterRequired={filterRequired}
                showApiSuccessMessage={showApiSuccessMessage}
                formRender={({ handleSubmit, handleReset, formValues, setFieldValue, page, pageSize, setOpenFilterModal }) => (
                    <Form
                        handleSubmit={handleSubmit}
                        handleReset={handleReset}
                        formValues={formValues}
                        setFieldValue={setFieldValue}
                        page={page}
                        pageSize={pageSize}
                        setOpenFilterModal={setOpenFilterModal}
                    />
                )}
                columns={columns}
                variant={variant}
                expandableRender={expandableRender}
                keyExtractor={(item) => item[`${rowIdToBeSearched}`]}
                tableOptions={{
                    selection: checkboxRequired
                }}
                errorKey={errorKey}
                filterRef={filterRef}
                rowSelectionConfig={{ selected: selectedRows, setSelected }}
                seprateCheckBoxRow={checkboxRequired}
                seprateCheckBoxOnClick={(e, key, index, { row }) => {
                    // improve later  getSelectedCheckBoxesconsole expenseDetailId
                    // console.log('OPOPPOPPOPP', row, row[`${rowIdToBeSearched}`]);
                    if (!_.includes(selectedRows, row[`${rowIdToBeSearched}`])) {
                        setSelected([...selectedRows, row[`${rowIdToBeSearched}`]]);
                        getSelectedCheckBoxes([...selectedRows, row[`${rowIdToBeSearched}`]]);
                    } else {
                        setSelected(selectedRows.filter((item) => item !== row[`${rowIdToBeSearched}`]));
                        getSelectedCheckBoxes(selectedRows.filter((item) => item !== row[`${rowIdToBeSearched}`]));
                    }
                }}
                onRowPress={(e) => {
                    separateRowOnClick(e);
                }}
                seprateRowOnClick={(editId, editData) => {
                    separateRowOnClick(editId, editData);
                }}
                actions={actions}
                onActionClick={(rowInfo, actionType, index) => {
                    onActionClick(rowInfo, actionType, index);
                }}
                showSkeleton={showSkeleton}
                {...restProps}
            />
        </>
    );
};

MaterialTableListBase.propTypes = propTypes;
MaterialTableListBase.defaultProps = defaultProps;

export default MaterialTableListBase;
