// @ts-nocheck
import { fork, call, takeLatest, select, put } from "redux-saga/effects";
import { toast } from "react-toastify";
import { getCredentials } from "utils/helper";
// import {
//   SIDEMENU_REQUEST,
// } from "store/types";
import { SIDEMENU_REQUEST } from "store/types";
import Ujala from "services/api/ujala";
import { saveSideMenuList } from "./reducers";
interface ApiResponse {
  code: number;
  responseObject: any; 
}
interface ApiPayload {
  accessToken: string;
  [key: string]: any; 
}
function* getSideMenuList(apiData: ApiPayload) {
  try {
    const { accessToken }: { accessToken: string } = yield select(getCredentials);
    
    const apiPayload: ApiPayload = { ...apiData, accessToken };
    const response: ApiResponse = yield call(Ujala.getMenuList, apiPayload);
    if (response.code === 200) {
      yield put(saveSideMenuList(response.responseObject));
    } else {
      toast.error("Something went wrong");
    }
  } catch (error) {
    console.log("error", error);
  }
}
interface SideMenuRequestAction {
  type: typeof SIDEMENU_REQUEST;
  payload: ApiPayload;
}
function* getSideMenuListRequest(action: SideMenuRequestAction) {
  yield fork(getSideMenuList, action.payload);
}
export default function* saga() {
  yield takeLatest(SIDEMENU_REQUEST, getSideMenuListRequest);
}