const validateForm = ({ formValues, formSchema, errorCallBackFunction }) => {
    formSchema.validate(formValues, { abortEarly: false }).catch(err => {
    const formErrors = err.inner.reduce((acc, error) => {
      return {
        ...acc,
        [error.path]: error.message,
      };
    }, {});
    errorCallBackFunction(formErrors);
  });
};

export const formValidator = async ({
  validationSchema,
  formValuesToBeValidated,
  errorCallBackFunction,
}) => {
  const isFormValid = await validationSchema.isValid(formValuesToBeValidated, {
    abortEarly: true,
  });

  validateForm({
    formValues: formValuesToBeValidated,
    formSchema: validationSchema,
    errorCallBackFunction,
  });
  return isFormValid;
};
