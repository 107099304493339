import { useState, useCallback } from 'react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Palette from 'themes/palette';

import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import * as DOMPurify from 'dompurify';
import PropTypes from 'prop-types';
const propTypes = {
    onChange: PropTypes.func,
    name: PropTypes.string,
    value: PropTypes.object,
    isMandate: PropTypes.bool,
    labelName: PropTypes.bool
};
const defaultProps = {
    onChange: () => {},
    name: '',
    value: {},
    isMandate: false,
    labelName: ''
};
const editorStyle = {
    padding: '3px',
    height: '160px'
    // width: '100%'
};
const textStyle = {
    color: '#595959'
};
const toolbarOptions = {
    options: ['inline', 'list'],
    inline: {
        options: ['bold', 'italic', 'underline']
    },
    list: {
        options: ['ordered']
    }
};
const TicketEditor = (props) => {
    const { onChange, name, value, isMandate, labelName } = props;
    const { palette } = Palette();
    const boxStyled = {
        border: `1px solid ${palette.grey[300]}`,
        backgroundColor: '#F4F6F8',
        borderRadius: '6px'
    };
    const supText = {
        color: palette.error.main,
        fontSize: '14px',
        fontWeight: 'bold'
    };

    // const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const handleChangeEditor = (data) => {
        // setEditorState(data);
        onChange(data, name);
    };

    return (
        <>
            <Typography style={textStyle}>
                {labelName}
                {isMandate == true ? <sup style={supText}>*</sup> : null}
            </Typography>
            <Box style={boxStyled} component="form" sx={{ p: '2px 4px', textAlign: 'justify', mt: 1 }}>
                <Editor
                    editorState={value}
                    onEditorStateChange={handleChangeEditor}
                    wrapperClassName="editor-wrapper"
                    editorClassName="message-editor"
                    toolbarClassName="message-toolbar"
                    placeholder="Share your concern/issue in detail"
                    toolbar={toolbarOptions}
                    editorStyle={editorStyle}
                />
            </Box>
            {/* uncomment this to see html */}
            {/* <textarea disabled value={draftToHtml(convertToRaw(props.value.getCurrentContent()))} />
            <div>
                {
                    <div
                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(draftToHtml(convertToRaw(props.value.getCurrentContent()))) }}
                    />
                }
            </div> */}
        </>
    );
};
TicketEditor.propTypes = propTypes;
TicketEditor.defaultProps = defaultProps;
export default TicketEditor;
