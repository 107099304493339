
import React, { useState, useEffect } from 'react';
import { Select, MenuItem, FormControl, InputLabel, Typography, Tooltip, IconButton, Checkbox } from '@mui/material';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles } from '@mui/styles';
import Palette from '../../themes/palette';
import FormHelperText from '@mui/material/FormHelperText';



const propTypes = {
  labelName: PropTypes.string,
  placeHolder: PropTypes.string,
  showToolTip: PropTypes.bool,
  onChange: PropTypes.func,
  showError: PropTypes.string,
  name: PropTypes.string,
  isMandatory: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string,
    })
  ),
};

const defaultProps = {
  labelName: '',
  placeHolder: '',
  showToolTip: false,
  onChange: () => {},
  showError: '',
  name: '',
  isMandatory: false,
  disabled: false,
  options: [],
  value: ''
};

const MultipleSelect = (props) => {
  const {
    labelName,
    showToolTip,
    onChange,
    name,
    isMandatory,
    disabled,
    value,
    options,
    selectedValues,
    showError
  } = props;

  const [checkedData, setCheckedData] = useState([]);

  const { palette } = Palette();
  const textStyle = {
    color: palette.error.main,
    fontSize: '16px',
    fontWeight: 'bold',
    
  };
  const selectStyle = {
    width: '100%',
    paddingTop: 0,
    paddingBottom: 0
    
  };

  // To select all options 

  const markAllChecks = () => {
    let optionsWithCheck = [];
    
    checkedData.map((item)=>{
      const optionWithCheck = {...item};

      optionWithCheck.checked = checkedData[0].checked ? false : true;
      optionsWithCheck.push(optionWithCheck);
    });
    setCheckedData(Array.from(optionsWithCheck)); 

  // Create a new array without the first element (remove select all)

    const removeFirstElementOfArray = optionsWithCheck?.slice(1)
    onChange(removeFirstElementOfArray[0].checked ? removeFirstElementOfArray:[], name);

  }


  const onSelectChange = (event, value) => {
    let selectedValue = value.props.value;
    if (selectedValue === 'Select All') {
      markAllChecks();
      return;
    }
  
    let optionIndex = checkedData.findIndex((item) => item.value === selectedValue);
    let updatedOptions = Array.from(checkedData);
  
    let updatedOption = { ...updatedOptions[optionIndex] };
    updatedOption.checked = !updatedOption.checked;
    updatedOptions[optionIndex] = updatedOption;
  
    const selectAllOption = updatedOptions[0];
    selectAllOption.checked = false;
    updatedOptions[0] = selectAllOption;
  
    setCheckedData(updatedOptions);
  
     // Filter options and call onChange function with selected values

    const selectedValues = updatedOptions.filter((option)=>{
      if(option.checked === true && option.id !== 'SELECT_ALL'){
        return true;
      }
      return false;
    })
  onChange(selectedValues, name);
  };
  
  // show options or select all option(value) when component render

  const initialData = () => {
    const optionsWithCheck = [];
  
    const selectAllOption = {
      id: 'SELECT_ALL',
      value: 'Select All',
      checked: false,
    };
    optionsWithCheck.push(selectAllOption);
  
    options.map((item) => {
      const optionWithCheck = {
        ...item,
        checked: false,
      };
      optionsWithCheck.push(optionWithCheck);
    });
  
    setCheckedData(Array.from(optionsWithCheck));
  };
  
  useEffect(() => {
    initialData();
  }, []);


  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

   // case to select all value or show selected options in field

  function getFormattedSelectedOptions(){
    let selectedOptions = [];
    let selectedCount = 0;

    if(checkedData[0].id === 'SELECT_ALL' && checkedData[0].checked){
      return 'All options selected'
    }
    for(let i = 0; i<= checkedData.length; i++) {
      if(selectedCount>5){
        break;
      }
      if(checkedData[i]?.checked){
        selectedCount++;
        selectedOptions.push(checkedData[i].value);
      }   
    }
    return selectedOptions.join(', ');
  }

  const shouldShowError = () => {
    if (showError) return true;
    return false;
  };
  
  return (
    <>
  
         <InputLabel sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <Typography style={{ marginBottom: '5px', font: 'DM Sans', fontWeight: 400, fontSize: '14px', color: '#333438' }}>
                {labelName}   
            {isMandatory && <sup style={textStyle}>*</sup>}
            </Typography>
            </InputLabel>
        <Select
        style={selectStyle}
        SelectDisplayProps={{ style: { paddingTop: 7.5, paddingBottom: 7.5 } }}
          labelId="mutiple-select-label"
          multiple
          value={checkedData}
          onChange={onSelectChange}
          notched={true}
          renderValue={(checkedData) => getFormattedSelectedOptions(checkedData)}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
              },
            },
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
          }}
          disabled={disabled}
          error={shouldShowError()} 
        >
          {checkedData.map((option) => (

            <MenuItem key={option.id} value={option.value}>
              <Checkbox checked={option.checked} />
              <ListItemText primary={option.value} />
            </MenuItem>
          ))}
       
        </Select>
        {shouldShowError()  ? (
              <FormHelperText error>{showError}</FormHelperText>
            ) : null}
         {/* <FormHelperText style={{color:'red'}}>{shouldShowError() ? showError : ''} </FormHelperText> */}
    </>
  );
};

MultipleSelect.propTypes = propTypes;
MultipleSelect.defaultProps = defaultProps;

export default MultipleSelect;
