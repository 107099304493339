import { call, put, takeLatest } from "redux-saga/effects";
import { push } from "redux-first-history";
import { toast } from "react-toastify";
import {
  VERIFY_OTP,
  SEND_OTP,
  RESET_PASSWORD,
} from "store/types";
import { dispatch } from "store/index";
import {
  otpTrigger,
  verifyOtpTrigger,
  loginFail,
  setUserCredential,
  resetPasswordTrigger,
  saveJwtToken,
  pswdEditable
} from "./reducers";
import { ROUTES } from "routes/constant";

interface SendOtpData {
  userId: string;
  password: string;
  navigate: Function;
}

interface VerifyOtpData {
  userId: string;
  password: string;
  otp: string;
  navigate: Function
}

interface resetPasswordData {
  userId: string;
  password: string;
  confirmPassword: string;
  otp: string;
}

interface AuthResponse {
  code: number;
  message: string;
  responseObject: any;
}

interface resetPasswordResponse {
  code: number;
  message: string;
  responseObject: any;
}

interface Action<T> {
  type: string;
  payload: T;
  navigate?: Function;
}

export function* sendOtpFlow(data: SendOtpData) {
  try {
    const authResponseRaw: Response = yield fetch(
      process.env.REACT_APP_BASE_URL +
        "/login-service/login/loginOtpGeneration",
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const authResponse: AuthResponse = yield authResponseRaw.json();
    if (authResponse.code !== 200) {
      throw new Error(authResponse.message);
    } else {
      toast.success(authResponse.message);
      // Navigate fn should be called here
      data.navigate(ROUTES.VERIFY_OTP);
    }
  } catch (e: any) {
    toast.error(e.message || "Something went wrong !!");
    dispatch(loginFail(e.message));
  }
}

export function* resetPasswordFlow(data: resetPasswordData) {
  const {
    otp
  }= data
  let payload ={};
  if(otp===""){
     payload ={
      userId: data.userId,
      password: data.password,
      confirmPassword: data.confirmPassword,
    }
  }
  try {
    const resetPasswordResponseraw: Response = yield fetch(
      `${process.env.REACT_APP_BASE_URL}/login-service/login/resetPassword`,
      {
        method: "POST",
        body: JSON.stringify(data.otp===""? payload : data),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const resetPasswordResponse: resetPasswordResponse =
      yield resetPasswordResponseraw.json();
    if (resetPasswordResponse.code !== 200) {
      throw new Error(resetPasswordResponse.message);
    } else {
      toast.success(resetPasswordResponse.message);
      if(otp)
        dispatch(push(ROUTES.LOGIN));
    }
  } catch (e: any) {
    yield put(pswdEditable(true));
    toast.error(e.message || "Something went worng !!");
    
  }
}

export function* verifyOtpFlow(data: VerifyOtpData) {
  const formData = {
    userId: data.userId,
    password: data.password,
    otp: data.otp,
  };

  try {
    const authResponseRaw: Response = yield fetch(
      `${process.env.REACT_APP_BASE_URL}/login-service/login/loginOtpValidation`,
      {
        method: "POST",
        body: JSON.stringify(formData),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const authResponse: AuthResponse = yield authResponseRaw.json();
    if (authResponse.code !== 200) {
      toast.success(authResponse.message);

      throw new Error(authResponse.message);
    } else {
      dispatch(saveJwtToken(authResponse.responseObject.jwtToken));
      data.navigate(ROUTES.HOME);
    }
  } catch (e: any) {
    dispatch(loginFail(e.message));
    toast.error(e.message || "Something went wrong !!");
  }
}


function* sendOtpSaga({ payload, navigate }: Action<any>) {
  dispatch(setUserCredential(payload))
  try {
    yield put(otpTrigger(payload));


    yield call(sendOtpFlow, {
      ...payload,
      navigate,
    });
  } catch (error) {
    console.error(error);
  }
}

function* resetPasswordSaga({ payload }: Action<resetPasswordData>) {
  try {
    yield put(resetPasswordTrigger(payload));
    yield call(resetPasswordFlow, payload);
  } catch (error) {
  }
}

function* verifyOtpSaga({ payload, navigate }: Action<any>) {
  try {
    yield put(verifyOtpTrigger(payload));

    yield call(verifyOtpFlow, {
      ...payload,
      navigate,
    });
  } catch (error) {
  }
}

function* sendOtp(action: Action<SendOtpData>) {
  yield call(sendOtpSaga, action);
}

function* verifyOtp(action: Action<VerifyOtpData>) {
  yield call(verifyOtpSaga, action);
}

function* resetPassword(action: Action<resetPasswordData>) {
  yield call(resetPasswordSaga, action);
}

export default function* saga() {
  yield takeLatest(SEND_OTP, sendOtp);
  yield takeLatest(VERIFY_OTP, verifyOtp);
  yield takeLatest(RESET_PASSWORD, resetPassword);
}