import END_POINTS from "services/endPoints";
import {
  get as getAxios,
  DownloadFile as downloadFileService,
  post as postAxios,
  multipart,
} from "../apiHandler";

const endPoint = `${process.env.REACT_APP_BASE_URL}${END_POINTS.ujala}`;

const get = (url, { accessToken, data }) => {
  return getAxios(url, { accessToken, data, endPoint });
};
const post = (url, { accessToken, data, params }) => {
  console.log("sasasasasasa", url, { accessToken, data, params });
  return postAxios(url, { accessToken, data, endPoint, params });
};

const downloadFile = (url, { accessToken, data }, nativeProps) => {
  return downloadFileService(url, { accessToken, data, endPoint }, nativeProps);
};

const uploadFile = (url, { accessToken, data }) => {
  return multipart(url, { accessToken, data, endPoint });
};
export default class Ujala {
  static getMenuList({ accessToken, data }) {
    const getMenuListRequest = post("/menu/getMenuList", {
      accessToken,
      data,
    });
    Ujala.getMenuList.cancel = getMenuListRequest.cancel;
    return getMenuListRequest;
  }

  static getMembersByID({ accessToken, data }) {
    const getMembersByIDRequest = post("/member/getMembers", {
      accessToken,
      data: { memberId: data.memberId },
    });
    Ujala.getMembersByID.cancel = getMembersByIDRequest.cancel;
    return getMembersByIDRequest;
  }

  static memberToAdvisorDropDown({ accessToken, data }) {
    const memberToAdvisorDropDownRequest = post("/advisor/promoteTo", {
      accessToken,
      data: { memberId: data.memberId },
    });
    Ujala.memberToAdvisorDropDown.cancel = memberToAdvisorDropDownRequest.cancel;
    return memberToAdvisorDropDownRequest;
  }

  static getAddressDetails({ accessToken, data }) {
    const getAddressDetailsRequest = post("/common/getDetailsByPincode", {
      accessToken,
      data,
    });
    Ujala.getAddressDetails.cancel = getAddressDetailsRequest.cancel;
    return getAddressDetailsRequest;
  }

  static getBankDetails({ accessToken, data }) {
    const getBankDetailsRequest = post("/common/getBankDetailsByIFSCCode", {
      accessToken,
      data,
    });
    Ujala.getBankDetails.cancel = getBankDetailsRequest.cancel;
    return getBankDetailsRequest;
  }

  static getMemberDetailsByAadharNumber({ accessToken, data }) {
    const getMemberDetailsByAadharNumberRequest = post(
      "/common/getaadhaarDetailsByaadhaarNumber",
      {
        accessToken,
        data,
      }
    );
    Ujala.getMemberDetailsByAadharNumber.cancel =
      getMemberDetailsByAadharNumberRequest.cancel;
    return getMemberDetailsByAadharNumberRequest;
  }

  static getMemberList({ accessToken, data }) {
    const getMemberListRequest = post("/member/getMembers", {
      accessToken,
      data,
    });
    Ujala.getMemberList.cancel = getMemberListRequest.cancel;
    return getMemberListRequest;
  }

  static getMemberDetailsByID({ accessToken, data }) {
    const getMemberDetailsByIDRequest = post(
      "/member/getMemberMasterByMemberId",
      {
        accessToken,
        data,
      }
    );
    Ujala.getMemberDetailsByID.cancel = getMemberDetailsByIDRequest.cancel;
    return getMemberDetailsByIDRequest;
  }

  static registerMember({ accessToken, data }) {
    const registerMemberRequest = post("/member/registerMember", {
      accessToken,
      data,
    });
    Ujala.registerMember.cancel = registerMemberRequest.cancel;
    return registerMemberRequest;
  }

  static getMemberDetailsByMemberNO({ accessToken, data }) {
    const getMemberDetailsByMemberNORequest = post(
      "/member/getMemberDetails",
      {
        accessToken,
        data,
      }
    );
    Ujala.getMemberDetailsByMemberNO.cancel = getMemberDetailsByMemberNORequest.cancel;
    return getMemberDetailsByMemberNORequest;
  }

  static getMemberDetailsByApplicationNO({ accessToken, data }) {
    const getMemberDetailsByApplicationNoRequest = post(
      "/member/getMemberStgDetails",
      {
        accessToken,
        data,
      }
    );
    Ujala.getMemberDetailsByApplicationNO.cancel = getMemberDetailsByApplicationNoRequest.cancel;
    return getMemberDetailsByApplicationNoRequest;
  }

  static getPaymentData({ accessToken, data }) {
    const getPaymentDataRequest = post("/common/getPaymentData", {
      accessToken,
      data,
    });
    Ujala.getPaymentData.cancel = getPaymentDataRequest.cancel;
    return getPaymentDataRequest;
  }

  static submitPayment({ accessToken, data }) {
    const submitPaymentRequest = post("/member/submitPayment", {
      accessToken,
      data,
    });
    Ujala.submitPayment.cancel = submitPaymentRequest.cancel;
    return submitPaymentRequest;
  }

  // GET REQUIRED DOCUMENT DETAILS
  static getRequiredDocumentDetails({ accessToken, data }) {
    const getRequiredDocumentDetailsRequest = post(
      "/common/getRequiredDocumentDetails",
      {
        accessToken,
        data,
      }
    );
    Ujala.getRequiredDocumentDetails.cancel = getRequiredDocumentDetailsRequest.cancel;
    return getRequiredDocumentDetailsRequest;
  }

  // UPLOAD DOCUMENT
  static uploadDocument({ accessToken, data }) {
    const uploadDocumentRequest = post("/common/uploadDocument", {
      accessToken,
      data,
    });
    Ujala.uploadDocument.cancel = uploadDocumentRequest.cancel;
    return uploadDocumentRequest;
  }

  // GET DOCUMENT URL
  static getDocumentURL({ accessToken, data }) {
    const getDocumentURLRequest = post("/common/getDocumentURL", {
      accessToken,
      data,
    });
    Ujala.getDocumentURL.cancel = getDocumentURLRequest.cancel;
    return getDocumentURLRequest;
  }

  // Product Master -----------------------------------------

  // getProductTypes
  
  // static getProductTypes({accessToken, data}) {
  //   const getProductTypesRequest = productPost("/product/getProductTypes", {
  //     accessToken: auth,
  //     data,
  //   });
  //   Ujala.registerMember.cancel = getProductTypesRequest.cancel;
  //   return getProductTypesRequest;
  // }

  static getMembersList({ accessToken, data }) {
    const getMembersListRequest = post("/member/getMemberList", {
      accessToken,
      data,
    });
    Ujala.getMembersList.cancel = getMembersListRequest.cancel;
    return getMembersListRequest;
  }

  // static ProductForSpecifiClient({accessToken, data}) {
  //   const ProductForSpecifiClientRequest = productPost("/product/getSpecificClientsData", {
  //     accessToken: auth,
  //   });
  //   Ujala.submitProductMaster.cancel = ProductForSpecifiClientRequest.cancel;
  //   return ProductForSpecifiClientRequest;
  // }

  // static createOrEditProductMaster({accessToken, data}) {
  //   const createOrEditProductMasterRequest = productPost("/product/createOrEditProductMaster", {
  //     accessToken: auth,
  //     data,
  //   });
  //   Ujala.submitProductMaster.cancel = createOrEditProductMasterRequest.cancel;
  //   return createOrEditProductMasterRequest;
  // }

  static getMembersStgList({ accessToken, data }) {
    const getMembersListRequest = post("/member/getMemberStgList", {
      accessToken,
      data,
    });
    Ujala.getMembersStgList.cancel = getMembersListRequest.cancel;
    return getMembersListRequest;
  }

  // GOV STRUC MASTER

  static getMembersStgList({ accessToken, data }) {
    const getMembersListRequest = post("/member/getMemberStgList", {
      accessToken,
      data,
    });
    Ujala.getMembersStgList.cancel = getMembersListRequest.cancel;
    return getMembersListRequest;
  }
  // SAVING ACCOUNT

  static getProductCategoryTypeList({ accessToken, data }) {
    const getProductCategoryTypeListRequest = post("/product/getProductTypes", {
      accessToken,
      data,
    });
    Ujala.getProductCategoryTypeList.cancel = getProductCategoryTypeListRequest.cancel;
    return getProductCategoryTypeListRequest;
  }

  // static getProductNameList({ accessToken, data }) {
  //   const getProductNameListRequest = post(
  //     "investmentAccount/getProductNamesList",
  //     {
  //       accessToken,
  //       data,
  //     }
  //   );
  //   Ujala.getMembersList.cancel = getProductNameListRequest.cancel;
  //   return getProductNameListRequest;
  // }

  // // TODO:
  // static getSelectedProductDetails({ accessToken, data }) {
  //   const getSelectedProductDetailsRequest = post(
  //     "investmentAccount/getProductNamesList",
  //     {
  //       accessToken,
  //       data,
  //     }
  //   );
  //   Ujala.getSelectedProductDetailsRequest.cancel =
  //     getSelectedProductDetailsRequest.cancel;
  //   return getSelectedProductDetailsRequest;
  // }

  static approveRejectDocument({ accessToken, data }) {
    const approveRejectDocumentRequest = post(
      "/common/approveRejectDocument",
      {
        accessToken,
        data,
      }
    );
    Ujala.approveRejectDocument.cancel =
    approveRejectDocumentRequest.cancel;
    return approveRejectDocumentRequest;
  }

  static finaliseApplication({ accessToken, data }) {
    const finaliseApplicationRequest = post(
      "/member/finaliseApplication",
      {
        accessToken,
        data,
      }
    );
    Ujala.finaliseApplication.cancel = finaliseApplicationRequest.cancel;
    return finaliseApplicationRequest;
  }

  static getPaymentDetails({ accessToken, data }) {
    const getPaymentDetailsRequest = post(
      "/common/getPaymentDetails",
      {
        accessToken,
        data,
      }
    );
    Ujala.getPaymentDetails.cancel = getPaymentDetailsRequest.cancel;
    return getPaymentDetailsRequest;
  }

}
