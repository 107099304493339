import React, { useState } from "react";
import CustomBox from "components/Box/CustomBox";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";

interface ExpandableBoxProps {
  children: React.ReactNode;
  label: React.ReactNode | string;
  style?: React.CSSProperties;
  className?: string;
}

const FiltersContainer: React.FC<ExpandableBoxProps> = ({
  children,
  label,
  style,
  className,
}) => {
  const [expanded, setExpanded] = useState(true);

  const handleToggle = () => {
    setExpanded(prevExpanded => !prevExpanded);
  };

  return (
    <CustomBox
      style={style}
      className={className}
      label={label}
    >
      {/* {label && <Box>{label}</Box>} */}
      <Box
        sx={{
          position: "absolute",
          top: 6,
          right: 8,
          display: "flex",
          flexDirection: "column",
          zIndex: 1,
        }}
      >
        <Button
          onClick={handleToggle}
          size="small"
          variant="text"
          // sx={{ minWidth: 0 }}
        >
          {expanded ? 
          <RemoveIcon sx={{ fontSize: "1.2rem", color:'#A9A9A9' }} />
           :
            <AddIcon sx={{ fontSize: "1.2rem", color: '#1F7A8C' }} />
            }
        </Button>
      </Box>
      
    
      <Box
        sx={{
          transition: "opacity 0.3s ease",
          opacity: expanded ? 1 : 0,
          height: expanded ? "auto" : 0,
          overflow: "hidden",
        }}
      >
        {children}
      </Box>
    </CustomBox>
  );
};

export default FiltersContainer;
