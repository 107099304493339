export const statesOfIndia = [
  { value: "andhra-pradesh", label: "Andhra Pradesh" },
  { value: "arunachal-pradesh", label: "Arunachal Pradesh" },
  { value: "assam", label: "Assam" },
  { value: "bihar", label: "Bihar" },
  { value: "chhattisgarh", label: "Chhattisgarh" },
  { value: "goa", label: "Goa" },
  { value: "gujarat", label: "Gujarat" },
  { value: "haryana", label: "Haryana" },
  { value: "himachal-pradesh", label: "Himachal Pradesh" },
  { value: "jharkhand", label: "Jharkhand" },
  { value: "karnataka", label: "Karnataka" },
  { value: "kerala", label: "Kerala" },
  { value: "madhya-pradesh", label: "Madhya Pradesh" },
  { value: "maharashtra", label: "Maharashtra" },
  { value: "manipur", label: "Manipur" },
  { value: "meghalaya", label: "Meghalaya" },
  { value: "mizoram", label: "Mizoram" },
  { value: "nagaland", label: "Nagaland" },
  { value: "odisha", label: "Odisha" },
  { value: "punjab", label: "Punjab" },
  { value: "rajasthan", label: "Rajasthan" },
  { value: "sikkim", label: "Sikkim" },
  { value: "tamil-nadu", label: "Tamil Nadu" },
  { value: "telangana", label: "Telangana" },
  { value: "tripura", label: "Tripura" },
  { value: "uttar-pradesh", label: "Uttar Pradesh" },
  { value: "uttarakhand", label: "Uttarakhand" },
  { value: "west-bengal", label: "West Bengal" },
  { value: "andaman-nicobar-islands", label: "Andaman and Nicobar Islands" },
  { value: "chandigarh", label: "Chandigarh" },
  {
    value: "dadra-nagar-haveli-daman-diu",
    label: "Dadra and Nagar Haveli and Daman and Diu",
  },
  { value: "delhi", label: "Delhi" },
  { value: "lakshadweep", label: "Lakshadweep" },
  { value: "puducherry", label: "Puducherry" },
  { value: "ladakh", label: "Ladakh" },
  { value: "jammu-kashmir", label: "Jammu and Kashmir" },
];

export const paymentMode = [
  {
    value: "UPI",
    label: "UPI",
  },
];

export const SALUTATIONS = [
  { value: "Mr.", label: "Mr." },
  { value: "Ms.", label: "Ms." },
  { value: "Mrs.", label: "Mrs." },
  { value: "Dr.", label: "Dr." },
  { value: "Mx.", label: "Mx." },
  { value: "Prof.", label: "Prof." },
];

export const GENDERS = [
  { value: "M", label: "Male" },
  { value: "F", label: "Female" },
  { value: "O", label: "Other" },
];

export const RELIGIONS = [
  { value: "Hindu", label: "Hindu" },
  { value: "Muslim", label: "Muslim" },
  { value: "Christian", label: "Christian" },
];

export const BLOOD_GROUPS = [
  { value: "A+", label: "A+" },
  { value: "A-", label: "A-" },
  { value: "B+", label: "B+" },
  { value: "B-", label: "B-" },
  { value: "AB+", label: "AB+" },
  { value: "AB-", label: "AB-" },
  { value: "O+", label: "O+" },
  { value: "O-", label: "O-" },
];

export const MARITAL_STATUS = [
  { value: "UnMarried", label: "UnMarried" },
  { value: "Married", label: "Married" },
  { value: "Divorced", label: "Divorced" },
  { value: "Separated", label: "Separated" },
];

export const NO_OF_CHILDRENS = [
  { value: "0", label: "0" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
];

export const NO_OF_NOMINEES = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
];

export const MEMBERS_DATA = [
  {
    id: 1,
    value: "Member1",
  },
  {
    id: 2,
    value: "Member2",
  },
  {
    id: 3,
    value: "Member3",
  },
  {
    id: 4,
    value: "Member4",
  },
];

export const SPACING = 1.5;

export const QUALIFICATIONS = [
  {
    label: "10th",
    value: "10th",
  },
  {
    label: "12th",
    value: "12th",
  },
  {
    label: "Graduation",
    value: "Graduation",
  },
  {
    label: "Post Graduation",
    value: "Post Graduation",
  },
];

export const Occupation = [
  {
    label: "Private",
    value: "Private",
  },
  {
    label: "Government",
    value: "Government",
  },
  {
    label: "Others",
    value: "Others",
  },
];

export const PAYMENT_TYPES = [
  {
    label: "CASH",
    value: "CASH",
  },
  // {
  //   label: "UPI",
  //   value: "UPI",
  // },
  // {
  //   label: "Cheque",
  //   value: "Cheque",
  // },
];
