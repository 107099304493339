
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List, ListItemIcon, ListItemText, Collapse, Box, Drawer } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import { makeStyles } from '@mui/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import DrawerHeader from './DrawerHeader';
import { saveSideMenuList, setDrawerItems, closeLargeTemporaryDrawer } from '../reducers';
import { MenuItemss } from '../constant';
import { SIDEMENU_REQUEST } from 'store/types';
// import { MenuItem } from '../types'; 
import { PeopleAltOutlined as PeopleAltOutlinedIcon, 
  DashboardCustomizeOutlined as DashboardCustomizeOutlinedIcon, 
  HomeOutlined as HomeOutlinedIcon, 
  AccountBalanceWallet as AccountBalanceWalletIcon } from '@mui/icons-material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PersonIcon from '@mui/icons-material/Person';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import CreditScoreRoundedIcon from '@mui/icons-material/CreditScoreRounded';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import {icons, IconName}  from './iconConstant';

// type IconName = 'PeopleAltOutlinedIcon' |
//  'DashboardCustomizeOutlinedIcon' | 
//  'HomeOutlinedIcon' | 
//  'AccountBalanceWalletIcon';

//  const icons: Record<IconName, React.ComponentType<any>> = {
// 'PeopleAltOutlinedIcon': PeopleAltOutlinedIcon,
// 'DashboardCustomizeOutlinedIcon': DashboardCustomizeOutlinedIcon,
// 'HomeOutlinedIcon': HomeOutlinedIcon,
// 'AccountBalanceWalletIcon': AccountBalanceWalletIcon,
// };



interface MenuItem {
  label: string;
  list?: MenuItem[];
  url?: string | null;
  icon?: IconName;
  id?: number;
}

interface SideMenuProps {
  isMiniDrawerOpen: boolean;
}

const useStyles = makeStyles(() => ({
  scrollbar: {
    // height: '95vh',
    '& .ps__rail-x, & .ps__rail-y': {
      opacity: 0,
      display: 'none',
    },
    '& .ps__thumb-x, & .ps__thumb-y': {
      display: 'none',
    },
  },
  sectionBox: {
    border: '1px solid #E3E3E3',
    borderRadius: '8px',
    // padding: '4px',
    margin: '4px 0',
    backgroundColor: '#fff',
    // boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  },
}));

const SideMenu: React.FC<SideMenuProps> = ({ isMiniDrawerOpen }) => {
  const drawer = useSelector((state: any) => state.drawer);
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();

useEffect(() => {
  dispatch({
    type: SIDEMENU_REQUEST
  })
},[])

  useEffect(() => {
    dispatch(saveSideMenuList(MenuItemss));
  }, [dispatch]);


const handleToggle = (item: any, level: number) => {

  const Id = item.id;

  dispatch(setDrawerItems(Id));

  if (level === 2) {
    if (drawer.isDrawerTemporary) {
      dispatch(closeLargeTemporaryDrawer());
    }
    navigate(item.url);
  }
  };

  const paddingLeft = (level: number) => {
    switch (level) {
      case 0:
        return 0;
      case 1:
        return 4;
      case 2:
        return 6;
      default:
        return 0;
    }
  };

  const getTextColor = (level : number, isActiveItem: boolean) => {


    if (isActiveItem) {
      return level === 0 || level === 1 ? '#022B3A' : '#7C8081'; 
    } else {
      return level === 0 || level === 1 ? '#7C8081' : '#7C8081'; 
    }
  };
  const renderMenuItems = (items: MenuItem[], level = 0) => {
    return items.map((item, idx) => {

      const Icons = item.icon ? icons[item.icon] : null;

      const isOpen = item.id !== undefined && drawer.openMenus ? drawer.openMenus[item.id] : false;
      const isSection = item.list && item.list.length > 0;
      const isMainSection = level === 0;
      const isActiveItem = drawer?.activeMenuItem === item.id;
      const paddingBdaseOnLevel = paddingLeft(level);
      const textColor = getTextColor(level, isActiveItem);
  
      const menuItemContent = (
        <Box
          sx={{
        display: 'flex',
        paddingLeft: paddingBdaseOnLevel, // Padding used based on the level
        paddingRight: '2px',
        paddingTop: '2px',
        paddingBottom: '2px',
        backgroundColor: level > 1 && isActiveItem  ? '#F6F7FA' : 'transparent',
        position: 'relative',
        color: level > 1 && isActiveItem ? '#1F7A8C' : '#7C8081',
        fontFamily: 'Poppins',
        fontWeight: 500,
        fontSize: '10px',
        lineHeight: '20px',
        textColor: textColor,
        
        //  paddingLeft: `${24 + 16 * level}px`, 
        
        //  borderLeft: level > 0 ? '2px solid #ccc' : 'none', 
        //  marginLeft:  `${level * 16}px`,
      }}
          onClick={() => handleToggle(item, level)}
        >
        
          {Icons && (
            <ListItemIcon sx={{ padding: ' 4px'}}>
              <Icons style={{fontSize: '18px'}}/>
            </ListItemIcon>
          )}
          {/* {!isMiniDrawerOpen && <ListItemText 
          primary={item.label} 
          />} */}
              <Box
    sx={{
      flex: 1, 
      // paddingLeft: item.Icon && '0px',
    }}
  >
    {!isMiniDrawerOpen && (
      <ListItemText
        primary={item.label}
      />
    )}
  </Box>

          {item.list && (isOpen ? <ExpandLess /> : <ExpandMore />)}
        </Box>
      );


      return (
          <React.Fragment key={item.id}>
            {isMainSection ? (
              <Box className={classes.sectionBox}>
                {menuItemContent}
                {isSection && item.list && (
                  <Collapse in={isOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {renderMenuItems(item.list, level + 1)}
                    </List>
                  </Collapse>
                )}
              </Box>
            ) : (
              <>
              {menuItemContent}
              {isSection && item.list && (
                <Collapse in={isOpen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {renderMenuItems(item.list, level + 1)}
                  </List>
                </Collapse>
              )}
            </>
          )}
        </React.Fragment>
    );
    });
  };
  

  return (
  <>
 
      <DrawerHeader isMiniDrawerOpen={isMiniDrawerOpen} />
      <PerfectScrollbar style={{ height: '90vh'}} >
      <Box sx={{ width: '100%', overflow: 'hidden', padding: '4px' }}>
    <List>
      {/* {renderMenuItems(MenuItemss)} */}
      {renderMenuItems(drawer?.sideMenuList)}
    </List>
  </Box>
</PerfectScrollbar>
</>
  );
};

export default SideMenu;