import React, { useEffect } from "react";
import { Box, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { useDispatch, useSelector } from "react-redux";
import { GURANTOR, WITNESS } from "store/types";
import { bankAndNomineeDetailsValidationSchema } from "./addMemberSchemaValidation";
import Nominee from "./components/Nominee";
import ReferalComponent from "./components/Referal";
import WitnessGurantorComponent from "./components/WitnessGurantor";
import TextFieldBase from "../../components/TextField/index";
import CustomBox from "../../components/Box/CustomBox";

const useStyles = makeStyles({
  minorNomineeTextLabel: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "italic",
    color: "#022B3A",
  },
  nomineeTextLabel: {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "15px",
    textAlign: "left",
    color: "#1F7A8C",
  },
});

const BankAndNominee = ({ errors }: any) => {
  const [age, setAge] = React.useState<any>("");
  const classes = useStyles();
  const dispatch = useDispatch();
  const addNewMember = useSelector((state: any) => state.addNewMember);
  return (
    <>
      <Stack spacing={2}>
        <ReferalComponent />
        <Nominee />
        <WitnessGurantorComponent type={WITNESS} />
        {/* <WitnessGurantorComponent type={GURANTOR} /> */}
        {addNewMember?.applicationId && (
          <CustomBox label="Application No.">
            <div style={{ maxWidth: "300px" }}>
              <TextFieldBase
                disabled
                name="applicationId"
                placeHolder={"Application ID"}
                labelName={"Application ID"}
                value={addNewMember?.applicationId}
              />
            </div>
          </CustomBox>
        )}

        <Box
          sx={{
            height: "20px",
          }}
        ></Box>
      </Stack>
      <Box mt={3}></Box>
    </>
  );
};

export default BankAndNominee;
