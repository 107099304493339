import React, { useEffect } from "react";
import SingleFileUploader from "../../../../components/SingleFileUploader";
import { useDispatch, useSelector } from "react-redux";
import { CHANGE_DOC_STATUS, GET_DOCUMENTS_DETAILS, UPLOAD_DOCUMENT, UPLOAD_DOCUMENT_URL } from "store/types";
import Stack from "@mui/material/Stack";
import CustomBox from "../../../../components/Box/CustomBox";
import { Box } from "@mui/material";

function Documents() {
  const dispatch = useDispatch();
  const addNewMember = useSelector((state: any) => state.addNewMember);

  useEffect(() => {
    dispatch({ type: GET_DOCUMENTS_DETAILS });
  }, []);

  const handleFileChange = (event: any, docType: string) => {
    const formData = new FormData();
    const selectedFile = event[0];

    formData.append("file", selectedFile);
    formData.append("documentType", docType);
    formData.append("applicationId", addNewMember.applicationId);
    formData.append("memberId", "");
    dispatch({ type: UPLOAD_DOCUMENT, payload: formData });
  };

  const getDocumentURL = (id: number) => {
    dispatch({
      type: UPLOAD_DOCUMENT_URL,
      payload: { documentId: id },
    });
  }

  const onStatusChange = (status: string, docId: number) => {
    dispatch({
      type: CHANGE_DOC_STATUS,
      payload: { documentId: docId, status },
    });

  }

  return (
    <Box p={3} pb={5}>
      <Stack spacing={2}>
        {addNewMember?.document?.requiredDocuments?.map(
          (doc: any) => (
            <CustomBox
              style={{
                height: "70px",
                display: "flex",
                alignItems: "center",
              }}
              label={doc?.documentLabel}
            >
              <SingleFileUploader
                name={doc?.documentType}
                value={doc?.documentURL}
                onChange={(file: any, name: string) =>
                  handleFileChange(file, name)
                }
                docId={doc?.documentId}
                docName={doc?.documentName}
                type="button"
                accept=".pdf"
                onApproveClick={(docId: number)=>onStatusChange('a', docId)}
                onRejectClick={(docId: number)=>onStatusChange('r', docId)}
                isVerified={doc?.isVerified}
                getDocUrl={()=> getDocumentURL(doc.documentId)}
              />
            </CustomBox>
          )
        )}
      </Stack>
      {addNewMember?.document?.requiredDocuments?.length === 0 ? (
        <CustomBox>Loading...</CustomBox>
      ) : null}
    </Box>
  );
}
export default Documents;
