import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Grid from "components/Grid";
import Checkbox from "components/Checkbox";
import FormControlLabel from "components/FormControlLabel";
import { addressCopy, setFormData } from "containers/members/reducers";

interface AddressCheckBoxProps {
  type: string;
  label: string;
  copyFrom: string;
  copyTo: string;
  name: string;
}
//props validation is missing
const AddressCheckBox = ({
  name,
  type,
  label,
  copyFrom,
  copyTo,
}: AddressCheckBoxProps) => {
  const dispatch = useDispatch();
  const addNewMember = useSelector((state: any) => state.addNewMember);

  const currentAddress = {
    address: "",
    pincode: "",
    city: "",
    country: "India",
    state: "",
  };
  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setFormData({ name: event.target.name, value: event.target.checked })
    );
    if (event.target.checked) {
      dispatch(addressCopy({ copyFrom, copyTo }));
    } else {
      dispatch(addressCopy({ currentAddress, copyTo }));
    }
  };

  return (
    <>
      <Grid item xs={12} sm={4} md={3} lg={12}>
        <FormControlLabel
          sx={{ marginBottom: "6px" }}
          control={
            <Checkbox
              disableRipple
              name={type}
              onChange={handleCheck}
              checked={addNewMember?.current_same_as_aadhar_address}
              //checked={addNewMember?.[type]}
              inputProps={{ "aria-label": "controlled" }}
              size="small"
            />
          }
          label={label}
        />
      </Grid>
    </>
  );
};

export default AddressCheckBox;
