import React from "react";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

type Message = {
  message: string;
};

const useStyles = makeStyles({
  errorTextStyle: {
    fontSize: "11px",
    color: "red",
  },
});

function ShowErrorMessage({ message }: Message) {
  const classes = useStyles();
  return (
    <div>
      <Typography className={classes.errorTextStyle}>{message}</Typography>
    </div>
  );
}

export default ShowErrorMessage;
