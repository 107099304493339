import React, { useCallback, useEffect } from "react";
import { Divider, Grid, Typography } from "@mui/material";
import TextFieldBase from "components/TextField";
import { makeStyles } from "@mui/styles";
import {
  updateNomineeDetail,
  setNomineeDetails,
  setNoOfNominees,
  clearErrors,
  setTotalNomineePercentage,
} from "containers/members/reducers";
import { useDispatch, useSelector } from "react-redux";
import Select from "components/Select";
import { NO_OF_NOMINEES, SPACING } from "containers/members/constant";
import { calculateAge } from "../../../../utils/calculateAge";
import CustomBox from "components/Box/CustomBox";
import ShowErrorMessage from "containers/members/showErrorMessage";
import InputDatePicker from "components/DatePicker";
const useStyles = makeStyles({
  minorNomineeTextLabel: {
    fontSize: "14px",
    fontWeight: 400,
    fontStyle: "italic",
    color: "#022B3A",
  },
  nomineeTextLabel: {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "15px",
    textAlign: "left",
    color: "#1F7A8C",
  },
});

const BankAndNominee = ({ errors }: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const addNewMember = useSelector((state: any) => state.addNewMember);

  const handleNomineeInputChange = (
    value: string,
    name: any,
    index?: any,
    thirdParam?: string
  ) => {
    if (!name) return;
    if (name === "pincode" && value?.length === 6) {
      dispatch({
        type: "ADDRESS_BY_PINCODE",
        payload: {
          pincode: value,
          addType: "NOMINEE_DETAILS",
          index: index,
          thirdParam: thirdParam,
        },
      });
    }
    if (name === "pincode" && value?.length > 6) return;
    if (name === "contactNo" && value?.length > 10) return;

    if (name === "percentage" && parseInt(value) > 100) return;
    dispatch(setNomineeDetails({ name, value, index, thirdParam }));
    dispatch(clearErrors({ name: `nominee[${index}].${name}` }));

    if (thirdParam === "guardian") {
      dispatch(clearErrors({ name: `nominee[${index}][guardian]?.${name}` }));
    }
  };

  const selectNoOfNomineeChange = (value: any) => {
    dispatch(updateNomineeDetail({ nomineeCount: value.value }));
    dispatch(setNoOfNominees(value?.value));
  };

  useEffect(() => {
    if (addNewMember?.totalPercentage === 100) {
      dispatch(clearErrors({ name: "totalPercentage" }));
    }
    dispatch(setTotalNomineePercentage({}));
  }, [addNewMember?.nominee]);

  return (
    <CustomBox style={{ marginBottom: "12px" }} label={"Nominee Detail"}>
      <Grid container spacing={SPACING} sx={{ marginBottom: "32px" }}>
        <Grid item xs={12} sm={6} md={6} lg={2}>
          <Select
            name="noOfNominees"
            multi={false}
            labelName={"No. of Nominee"}
            data={NO_OF_NOMINEES}
            onChangeSelect={selectNoOfNomineeChange}
            value={addNewMember?.noOfNominees || "1"}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={2}>
          <TextFieldBase
            disabled
            type="number"
            name="totalPercentage"
            placeHolder={"Total Percentage"}
            labelName={"Total Percentage(%)"}
            onChange={handleNomineeInputChange}
            value={addNewMember.totalPercentage}
          />
          <ShowErrorMessage
            message={addNewMember.formErrors?.["totalPercentage"]}
          />
        </Grid>
      </Grid>
      {addNewMember?.nominee?.map((nomineeMember: any, index: any) => (
        <React.Fragment>
          <Grid container spacing={SPACING} style={{ marginBottom: "16px" }}>
            {/* <Grid item xs={12} sm={4} md={3} lg={1}>
              <Typography className={classes.nomineeTextLabel}>
                Nominee {index + 1}{" "}
              </Typography>
            </Grid> */}
            <Grid item xs={12} sm={4} md={3} lg={4}>
              <TextFieldBase
                name="name"
                placeHolder={"Full Name"}
                labelName={"Full Name"}
                onChange={(value, name) =>
                  handleNomineeInputChange(value, name, index)
                }
                value={addNewMember.nominee[index].name}
                showError={addNewMember.formErrors?.hasOwnProperty(
                  `nominee[${index}].name`
                )}
              />

              <ShowErrorMessage
                message={addNewMember.formErrors?.[`nominee[${index}].name`]}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2}>
              <TextFieldBase
                type="number"
                name="percentage"
                // placeHolder={"60"}
                labelName={"Percentage(%)"}
                onChange={(value, name) =>
                  handleNomineeInputChange(value, name, index)
                }
                value={addNewMember.nominee[index].percentage}
                showError={addNewMember.formErrors?.hasOwnProperty(
                  `nominee[${index}].percentage`
                )}
              />
              <ShowErrorMessage
                message={
                  addNewMember.formErrors?.[`nominee[${index}].percentage`]
                }
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2}>
              <TextFieldBase
                name="relation"
                placeHolder={"Relationship"}
                labelName={"Relationship"}
                onChange={(value, name) =>
                  handleNomineeInputChange(value, name, index)
                }
                value={addNewMember.nominee[index].relation}
                showError={addNewMember.formErrors?.hasOwnProperty(
                  `nominee[${index}].relation`
                )}
              />
              <ShowErrorMessage
                message={
                  addNewMember.formErrors?.[`nominee[${index}].relation`]
                }
              />
            </Grid>

            <Grid item xs={12} sm={4} md={3} lg={2}>
              {/* <InputDatePicker
                disableFuture={true}
                name="dob"
                placeHolder={"DOB"}
                onChange={(value, name) => {
                  handleNomineeInputChange(value, "dob", index);
                }}
                value={addNewMember.nominee[index].dob}
              />
              <ShowErrorMessage
                message={addNewMember.formErrors?.[`nominee[${index}].dob`]}
              /> */}
              {/* <TextFieldBase
                type="date"
                name="dob"
                placeHolder={"DOB"}
                labelName={"DOB"}
                onChange={(value, name) =>
                  handleNomineeInputChange(value, name, index)
                }
                value={addNewMember.nominee[index].dob}
                showError={addNewMember.formErrors?.hasOwnProperty(
                  `nominee[${index}].dob`
                )}
              /> */}
              <InputDatePicker
                labelName="Date Of Birth"
                disableFuture={true}
                name="dob"
                value={addNewMember.nominee[index].dob}
                placeHolder={"DOB"}
                onChange={(val) =>{
                  const { value, name } = val;
                  console.log('************************&^&^&^&*^&^&^', {10:addNewMember.nominee[index].dob, 11: {value, name} })
                  handleNomineeInputChange(value, name, index)
                }}
              />
              <ShowErrorMessage
                message={addNewMember.formErrors?.[`nominee[${index}].dob`]}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={3} lg={2}>
              <TextFieldBase
                type="number"
                name="nominee_age"
                placeHolder={"e.g 35"}
                labelName={"Age(Y)"}
                value={calculateAge(addNewMember.nominee[index].dob).toString()}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={4}>
            <TextFieldBase
                name="address"
                placeHolder={"Current Address"}
                labelName={"Current Address"}
                value={addNewMember.nominee[index].address}
                onChange={(value, name) =>
                  handleNomineeInputChange(value, name, index)
                }
                showError={addNewMember.formErrors?.hasOwnProperty(
                  `nominee[${index}].address`
                )}
              />
              <ShowErrorMessage
                message={addNewMember.formErrors?.[`nominee[${index}].address`]}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2}>
              <TextFieldBase
                type="number"
                name="pincode"
                placeHolder={"Pincode"}
                labelName={"Pincode"}
                onChange={(value, name) =>
                  handleNomineeInputChange(value, name, index)
                }
                value={addNewMember.nominee[index].pincode}
                showError={addNewMember.formErrors?.hasOwnProperty(
                  `nominee[${index}].pincode`
                )}
              />
              <ShowErrorMessage
                message={addNewMember.formErrors?.[`nominee[${index}].pincode`]}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={3} lg={2}>
              <TextFieldBase
                name="city"
                placeHolder={"City"}
                labelName={"City"}
                onChange={(value, name) =>
                  handleNomineeInputChange(value, name, index)
                }
                value={addNewMember.nominee[index].city}
                showError={addNewMember.formErrors?.hasOwnProperty(
                  `nominee[${index}].city`
                )}
              />
              <ShowErrorMessage
                message={addNewMember.formErrors?.[`nominee[${index}].city`]}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={3} lg={2}>
              <TextFieldBase
                name="state"
                placeHolder={"State"}
                labelName={"State"}
                onChange={(value, name) =>
                  handleNomineeInputChange(value, name, index)
                }
                value={addNewMember.nominee[index].state}
                showError={addNewMember.formErrors?.hasOwnProperty(
                  `nominee[${index}].state`
                )}
              />
              <ShowErrorMessage
                message={addNewMember.formErrors?.[`nominee[${index}].state`]}
              />
            </Grid>

            {/* <Grid item xs={12} sm={4} md={3} lg={6}>
              <TextFieldBase
                name="address"
                placeHolder={"Current Address"}
                labelName={"Current Address"}
                value={addNewMember.nominee[index].address}
                onChange={(value, name) =>
                  handleNomineeInputChange(value, name, index)
                }
                showError={addNewMember.formErrors?.hasOwnProperty(
                  `nominee[${index}].address`
                )}
              />
              <ShowErrorMessage
                message={addNewMember.formErrors?.[`nominee[${index}].address`]}
              />
            </Grid> */}

            {calculateAge(addNewMember.nominee[index].dob) < 18 ? (
              <>
                <Grid item xs={12}>
                  <Typography className={classes.minorNomineeTextLabel}>
                    Nominee is minor i.e. less than 18, Please fill below
                    details.
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={4} md={3} lg={2}>
                  <TextFieldBase
                    name="relationShip"
                    placeHolder={"RelationShip"}
                    labelName={"RelationShip"}
                    onChange={(value, name) =>
                      handleNomineeInputChange(value, name, index, "guardian")
                    }
                    value={addNewMember.nominee[index]?.guardian?.relationShip}
                    showError={errors && errors.hasOwnProperty("guardianName")}
                  />
                  <ShowErrorMessage
                    message={
                      addNewMember?.formErrors
                        ? addNewMember?.formErrors[
                            `nominee[0].guardian.relationShip`
                          ]
                        : ""
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={4} md={3} lg={2}>
                  <TextFieldBase
                    name="name"
                    placeHolder={"Guardian Name"}
                    labelName={"Guardian Name"}
                    onChange={(value, name) =>
                      handleNomineeInputChange(value, name, index, "guardian")
                    }
                    value={addNewMember.nominee[index]?.guardian?.name}
                    showError={errors && errors.hasOwnProperty("guardianName")}
                  />
                  <ShowErrorMessage
                    message={
                      addNewMember.formErrors?.[
                        `nominee[${index}].guardian?.name`
                      ]
                    }
                  />
                  <ShowErrorMessage
                    message={
                      addNewMember?.formErrors
                        ? addNewMember?.formErrors[`nominee[0].guardian.name`]
                        : ""
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={4} md={3} lg={2}>
                  <TextFieldBase
                    type="number"
                    name="contactNo"
                    placeHolder={"Contact No"}
                    labelName={"Contact No"}
                    onChange={(value, name) =>
                      handleNomineeInputChange(value, name, index, "guardian")
                    }
                    value={addNewMember.nominee[index]?.guardian?.contactNo}
                    showError={errors && errors.hasOwnProperty("guardianName")}
                  />
                  <ShowErrorMessage
                    message={
                      addNewMember?.formErrors
                        ? addNewMember?.formErrors[
                            `nominee[0].guardian.contactNo`
                          ]
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2}>
                  <TextFieldBase
                    name="dob"
                    type="date"
                    placeHolder={"DOB"}
                    labelName={"DOB"}
                    onChange={(value, name) =>
                      handleNomineeInputChange(value, name, index, "guardian")
                    }
                    value={addNewMember.nominee[index]?.guardian?.dob}
                  />
                  <ShowErrorMessage
                    message={
                      addNewMember?.formErrors
                        ? addNewMember?.formErrors[`nominee[0].guardian.dob`]
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2}>
                  <TextFieldBase
                    type="number"
                    name="pincode"
                    placeHolder={"Pincode"}
                    labelName={"Pincode"}
                    onChange={(value, name) =>
                      handleNomineeInputChange(value, name, index, "guardian")
                    }
                    value={addNewMember.nominee[index]?.guardian?.pincode}
                  />
                  <ShowErrorMessage
                    message={
                      addNewMember?.formErrors
                        ? addNewMember?.formErrors[
                            `nominee[0].guardian.pincode`
                          ]
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={2}>
                  <TextFieldBase
                    name="state"
                    placeHolder={"State"}
                    labelName={"State"}
                    onChange={(value, name) =>
                      handleNomineeInputChange(value, name, index, "guardian")
                    }
                    value={addNewMember.nominee[index]?.guardian?.state}
                  />
                  <ShowErrorMessage
                    message={
                      addNewMember?.formErrors
                        ? addNewMember?.formErrors[`nominee[0].guardian.state`]
                        : ""
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={4} md={3} lg={2}>
                  <TextFieldBase
                    name="city"
                    placeHolder={"City"}
                    labelName={"City"}
                    value={addNewMember.nominee[index]?.guardian?.city}
                    onChange={(value, name) =>
                      handleNomineeInputChange(value, name, index, "guardian")
                    }
                  />
                  <ShowErrorMessage
                    message={
                      addNewMember?.formErrors
                        ? addNewMember?.formErrors[`nominee[0].guardian.city`]
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3} lg={4}>
                  <TextFieldBase
                    name="address"
                    placeHolder={"Current Address"}
                    labelName={"Current Address"}
                    onChange={(value, name) =>
                      handleNomineeInputChange(value, name, index, "guardian")
                    }
                    value={addNewMember.nominee[index]?.guardian?.address}
                  />
                  <ShowErrorMessage
                    message={
                      addNewMember?.formErrors
                        ? addNewMember?.formErrors[
                            `nominee[0].guardian.address`
                          ]
                        : ""
                    }
                  />
                </Grid>
              </>
            ) : (
              <></>
            )}
          </Grid>
          {addNewMember?.nominee?.length > 1 && (
            <Divider
              style={{
                marginBottom: "16px",
                borderWidth: "1.5px",
              }}
            />
          )}
        </React.Fragment>
      ))}
    </CustomBox>
  );
};

export default BankAndNominee;
