import React, { useEffect, useMemo, useState } from "react";

import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import { Typography } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import Palette from "../../themes/palette";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import createUseGet from "hooks/createUseGet";
import { styled } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import ClearIcon from "@mui/icons-material/Clear";

import { makeStyles } from "@mui/styles";
import * as _ from "lodash";

import ujala from "services/api/ujala";
import PropTypes from "prop-types";

const propTypes = {
  api: PropTypes.func,
  params: PropTypes.object,
  disabled: PropTypes.bool,
  onChangeSelect: PropTypes.func,
  labelName: PropTypes.string,
  IconComponent: PropTypes.node,
  showToolTip: PropTypes.bool,
  isMandatory: PropTypes.bool,
  onTextInputChange: PropTypes.func,
  callApiOnFirstRender: PropTypes.bool,
  placeHolder: PropTypes.string,
  disableClearable: PropTypes.bool,
  value: PropTypes.object,
};
const defaultProps = {
  api: () => {},
  disabled: false,
  onChangeSelect: () => {},
  labelName: "",
  showToolTip: false,
  isMandatory: false,
  onTextInputChange: () => {},
  callApiOnFirstRender: true,
  placeHolder: "",
  disableClearable: false,
  value: null
};
function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}
const SearchableSelectBase = (props) => {
  const {
    api,
    onChangeSelect,
    isMandatory,
    labelName,
    disabled,
    showToolTip,
    callApiOnFirstRender,
    IconComponent,
    onTextInputChange,
    placeHolder,
    disableClearable,
    ...restprops
  } = props;
  const { palette } = Palette();
  const [selectedValue, setSelectedValue] = React.useState("");

  const supText = {
    color: palette.error.main,
    fontSize: "14px",
    fontWeight: "bold",
  };

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = open && options.length === 0;

  const useGet = createUseGet({
    api: api ? api : ujala.getCategories, //need to fix this (brainstorm)
  });

  const { state, request } = useGet({
    onSuccess: ({ data: apiData }) => {
      console.log("api called searchable", apiData);
      setOptions([...apiData]);
    },
    onFail: (e) => {
      console.log("error is", e);
    },
  });

  const getDependencyArray = () => {
    let dependencyArray = [];
    if (api) {
      dependencyArray.push(api);
      if (props.params) {
        const apiParamsKeys = Object.keys(props.params);
        apiParamsKeys.map((key) => {
          dependencyArray.push(props.params[key]);
        });
      }
    }
    return dependencyArray;
  };

  useEffect(() => {
    if (api && !disabled && callApiOnFirstRender) {
      request({
        ...props.params,
      });
    }
  }, getDependencyArray());

  const onChangehandler = (value) => {
    onChangeSelect(value, props.name);
  };

  function debounce(func, timeout = 500) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }

  const onTextInputChangeHandler = debounce((value) => {
    if (value.length >= 3) onTextInputChange(value);
  });

  return (
    <>
      {/* <InputLabel sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '36px' }}>
                <Typography>
                    {labelName}
                    {isMandatory == true ? <sup style={supText}>*</sup> : null}
                </Typography>
                {showToolTip == true ? (
                    <Tooltip title="Delete" placement="bottom-end">
                        <IconButton sx={{ display: 'flex', justifyContent: 'space-between', color: '#1F6A93' }}>
                            <ErrorOutlineOutlinedIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                ) : null}
            </InputLabel> */}
      <Autocomplete
        sx={{ backgroundColor: "white" }}
        {...restprops}
        disableClearable={disableClearable}
        disabled={disabled}
        // value={value}
        // {...(agent && agent?.id && { value: agent })}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        getOptionLabel={(option) => `${option.id} - ${option.value}`}
        options={options}
        loading={state.loading}
        onChange={(e, value) => {
          onChangehandler(value);
        }}
        noOptionsText={"Type to search"}
        // sx={{
        //     '& .MuiOutlinedInput-root': {
        //         paddingTop: '5.3px!important',
        //         paddingBottom: '5.3px!important'
        //     },
        //     '& .MuiAutocomplete-popupIndicator': {
        //         width: '30px!important',
        //         height: '30px!important',
        //         padding: '0px!important'
        //     },
        //     '& 	.MuiAutocomplete-endAdornment': {
        //         position: 'absolute',
        //         top: '0px'
        //     }
        // }}
        onInputChange={(e, text) => {
          onTextInputChangeHandler(text);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            sx={{ height: "32px", backgroundColor: "white" }}
            placeholder={placeHolder}
            fullWidth
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <React.Fragment>
                  {IconComponent ? (
                    <InputAdornment position="start">
                      <IconComponent fontSize="small" color="inherit" />
                    </InputAdornment>
                  ) : null}
                </React.Fragment>
              ),
              endAdornment: (
                <React.Fragment>
                  {state.loading ? (
                    <CircularProgress color="inherit" size={10} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </>
  );
};
// const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
//     [`& .${tooltipClasses.tooltip}`]: {
//         backgroundColor: '#f5f5f9',
//         color: 'rgba(0, 0, 0, 0.87)',
//         maxWidth: 220,
//         fontSize: theme.typography.pxToRem(12),
//         border: '1px solid #dadde9'
//     }
// }));

const areEqual = (prevProps, nextProps) => {
  const preventRender =
    _.isEqual(prevProps.params, nextProps.params) &&
    prevProps.value === nextProps.value &&
    prevProps.disabled === nextProps.disabled;

  return preventRender;
};
SearchableSelectBase.propTypes = propTypes;
SearchableSelectBase.defaultProps = defaultProps;
export default React.memo(SearchableSelectBase, areEqual);
