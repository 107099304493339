// SearchButton.tsx
import React, { useCallback } from 'react';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';

import { openMiniDrawer } from 'layouts/drawerAndHeader/reducers';

interface Props {
  handleOpenTable?: () => void;
}

const SearchButton: React.FC<Props> = ({handleOpenTable}) => {
  const dispatch = useDispatch();
  const drawer = useSelector((state: any) => state.drawer);

  const handleDrawerToggle = useCallback(() => {
    dispatch(openMiniDrawer());
  
    if (handleOpenTable) {
      handleOpenTable(); 
    }
  }, [ drawer.isMiniDrawerOpen, handleOpenTable]); 

  return (
    <Button  onClick={handleDrawerToggle} sx={{padding: '4px 16px', 
    color: '#1F7A8C', 
    backgroundColor: '#F7F7F7', 
    height: '34px',
    border: '1px solid #1F7A8C',
    borderRadius: '4px'
    }}>
     Search
    </Button>
  );
};

export default SearchButton;
