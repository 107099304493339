import { toast } from 'react-toastify';
export const throwError = (response) => {
    toast.error(response.message);
    const errorObject = {
        message: response.message,
        code: response.code,
        status: response.code
    };

    throw errorObject;
};

/**
 * 403
 *
 */

// eslint-disable-next-line consistent-return
function handleSuccess(response) {
    const data = response.data || {};
    if (data.code == 400) {
        throwError(response.data);
    } else if (data.code == 500) {
        throwError(response.data);
    } else if (data.code == 401) {
        throwError(response.data);
    } else if (data.code == 403) {
        throwError(response.data);
    } else {
        return response && response.data ? response.data : response;
    }
}

export default handleSuccess;
