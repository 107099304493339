function sanitizeError(message, defaultErrorMessage) {
    const badErrorKeywords = ['sql', 'jpa transaction', 'exception', 'tunnel', 'null', 'html', 'font', 'argument', 'com.scnl'];

    if (typeof message === 'string') {
        if (/with status code/.test(message)) {
            switch (true) {
                case /401/.test(message):
                    return 'Unauthorized request';
                case /500/.test(message):
                    return 'Something went wrong, please try later.';
                default:
                    return defaultErrorMessage;
            }
        } else if (message.search(new RegExp(badErrorKeywords.join('|'), 'i')) !== -1) {
            return defaultErrorMessage;
        }
    }
    //   if (message === "Network Error") {
    //     return "Service Unavailable";
    //   }
    return message;
}

export default sanitizeError;
