import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import PopUpHeader from './PopUpHeader';
import PropTypes from 'prop-types';

const propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    modalStyleprops: PropTypes.object
};

const defaultProps = {
    open: false,
    headerTitle: 'Modal',
    modalStyleprops: {}
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 1.5,
    bgcolor: 'background.paper',
    boxShadow: 24
};

const PopUpModalWithHeader = (props) => {
    const { open, handleClose, modalStyleprops, children } = props;

    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={{ ...style, ...modalStyleprops }}>
                <PopUpHeader {...props} />
                <Box>{children}</Box>
            </Box>
        </Modal>
    );
};
PopUpModalWithHeader.propTypes = propTypes;
PopUpModalWithHeader.defaultProps = defaultProps;

export default PopUpModalWithHeader;
