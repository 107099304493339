// types
import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
  applicationId: "",
  memberId: "",
  aadhaarNo: "",
  salutation: "",
  fullName: "",
  gender: "",
  dob: "",
  joiningDate: "",
  religion: "INDIAN",
  emailId: "",
  mobileNumber: "",
  alternateMobileNumber: "",
  bloodGroup: "",
  panNo: "",
  highestQualification: "",
  occupationType: "",
  familyIncome: "",
  maritalStatus: "",
  childCount: 0,
  dom: "",
  isSms: "N",
  aadhaarAddress: {
    address: "",
    pincode: "",
    city: "",
    country: "India",
    state: "",
  },
  currentAddress: {
    address: "",
    pincode: "",
    city: "",
    country: "India",
    state: "",
  },
  bankDetails: {
    accountNumber: "",
    accountHolderName: "",
    bankName: "",
    branchName: "",
    ifsc: "",
  },
  nominee: [
    {
      // memberId: "",
      name: "",
      percentage: "",
      relation: "",
      dob: "",
      pincode: "",
      state: "",
      city: "",
      address: "",
      guardian: {
        memberId: "",
        name: "",
        address: "",
        state: "",
        city: "",
        pincode: "",
        dob: "",
      },
    },
  ],
  totalPercentage: 0,
  nimineeAge: 0,
  referral: {
    member: null,
    firstName: "",
    type: "",
    level: "",
    mobileNo: "",
    branchId: "",
  },
  witness: [
    {
      member: null,
      fullName: "",
      mobileNo: "",
    },
    {
      member: null,
      fullName: "",
      mobileNo: "",
    },
  ],
  payment: {
    applicationId: "",
    paymentType: "CASH",
    membershipFee: 0,
    noOfShares: 0,
    totalAmount: 0,
    shareFee: 0,
    paid: false
  },
  current_same_as_aadhar_address: false,
  permanent_same_as_current_address: false,
  permanent_same_as_aadhar_address: false,

  is_existing_member1: false,
  witness_is_existing_member1: false,
  witness_is_existing_member2: false,
  guaranter_is_existing_member1: false,
  guaranter_is_existing_member2: false,

  formErrors: null,

  saveAddressDetails: {
    state: "Haryana",
    city: "Gurugram",
  },
  memberList: [],
  document: {
    requiredDocuments: [],
  },
  filters: {
    memberId: "",
    applicationId: "",
    name: "",
    aadhaarNo: "",
    contact: "",
    branchName: "",
  },
  activeStep: 0,
  isMemberEditable: false,
};

// ==============================|| SLICE - MENU ||============================== //

const addNewMember = createSlice({
  name: "addNewMember",
  initialState,
  reducers: {
    setFormData: (state, action) => {
      const { name, value } = action.payload;

      if (value?.name) {
        return {
          ...state,
          [value.name]: value?.value,
        };
      }

      return {
        ...state,
        [name]: value,
      };
    },

    saveErrors: (state, action) => {
      return {
        ...state,
        formErrors: {
          // ...state.formErrors, // Ensure existing errors are kept if needed
          ...action.payload, // Merge new errors from the action
        },
      };
    },

    setIntroMember: (state, action) => {
      return {
        ...state,
        referMemberId: action.payload,
      };
    },

    setSalutation: (state, action) => {
      return {
        ...state,
        salutation: action.payload,
      };
    },

    setGenderVal: (state, action) => {
      return {
        ...state,
        gender: action.payload,
      };
    },

    setBloodGroupVal: (state, action) => {
      return {
        ...state,
        bloodGroup: action.payload,
      };
    },

    setQualificationVal: (state, action) => {
      return {
        ...state,
        highestQualification: action.payload,
      };
    },

    setOcuupationVal: (state, action) => {
      return {
        ...state,
        occupationType: action.payload,
      };
    },

    setMarriedStatus: (state, action) => {
      return {
        ...state,
        maritalStatus: action.payload,
      };
    },

    setNoOfChildren: (state, action) => {
      return {
        ...state,
        childCount: action.payload,
      };
    },
    setCurrentState: (state, action) => {
      return {
        ...state,
        currentState: action.payload,
      };
    },
    setMemberData: (state, action) => {
      return {
        ...state,
        memberData: action.payload,
      };
    },
    setReligoiusData: (state, action) => {
      return {
        ...state,
        religion: action.payload,
      };
    },
    setNomineeDetails: (state, action) => {
      const { name, value, index, thirdParam } = action.payload;
      const updatedNomineeDetails = Array.from(state.nominee);
      if (thirdParam !== undefined && thirdParam == "referal") {
        return {
          ...state,
          referral: {
            ...state.referral,
            [name]: value,
          },
        };
      }

      if (thirdParam !== undefined && thirdParam === "guardian") {
        updatedNomineeDetails[index] = {
          ...updatedNomineeDetails[index],
          guardian: {
            ...updatedNomineeDetails[index].guardian,
            [name]: value,
          },
        };
      } else {
        updatedNomineeDetails[index] = {
          ...updatedNomineeDetails[index],
          [name]: value,
        };
      }

      return {
        ...state,
        nominee: Array.from(updatedNomineeDetails),
      };
    },

    updateGurdianStateAndCity: (state, action) => {
      const { state: gurdianState, city, index } = action?.payload;

      const updatedNomineeDetails = Array.from(state.nominee);
      updatedNomineeDetails[index] = {
        ...updatedNomineeDetails[index],
        guardian: {
          ...updatedNomineeDetails[index].guardian,
          state: gurdianState,
          city: city,
        },
      };

      return {
        ...state,
        nominee: Array.from(updatedNomineeDetails),
      };
    },

    setWitnessGurantorRequests: (state, action) => {
      const { name, value, index, type } = action.payload;

      const updatedWitnessRequests = state[type].map((request, i) => {
        if (i === index) {
          return {
            ...request,
            [name]: value,
          };
        }
        return request;
      });
      return {
        ...state,
        [type]: updatedWitnessRequests,
      };
    },

    updateWitnessAndGuarantorRequests: (state, action) => {
      const { firstName, memberFullName, mobileNo, index, label } =
        action.payload;

      const updatedWitnessRequests = state[label].map((request, i) => {
        if (i === index) {
          return {
            ...request,
            mobileNo: mobileNo,
            fullName: firstName,
          };
        }
        return request;
      });
      return {
        ...state,
        witness: updatedWitnessRequests,
      };
    },
    setNoOfNominees: (state, action) => {
      return {
        ...state,
        noOfNominees: action.payload,
      };
    },

    setAddressByType: (state, action) => {
      const { name, value, type } = action.payload;
      return {
        ...state,
        [type]: {
          ...state[type],
          [name]: value,
        },
      };
    },

    setBankDetails: (state, action) => {
      const { name, value } = action.payload;

      return {
        ...state,
        bankDetails: {
          ...state.bankDetails,
          [name]: value,
        },
      };
    },

    setBankDetailsByIFSC: (state, action) => {
      const { bank, branch, city, pincode, ifsc } = action.payload;

      return {
        ...state,
        bankDetails: {
          ...state.bankDetails,
          bankName: bank,
          branchName: branch,
          ifsc
        },
      };
    },

    setCurrentAddress: (state, action) => {
      const { address, city, currentState, pincode } = action.payload?.value;
      return {
        ...state,
        currentAddress: address || "",
        currentState: currentState || "",
        currentCity: city || "",
        currentPincode: pincode || "",
      };
    },

    setPermanentAddress: (state, action) => {
      const { address, city, currentState, pincode } = action.payload?.value;
      return {
        ...state,
        permanentAddress: address || "",
        permanentState: currentState || "",
        permanentCity: city || "",
        permanentPincode: pincode || "",
      };
    },
    setPermanentAddressAsAadharAddress: (state, action) => {
      const { address, city, currentState, pincode } = action.payload?.value;
      return {
        ...state,
        permanentAddress: address || "",
        permanentState: currentState || "",
        permanentCity: city || "",
        permanentPincode: pincode || "",
      };
    },
    setNomineeDetailsAsCount: (state, action) => {
      const newNominees = [...state.nominee, action.payload.value];
      return {
        ...state,
        nominee: newNominees,
      };
    },
    updateNomineeDetail: (state, action) => {
      const { nomineeCount } = action.payload;
      if (state.nominee?.length > nomineeCount) {
        const updatedNominee = [...state.nominee];
        const updatedNewNominee2 = updatedNominee.splice(0, nomineeCount);
        return {
          ...state,
          nominee: updatedNewNominee2,
        };
      } else {
        const nomineeObj = {
          name: "",
          percentage: "",
          relation: "",
          dob: "",
          pincode: "",
          state: "",
          city: "",
          address: "",
          guardian: null,
        };

        const newArray = new Array(parseInt(nomineeCount - 1))
          .fill(nomineeObj)
          .map((obj) => {
            return {
              ...obj,
            };
          });

        const newNominees = [...state.nominee, ...newArray];
        return {
          ...state,
          nominee: newNominees,
        };
      }
    },

    updateNomineeAddressDetail: (state, action) => {
      const { index, city, state: NomineeState } = action.payload;

      const updatedWitnessRequests = state.nominee.map((request, i) => {
        if (i === index) {
          return {
            ...request,
            city: city,
            state: NomineeState,
          };
        }
        return request;
      });
      return {
        ...state,
        nominee: updatedWitnessRequests,
      };
    },

    updateReferalData: (state, action) => {
      const { firstName, mobileNo, memberType, branchId, level } = action.payload;

      return {
        ...state,
        referral: {
          ...state.referral,
          firstName: firstName,
          type: memberType,
          level: level,
          mobileNo: mobileNo,
          branchId: branchId,
        },
      };
    },

    updateAddress: (state, action) => {
      const { name, vlaue } = action.payload;
    },

    updateAddressDetails: (state, action) => {
      const { index, addType } = action.payload;

      if (addType === "CURRENT") {
        const updatedAddress = state.address.map((item, i) => {
          if (i === 1) {
            return { ...state.address[0], addType: item.addType };
          }
          return item;
        });
        return {
          ...state,
          address: updatedAddress,
        };
      }

      if (addType === "PERMANENT") {
        const updatedAddress = state.address.map((item, i) => {
          if (i === 2) {
            return { ...state.address[1], addType: item.addType };
          }
          return item;
        });
        return {
          ...state,
          address: updatedAddress,
        };
      }
    },

    addressCopy: (state, action) => {
      const { copyFrom, copyTo } = action.payload;
      return {
        ...state,
        [copyTo]: { ...state[copyFrom] },
      };
    },

    setReferalData: (state, action) => {
      const { name, value } = action.payload;

      if (name === "memberId") {
        return {
          ...state,
          referral: {
            ...state.referral,
            memberId: value,
          },
        };
      }

      return {
        ...state,
        referral: {
          ...state.referral,
          [name]: value,
        },
      };
    },

    setAadharData: (state, action) => {
      const {
        name,
        address,
        dist,
        dob,
        gender,
        pincode,
        state: aadharState,
      } = action.payload;

      return {
        ...state,
        fullName: name,
        dob: dob,
        gender: gender,
        aadhaarAddress: {
          ...state.aadhaarAddress,
          address: address,
          pincode: pincode,
          city: dist,
          state: aadharState,
        },
      };
    },

    clearErrors: (state, action) => {
      delete state.formErrors?.[action.payload?.name];
    },

    setMemberApplicationData: (state, action) => {
      const {
        memberId,
        applicationId,
        fullName,
        salutation,
        aadhaarNo,
        dob,
        joiningDate,
        doj,
        religion,
        emailId,
        isSms,
        bloodGroup,
        maritalStatus,
        occupationType,
        mobileNumber,
        alternateMobileNumber,
        dom,
        panNo,
        gender,
        highestQualification,
        familyIncome,
        bankDetails,
        nominee,
        witness,
        aadhaarAddress,
        currentAddress,
        aadhaarDetail,
        currentAddressResponse,
        referral,
      } = action.payload;

      return {
        ...state,
        applicationId: applicationId,
        memberId: memberId,
        aadhaarNo: aadhaarNo,
        fullName: fullName,
        mobileNumber: mobileNumber,
        alternateMobileNumber: alternateMobileNumber,
        salutation: salutation,
        occupationType: occupationType,
        joiningDate: doj,
        isSms: isSms,
        emailId: emailId,
        religion: religion,
        dom: dom,
        dob: dob,
        panNo: panNo,
        gender: gender,
        bloodGroup: bloodGroup,
        maritalStatus: maritalStatus,
        highestQualification: highestQualification,
        familyIncome: familyIncome,
        bankDetails: bankDetails,
        nominee: nominee,
        witness: witness,
        aadhaarAddress: {
          address: aadhaarDetail?.address,
          country: aadhaarDetail?.country,
          state: aadhaarDetail?.state,
          pincode: aadhaarDetail?.pincode,
          city: aadhaarDetail?.city,
        },
        currentAddress: {
          address: currentAddress?.address,
          country: currentAddress?.country,
          state: currentAddress?.state,
          pincode: currentAddress?.pincode,
          city: currentAddress?.city,
        },
        referral: {
            ...referral
        },
      };
    },

    setPaymentType: (state, action) => {
      const { paymentType } = action.payload;
      return {
        ...state,
        payment: {
          ...state.payment,
          paymentType: paymentType?.value,
        },
      };
    },

    setRequiredDocuments: (state, action) => {
      const documentListWithUpdatedKey = action.payload?.map((document) => ({
        ...document,
        documentURL: "",
      }));
      return {
        ...state,
        document: {
          ...state.document,
          requiredDocuments: documentListWithUpdatedKey,
        },
      };
    },

    updateRequiredDocuments: (state, action) => {
      const { documentId, documentURL } = action.payload;
      const updatedDocuments = state.document.requiredDocuments.map((doc) =>
        doc.documentId === documentId ? { ...doc, documentURL } : doc
      );
      return {
        ...state,
        document: {
          ...state.document,
          requiredDocuments: updatedDocuments,
        },
      };
    },

    setSelectedDocumentId: (state, action) => {
      const { documentId } = action.payload;
      return {
        ...state,
        selectedDocumentId: documentId,
      };
    },
    setFilterFormData: (state, action) => {
      const { name, value } = action.payload;
      return {
        ...state,
        filters: {
          ...state.filters,
          [name]: value,
        },
      };
    },

    setTotalNomineePercentage: (state, action) => {
      const totalPercentage = state.nominee.reduce((total, request) => {
        return total + (parseInt(request.percentage) || 0);
      }, 0);
      return {
        ...state,
        totalPercentage,
      };
    },

    setActiveStep: (state, action) => {
      return {
        ...state,
        activeStep: action.payload,
      };
    },
    setIsMemberEditable: (state, action) => {
      state.isMemberEditable = action.payload;
    },
    resetState: (state) => {
      return initialState;
    }
  },
});

export default addNewMember.reducer;

export const {
  setFormData,
  clearErrors,
  setIntroMember,
  setSalutation,
  setMarriedStatus,
  setNoOfChildren,
  setGenderVal,
  setBloodGroupVal,
  setOcuupationVal,
  setQualificationVal,
  saveErrors,
  setCurrentState,
  setPaymentType,
  setMemberData,
  setReligoiusData,
  setNomineeDetails,
  setWitnessGurantorRequests,
  setBankDetails,
  setCurrentAddress,
  setPermanentAddressAsAadharAddress,
  setNoOfNominees,
  setNomineeDetailsAsCount,
  updateAddress,
  setBankDetailsByIFSC,
  updateAddressDetails,
  setAddressByType,
  addressCopy,
  updateNomineeDetail,
  updateReferalData,
  updateNomineeAddressDetail,
  updateWitnessAndGuarantorRequests,
  setReferalData,
  setAadharData,
  setMemberApplicationData,
  setRequiredDocuments,
  updateRequiredDocuments,
  setFilterFormData,
  setTotalNomineePercentage,
  updateGurdianStateAndCity,
  setActiveStep,
  setIsMemberEditable,
  resetState
} = addNewMember.actions;
