import * as _ from "lodash";

export const defaultLoginParam = Object.freeze({
  username: "310860",
  password: "Deepak@123",
  applicationId: "com.scnl.hcm.portal",
  appVersion: "21",
  deviceId: "61b93a6b77a47277",
  imei1: "111111111111111",
  loginMode: "android",
  client_id: "280eecb8199656219381abef5f253d12",
  client_secret:
    "0cb9c382c4db6f38f54cda7bc4fa20e23cf88246d8b16fc1997237b526814414",
  grant_type: "password",
  deviceTime: new Date().getTime(),
});

export const generateLoginParams = (param:any) => ({
  ...defaultLoginParam,
  ...param,
});

export const getLoginData = (apiResponse:any) => ({
  accessToken: _.get(apiResponse, "accessToken"),
  refreshToken: _.get(apiResponse, "refresh_token"),
  expiresIn: _.get(apiResponse, "expires_in"),
});

export const getBasicDetailData = (apiResponse:any) => apiResponse.responseObject;
