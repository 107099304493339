import { useSelector } from "react-redux";
import * as _ from "lodash";
/**
 *
 * @typedef Credentials
 * @type {object}
 * @property {String} accessToken
 * @property {String} refreshToken
 *
 */

/**
 * @returns {Credentials}
 */
const useCredentials = () => {
  const credentials = useSelector((state) =>
    _.get(state, "auth.jwtToken", null)
  );

  // const user = useSelector((state) => _.get(state, "auth.user", null));
  // return { ...credentials, ...user };
  return credentials;
};

export default useCredentials;
