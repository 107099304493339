import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Guarantor {
  memberId: string;
  firstName: string;
  middleName: string;
  lastName: string;
  branchName: string;
  mobileNo: string;
  memberType: string;
  memberStatus: string;
}

interface MemberDetails {
  memberId: string;
  firstName: string;
  branchName: string;
  mobileNo: string;
}

// Define the shape of the state
interface PromoteMemberMasterState {
  error?: string;
  data?: any;
  fetchMemberPromoteDetails: any;
  list: Array<{
    memberId: number;
    firstName: string;
    middleName: string;
    lastName: string;
  }>;
  totalCount: number;
  guarantor1: Guarantor;
  guarantor2: Guarantor;
  memberDetails: MemberDetails;
  TaggedTo: Guarantor;
  promoteToId?: number;
  promoteToValue?: string;
  uploadedFiles: File[];  // New state field for uploaded files
}

const initialState: PromoteMemberMasterState = {
  error: undefined,
  data: undefined,
  fetchMemberPromoteDetails: "",
  list: [],
  totalCount: 0,
  guarantor1: {
    memberId: "",
    firstName: "",
    middleName: "",
    lastName: "",
    branchName: "",
    mobileNo: "",
    memberType: "",
    memberStatus: "",
  },
  guarantor2: {
    memberId: "",
    firstName: "",
    middleName: "",
    lastName: "",
    branchName: "",
    mobileNo: "",
    memberType: "",
    memberStatus: "",
  },
  TaggedTo: {
    memberId: "",
    firstName: "",
    middleName: "",
    lastName: "",
    branchName: "",
    mobileNo: "",
    memberType: "",
    memberStatus: "",
  },
  memberDetails: {
    firstName: "",
    memberId: "",
    branchName: "",
    mobileNo: "",
  },
  promoteToId: undefined,
  promoteToValue: undefined,
  uploadedFiles: [], // Initialize uploadedFiles as an empty array
};

interface MemberIDResponseType {
  code: number;
  message: string;
  memberId: string;
  firstName: string;
  middleName: string;
  lastName: string;
  branchName: string;
  mobileNo: string;
  memberType: string;
  memberStatus: string;
}

const PromoteMemberMaster = createSlice({
  name: "PromoteMemberMaster",
  initialState,
  reducers: {
    fetchMemberPromoteDetailsSuccess(
      state,
      action: PayloadAction<MemberIDResponseType & { guarantorNumber: string }>
    ) {
      const { guarantorNumber, ...guarantorData } = action.payload;

      if (
        guarantorNumber === "guarantor1" ||
        guarantorNumber === "guarantor2" ||
        guarantorNumber === "TaggedTo" ||
        guarantorNumber === "memberDetails"
      ) {
        state[guarantorNumber] = { ...guarantorData };
      }
    },

    promoteTo(state, action: PayloadAction<{ id: number; value: string }>) {
      const { id, value } = action.payload;
      state.promoteToId = id;
      state.promoteToValue = value;
    },

    setFetchError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },

    // Reducer to handle file upload success
    uploadFileSuccess(state, action: PayloadAction<File[]>) {
      console.log("wefrgthyuj", action.payload)
      state.uploadedFiles = action.payload;
    },

    // Reducer to handle file upload errors
    setFileUploadError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
  },
});

export default PromoteMemberMaster.reducer;

export const {
  fetchMemberPromoteDetailsSuccess,
  promoteTo,
  setFetchError,
  uploadFileSuccess, 
  setFileUploadError,
} = PromoteMemberMaster.actions;
