//@ts-nocheck
import { call, put, takeLatest, select, CallEffect } from "redux-saga/effects";

import { toast } from "react-toastify";

import {
  ADDRESS_BY_PINCODE,
  CREATE_SAVING_ACCOUNT,
  GET_COMMISSION_AGENT_DETAILS,
  GET_ENTROLLED_PRODUCT_LIST,
  GET_NOMINEE_DETAILS_BY_MEMBERID_AND_PRODUCTID,
  GET_PRODUCT_DETAILS,
  GET_PRODUCT_DETAILS_BY_ACCOUNTNUMBER,
  GET_PRODUCT_MASTER_DETAILS,
} from "store/types";
import { dispatch } from "store/index";

import {
  setCommissionAgentData,
  setFormData,
  setMemberDetails,
  setNoOfNominees,
  setProductMemberDetailsData,
  setViewProductDetails,
  updateCommissionAgentData,
  updateGurdianStateAndCity,
  updateNomineeDetail,
  updateNomineeDetailsWithResponse,
  updateselectProductDetails,
} from "./reducers";
import { getCredentials } from "utils/helper";
import Ujala from "services/api/ujala";
import Investment from "services/api/investment";
import { updateNomineeAddressDetail } from "./reducers";

interface ApiPayload {
  data?: any; // Replace 'any' with the actual type if you know it
  accessToken?: string;
}

interface ResponseType {
  code: number;
  responseObject: any;
}

function* getSeledtedProjectDetailsSaga(
  apiData: Generator<CallEffect<ResponseType>, void, ResponseType>
) {
  try {
    const { accessToken } = yield select(getCredentials);
    const apiPayload: ApiPayload = {};
    apiPayload.data = apiData;
    apiPayload.accessToken = accessToken;
    const response: ResponseType = yield call(
      Investment.getSelectedProductDetails,
      apiPayload
    );
    if (response.code == 200) {
      const {
        minPrincipalAmount,
        MaxPrincipalAmount,
        maxCashDisb,
        interest,
        isPassbookReq,
        isSecured,
      } = response.responseObject;

      dispatch(
        updateselectProductDetails({
          minPrincipalAmount,
          MaxPrincipalAmount,
          maxCashDisb,
          interest,
          isPassbookReq,
          isSecured,
        })
      );
    } else {
      toast.error("Error");
    }
  } catch (error: any) {
    // toast.error(error?.message);
  }
}

function* getCommissionAgentDetailsSaga(
  apiData: Generator<CallEffect<ResponseType>, void, ResponseType>
) {
  try {
    const { accessToken } = yield select(getCredentials);
    const apiPayload: ApiPayload = {};
    apiPayload.data = apiData;
    apiPayload.accessToken = accessToken;
    const response: ResponseType = yield call(
      Investment.getCommisssionAgentDetails,
      apiPayload
    );
    if (response.code == 200) {
      const {
        commissionAgentId,
        name,
        memberType,
        designationLevel,
        branch,
        mobileNo,
        status,
      } = response?.responseObject;
      dispatch(
        setCommissionAgentData({
          commissionAgentId,
          name,
          memberType,
          designationLevel,
          branch,
          mobileNo,
          status,
        })
      );

      toast.success(response?.responseObject);
    } else {
      toast.error("Error");
    }
  } catch (error: any) {
    toast.error(error?.message);
  }
}

function* getMemberDetailsByIDSaga(apiData) {
  try {
    const { accessToken } = yield select(getCredentials);
    const { memberId, index, label } = apiData;
    const apiPayload = {
      data: {
        memberId: memberId,
      },
    };
    apiPayload.accessToken = accessToken;
    const response = yield call(Ujala.getMemberDetailsByID, apiPayload);
    if (response.code == 200) {
      const { firstName, mobileNo, memberType } = response.responseObject;
      if (label === "product_commissionAgent") {
        dispatch(
          updateCommissionAgentData({
            firstName,
            mobileNo,
            memberType,
          })
        );
        return;
      } else {
        dispatch(
          setMemberDetails({
            firstName,
            mobileNo,
            memberType,
          })
        );
        return;
      }
    } else {
      toast.error(response?.message);
    }
  } catch (error) {
    toast.error("Something went wrong");
  }
}

function* getProductMemberDetailsByIDSaga(apiData) {
  try {
    const { accessToken } = yield select(getCredentials);
    const { memberId } = apiData;
    const apiPayload = {
      data: {
        memberId: memberId,
      },
    };
    apiPayload.accessToken = accessToken;
    const response = yield call(Ujala.getMemberDetailsByID, apiPayload);
    if (response.code == 200) {
      const { firstName, mobileNo, memberType } = response.responseObject;
      dispatch(
        setProductMemberDetailsData({
          firstName,
          mobileNo,
          memberType,
          memberStatus,
        })
      );
    } else {
      toast.error(response?.message);
    }
  } catch (error) {
    toast.error("Something went wrong");
  }
}

function* getEnrolledProductListSaga(
  apiData: Generator<CallEffect<ResponseType>, void, ResponseType>
) {
  try {
    const { accessToken } = yield select(getCredentials);
    const apiPayload: ApiPayload = {};
    apiPayload.data = apiData;
    apiPayload.accessToken = accessToken;
    const response: ResponseType = yield call(
      Investment.getEnrolledProductList,
      apiPayload
    );
    if (response.code == 200) {
      // TODO: SET API RESPONSE
      toast.success(response?.responseObject);
    } else {
      toast.error("Error");
    }
  } catch (error: any) {
    toast.error(error?.message);
  }
}
function* getnomineeDetailsByMemberIdAndProductIdSaga(
  apiData: Generator<CallEffect<ResponseType>, void, ResponseType>
) {
  try {
    const { accessToken } = yield select(getCredentials);
    const apiPayload: ApiPayload = {};
    apiPayload.data = apiData;
    apiPayload.accessToken = accessToken;
    const response: ResponseType = yield call(
      Investment.getNomineeDetailsByMemberIdAndProductId,
      apiPayload
    );
    if (response.code == 200) {
      if (Array.isArray(response?.responseObject)) {
        dispatch(
          setNoOfNominees({
            name: "nomineeCount",
            value: response?.responseObject?.length,
          })
        );

        const filteredData = response?.responseObject?.map(
          ({
            name,
            nomPercentage,
            dob,
            state,
            city,
            relation,
            pincode,
            address,
          }) => ({
            name,
            nomPercentage,
            dob,
            state,
            city,
            relation,
            pincode,
            address,
          })
        );
        dispatch(
          updateNomineeDetailsWithResponse({ nomineeList: filteredData })
        );
      }
    } else {
      toast.error("Error");
    }
  } catch (error: any) {
    toast.error(error?.message);
  }
}
function* getAddressDetailsByPincodeSaga(apiData) {
  try {
    const { pincode, addType, index, thirdParam } = apiData;

    const { accessToken } = yield select(getCredentials);
    const apiPayload = { data: {} };
    apiPayload.data.pincode = pincode;
    apiPayload.accessToken = accessToken;
    const response = yield call(Ujala.getAddressDetails, apiPayload);
    if (response.code == 200) {
      const { city, state } = response.responseObject;

      if (thirdParam === "guardian") {
        dispatch(
          updateGurdianStateAndCity({ city: city, state: state, index: index })
        );
      }
      if (addType === "NOMINEE_DETAILS") {
        dispatch(updateNomineeAddressDetail({ index, city, state }));
      }
    }
  } catch (error) {
    toast.error(error?.message);
  }
}

function* cretaeSavingAccountSaga(apiData) {
  try {
    const { accessToken } = yield select(getCredentials);
    const apiPayload = {};
    const {
      commissionAgent,
      filters,
      formErrors,
      insurable,
      isNomineeExit,
      productMemberDetails,
      selectProductDetails,
      selectedEntrolledProductId,
      selectedEntrolledProductValue,
      selectedOwnProductName,
      totalPercentage,
      NOMINEE_DETAILS,
      ePassbook,
      noOfNominees,
      productNameValue,
      productCategoryValue,
      isAddNewProduct,
      isProductDetailsView,
      validationError,
      ...others
    } = apiData.savingAccount;

    apiPayload.data = others;
    apiPayload.accessToken = accessToken;
    const response = yield call(Investment.openProductAccount, apiPayload);
    if (response.code == 200) {
      toast.success("Account Open Successfully!!!");
      setTimeout(() => {
        window?.location?.reload();
      }, 2000);
      dispatch(setFormData({ name: "isNomineeExit", value: false }));
    } else {
      toast.error(response?.message);
    }
  } catch (error) {
    // toast.error("Something went wrong");
  }
}

function* getProductDetailsByAccountNumberSaga(apiData) {
  try {
    const { accessToken } = yield select(getCredentials);
    const apiPayload: ApiPayload = {};
    apiPayload.data = apiData;
    apiPayload.accessToken = accessToken;
    const response = yield call(
      Investment.getProductDetailsByAccountNumber,
      apiPayload
    );
    if (response.code == 200) {
      dispatch(
        setViewProductDetails({ productDetails: response?.responseObject })
      );
    }
  } catch (error) {
    toast.error(error?.message);
  }
}

function* getProjectDetails(action) {
  yield call(getSeledtedProjectDetailsSaga, action.payload);
}

function* getCommissionAgentDetails(action) {
  yield call(getCommissionAgentDetailsSaga, action.payload);
}

function* getMemberDetailsByID(action) {
  yield call(getMemberDetailsByIDSaga, action.payload);
}

function* createSavingAccount(action) {
  yield call(cretaeSavingAccountSaga, action.payload);
}

function* getAddressDetailsByPincode(action) {
  yield call(getAddressDetailsByPincodeSaga, action.payload);
}

function* getnomineeDetailsByMemberIdAndProductId(action) {
  yield call(getnomineeDetailsByMemberIdAndProductIdSaga, action.payload);
}

function* getEnrolledProductList(action) {
  yield call(getEnrolledProductListSaga, action.payload);
}

function* getProductDetailsByAccountNumber(action) {
  yield call(getProductDetailsByAccountNumberSaga, action.payload);
}

export default function* saga() {
  yield takeLatest(GET_PRODUCT_DETAILS, getProjectDetails);
  yield takeLatest(GET_COMMISSION_AGENT_DETAILS, getCommissionAgentDetails);
  yield takeLatest(GET_PRODUCT_MASTER_DETAILS, getMemberDetailsByID);
  yield takeLatest(CREATE_SAVING_ACCOUNT, createSavingAccount);
  yield takeLatest(GET_ENTROLLED_PRODUCT_LIST, getEnrolledProductList);
  // NOMINEE
  yield takeLatest(
    GET_NOMINEE_DETAILS_BY_MEMBERID_AND_PRODUCTID,
    getnomineeDetailsByMemberIdAndProductId
  );
  yield takeLatest(ADDRESS_BY_PINCODE, getAddressDetailsByPincode);
  yield takeLatest(
    GET_PRODUCT_DETAILS_BY_ACCOUNTNUMBER,
    getProductDetailsByAccountNumber
  );
}
