import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import { Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FilterContext from './context';
import MaterialTableInner from './materialTableInner';

const propTypes = {
    // showToast: PropTypes.func.isRequired,
    onRowPress: PropTypes.func,
    columns: PropTypes.arrayOf(PropTypes.object.isRequired),
    variant: PropTypes.string,
    expandableRender: PropTypes.func,
    data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
    loading: PropTypes.bool.isRequired,
    totalCount: PropTypes.number.isRequired,
    onTableAction: PropTypes.func.isRequired,
    validationSchema: PropTypes.any.isRequired,
    initialValues: PropTypes.object.isRequired,
    formRender: PropTypes.any,
    keyExtractor: PropTypes.func,
    pageSize: PropTypes.number,
    tableOptions: PropTypes.object,
    rowSelectionConfig: PropTypes.object,
    filterRef: PropTypes.object,
    filterRequired: PropTypes.bool,
    seprateCheckBoxRow: PropTypes.bool,
    seprateCheckBoxOnClick: PropTypes.func,
    seprateRowOnClick: PropTypes.func,
    errorKey: PropTypes.string,
    actions: PropTypes.array,
    onActionClick: PropTypes.func,
    allSelector: PropTypes.func,
    apiFixedParam: PropTypes.object,
    filterType: PropTypes.string
};

const defaultProps = {
    // variant: Table.CONSTANTS.VARIANT.NORMAL,
    variant: 'normal',
    keyExtractor: (item) => item.id,
    pageSize: 10,
    tableOptions: { selection: false },
    onRowPress: () => {},
    filterRef: { current: () => {} },
    filterRequired: true,
    actions: [],
    onActionClick: () => {},
    allSelector: () => {},
    apiFixedParam: {},
    totalCount: 0
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },

    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
        marginTop: 5,
        borderRadius: 0,
        display: 'table',
        tableLayout: 'fixed'
    },
    table: {
        minWidth: 750
    }
}));

const payload = ({ values = {}, pageNumber, pageSize, ...apiFixedParam }) => ({
    ...values,
    pageNumber: pageNumber + 1,
    pageSize,
    ...apiFixedParam
});

const MaterialTable = (props) => {
    const {
        // showToast,
        onRowPress,
        columns,
        variant,
        expandableRender,
        data,
        loading,
        totalCount,
        onTableAction,
        validationSchema,
        initialValues,
        formRender: FormRender,
        keyExtractor,
        pageSize,
        tableOptions,
        rowSelectionConfig,
        filterRef,
        filterRequired,
        seprateCheckBoxRow,
        seprateCheckBoxOnClick,
        seprateRowOnClick,
        errorKey,
        actions,
        onActionClick,
        allSelector,
        apiFixedParam,
        filterType,
        ...restProps
    } = props;
    const classes = useStyles();
    const [isExpanded, setExpanded] = useState(true);
    const [rowsPerPage, changeRowsPerPage] = useState(pageSize);
    const [page, setPage] = useState(0);
    const [filter, setCurrentFilter] = useState(initialValues);
    const [theme, setTheme] = useState('dark');

    useEffect(() => {
        filterRef.current = setCurrentFilter;
    }, [filterRef]);
    const onChangeRowsPerPage = ({ params: [event], formikProps }) => {
        const pageCount = event;
        const pageNumber = 0;
        changeRowsPerPage(parseInt(pageCount, 10));
        setPage(pageNumber);
        onTableAction(payload({ pageNumber, pageSize: pageCount, values: filter, ...formikProps.values }));
    };

    const onChangePage = ({ params: [pageNumber], formikProps }) => {
        setPage(pageNumber);
        onTableAction(
            payload({
                pageNumber,
                pageSize: rowsPerPage,
                values: filter,
                ...formikProps.values,
                ...apiFixedParam
            })
        );
    };

    const onFormSubmit = (values) => {
        setPage(0);
        const trimmedValues = {};
        const keys = Object.keys(values);
        keys.forEach((key) => {
            if (typeof values[key] === 'string') {
                trimmedValues[key] = values[key].trim();
            } else {
                trimmedValues[key] = values[key];
            }
        });
        onTableAction(
            payload({
                pageNumber: 0,
                pageSize: rowsPerPage,
                values: trimmedValues,
                ...apiFixedParam
            })
        );
    };

    const onRowClick =
        (form) =>
        (event, id, index, { row }) => {
            onRowPress({ row, data, form, id, index, filter });
        };

    const afterValidation =
        (formikProps, callback) =>
        (...params) => {
            if (!formikProps.isValid) {
                // showToast('Check your filter', { variant: 'error' });
            } else {
                callback({ params, formikProps });
            }
        };

    return (
        <>
            <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={onFormSubmit} enableReinitialize={true}>
                {(formikProps) => {
                    return (
                        <FilterContext.Provider value={{ filter, setCurrentFilter, theme }}>
                        <>
                            {filterRequired && filterType === 'OUT_TABLE' && (
                                <Accordion
                                    disableGutters
                                    elevation={1}
                                    sx={{
                                        '&:before': {
                                            display: 'none'
                                        },
                                        width: '100%',
                                        padding: '10px'
                                    }}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        sx={{ padding: '5px', width: '100%' }}
                                    >
                                        <Typography variant="h5">Ticket Summary</Typography>
                                    </AccordionSummary>
                                    <FormRender
                                        handleSubmit={formikProps.handleSubmit}
                                        handleReset={formikProps.handleReset}
                                        formValues={formikProps.values}
                                        setFieldValue={formikProps.setFieldValue}
                                        page={page}
                                        pageSize={rowsPerPage}
                                    />
                                </Accordion>
                            )}
                            <Paper className={classes.paper} elevation={1}>
                                <MaterialTableInner
                                    key={`refreshTable${rowsPerPage}`}
                                    allSelector={allSelector}
                                    data={data}
                                    errorKey={errorKey}
                                    columns={columns}
                                    rowsPerPageOptions={[5, 10, 25, 50]}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={afterValidation(formikProps, onChangePage)}
                                    onChangeRowsPerPage={afterValidation(formikProps, onChangeRowsPerPage)}
                                    loading={loading}
                                    count={totalCount}
                                    keyExtractor={keyExtractor}
                                    onRowClick={onRowClick(formikProps.values)}
                                    variant={variant}
                                    expandableRender={expandableRender}
                                    option={tableOptions}
                                    rowSelectionConfig={rowSelectionConfig}
                                    filter={filter}
                                    seprateCheckBoxRow={seprateCheckBoxRow}
                                    seprateCheckBoxOnClick={seprateCheckBoxOnClick}
                                    seprateRowOnClick={seprateRowOnClick}
                                    actions={actions}
                                    onActionClick={onActionClick}
                                    filterRequired={filterRequired}
                                    FormRender={FormRender}
                                    formikProps={formikProps}
                                    pageSize={rowsPerPage}
                                    filterType={filterType}
                                    {...restProps}
                                />
                            </Paper>
                            </>
                        </FilterContext.Provider>
                    );
                }}
            </Formik>
        </>
    );
};

MaterialTable.propTypes = propTypes;
MaterialTable.defaultProps = defaultProps;

export default {
    component: MaterialTable,
    context: FilterContext
};
