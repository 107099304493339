import { createSlice } from "@reduxjs/toolkit";


interface ClientSpecificSpecification {
  1: { id: string; value: string };
  2: { min: string; max: string };
}

interface apiClientSpecific {
  specification: any;
}

interface InterestStructure {
  startDay: number | null | string;
  endDay: number | null;
  interest: number | null;
}

interface categoryType {
  id: string;
  value: string;
}

interface preMatureStructure {
  indexOnEdit: number | null;
  startDay: number | null;
  endDay: number | null;
  interest: number | null;
}

/////////// view producttt




interface ProductMaster {
  categoryType: categoryType;
  productCode: number | null;
  productName: string;
  tenure: number | null;
  minPrincipleAmount: number | null;
  maxPrincipleAmount: number | null;
  returnOnInvestment: string;
  status: categoryType;
  effectiveDate: string;
  recurringAmount: number | null;
  // monthlyMISAmount: number | null;
  processingFee: number | null;
  serviceTax: number | null;
  maxCashLimit: number | null;
  consultationCharge: number | null;
  openingDate: string;
  lockInPeriod: number | null;
  lockInPenalty: number | null;
  description: string;
  isBondRequired: boolean | null;
  isPassbookRequired: boolean | null;
  isMonthly: boolean | null;
  isMobileDeposit: boolean | null;
  isAmountMultiple: boolean | null;
  isAmountMultipleValue: number | null;
  commissionRate: number | null;
  growthPoint: number | null;
//   loan: LoanDetails;
  isLoanAvailable: boolean | null;
  maxLoanPercentage: number | null;
  maxLoanAmountPercentageOnTotalReturn: number | null;
  roi: number | null;
  minWaitingPeriod: number | null;
  isProductSpecific: boolean;
  specificClientRequirements: ClientSpecificSpecification[];
  apiResponseClientSpecific: apiClientSpecific;
  onChangeClientSpecific: apiClientSpecific;
  interestStructure: InterestStructure[];

  preMatureStructure: preMatureStructure;

  validationsError: any;

  /////// view producttt reducsfgdfs

  productId: number | null;
  filterProductName: string;
  filterCategoryType: categoryType;
  isProductViewMode: boolean;
  isProductEditMode: boolean;

}

const initialState: ProductMaster = {
  categoryType: { id: "", value: "" },
  productCode: null,
  productName: "",
  tenure: null,
  minPrincipleAmount: null,
  maxPrincipleAmount: null,
  returnOnInvestment: "",
  status: { id: "", value: "" },
  effectiveDate: "",
  recurringAmount: null,
  // monthlyMISAmount: null,
  processingFee: null,
  serviceTax: null,
  maxCashLimit: null,
  consultationCharge: null,
  openingDate: "",
  lockInPeriod: null,
  lockInPenalty: null,
  description: "",
  isBondRequired: null,
  isPassbookRequired: null,
  isMonthly: null,
  isMobileDeposit: null,
  isAmountMultiple: false,
  isAmountMultipleValue: null,
  commissionRate: null,
  growthPoint: null,
  isLoanAvailable: false,
  maxLoanPercentage: null,
  maxLoanAmountPercentageOnTotalReturn: null,
  minWaitingPeriod: null,
  roi: null,
//   loan: {
//   },
  isProductSpecific: false,
  specificClientRequirements:[],
  interestStructure: [
    { startDay: "Lock-in Period", endDay: null, interest: null },
  ],

  //////////////////////////////////////

  preMatureStructure: {
    indexOnEdit: null,
    startDay: null,
    endDay: null,
    interest: null,
  },

  apiResponseClientSpecific: {
    specification: [],
  },

  onChangeClientSpecific: {
    specification: {},
  },
  validationsError: null,

  // view mameber reducerssss

  productId: null,
  filterProductName: "",
  filterCategoryType: { id: "", value: "" },

  isProductViewMode: false,
  isProductEditMode: true,

};

const productMaster = createSlice({
  name: "ProductMaster",
  initialState,
  reducers: {
    setCategoryType(state, actions) {
        
      return {
        ...state,
        categoryType: actions.payload,
      };
    },

    setStatusType(state, actions) {
      return {
        ...state,
        status: actions.payload,
      };
    },

    setProductDetails(state, actions) {
      const { name, value } = actions.payload;
      return {
        ...state,
        [name]: value,
      };
    },

    setLockInPeriod(state, action) {
      const { name, value } = action.payload;
      return {
        ...state,
        [name]: value,
        interestStructure: [
          { startDay: "Lock-in Period", endDay: value, interest: null },
        ],
      };
    },

    setisLoanAvailable(state, actions){
        return{
            ...state,
            isLoanAvailable: actions.payload
        }
    },

    setLoanDetails(state, actions) {
      const {name, value} = actions.payload
      return {
        ...state,
        // loan: {
        //   ...state.loan,
          [name]: value
        //   ...actions.payload,
        // },
      };
    },

    setProductCheckBox(state, actions) {
      return {
        ...state,
        ...actions.payload,
      };
    },

    setComissionGroth(state, actions) {
      return {
        ...state,
        ...actions.payload,
      };
    },

    setPreMatureStructure(state, actions) {
      const { name, value } = actions.payload;
      return {
        ...state,
        preMatureStructure: {
          ...state.preMatureStructure,
          [name]: value,
        },
      };
    },

    editPreMatureStructure(state, actions): any {
      const index = actions.payload;

      if (index >= 0 && index < state.interestStructure.length) {
        const selectedStructure = state.interestStructure[index];
        return {
          ...state,
          preMatureStructure: {
            ...selectedStructure,
            indexOnEdit: index,
          },
        };
      }

      return state;
    },

    deletePreMatureStructure(state, actions) {
      const index = actions.payload;

      const updatedInterestStructure = [
        ...state.interestStructure.slice(0, index), // basically index se phle jo jo element h array m we can get them here
        ...state.interestStructure.slice(index + 1), // and index k baad k from here and update the array
      ];

      return {
        ...state,
        interestStructure: updatedInterestStructure,
      };
    },

    updatePrematureInterestStructure(state) {
      //on click on Add Button this function is called and update the details on the main payload array
      const index = state.preMatureStructure.indexOnEdit;

      if (
        index !== null &&
        index >= 0 &&
        index < state.interestStructure.length
      ) {
        const updatedInterestStructure = state.interestStructure.map(
          (item, i) => (i === index ? { ...state.preMatureStructure } : item)
        );
        return {
          ...state,
          interestStructure: updatedInterestStructure,
          preMatureStructure: {
            ...state.preMatureStructure,
            endDay: 0,
            interest: 0,
            indexOnEdit: null,
          }, // Reset fields
        };
      } else {
        return {
          ...state,
          interestStructure: [
            ...state.interestStructure,
            { ...state.preMatureStructure, indexOnEdit: null },
          ],
          preMatureStructure: {
            ...state.preMatureStructure,
            endDay: 0,
            interest: 0,
            indexOnEdit: null,
          }, // Reset fields
        };
      }
    },

    isProductSpecificClient(state, actions){
        return {
            ...state,
            isProductSpecific: actions.payload
        }
    },

    setIsClientSpecific(state, actions) {
        return {
            ...state,
            apiResponseClientSpecific: {
                ...state.apiResponseClientSpecific,
                specification: actions.payload,
            }
        };
    },

    setIsClientSpecificData(state:any, actions) {
        const { value, fieldId } = actions.payload;

        return {
            ...state,
            onChangeClientSpecific: {
                ...state.onChangeClientSpecific,
                specification: {
                    ...state.onChangeClientSpecific.specification,
                    [fieldId]: {
                        ...(state.onChangeClientSpecific.specification[fieldId] || {}),
                        ...value
                    }
                }
            }
        };
    },

    addToSpecificCLientArray(state) {
        const specificationData = state.onChangeClientSpecific.specification;
        return {
            ...state,
            specificClientRequirements: [
                ...state.specificClientRequirements,
                specificationData,
            ]
        };
    },

    clearSpecificClientFields(state) {
      return {
          ...state,
          onChangeClientSpecific: {
              ...state.onChangeClientSpecific,
              specification: {}
          }
      };
    },

    deleteSpecificClientRequirement(state, action) {
      return {
        ...state,
        specificClientRequirements: state.specificClientRequirements.filter((_, index) => index !== action.payload),
      };
    },
    

    saveErrors(state, actions) {
      return {
        ...state,
        validationsError: {
          ...actions.payload,
        },
      };
    },

    clearErrors: (state, action) => {
      delete state.validationsError?.[action.payload?.name];
    },


    // view mameber reducerssss functionsssssssssssssssssssssssss

    filterViewProduct(state, actions){
      const {name, value} = actions.payload
      return{
        ...state,
        [name]: value
      }
    },

    setFilterCategoryType(state, actions){

      return {
        ...state,
        filterCategoryType: actions.payload,
      };
    },

    viewProductDataUpdate(state, action){
      return{
        ...state,
        ...action.payload,
      }
    },

    setisProductViewMode: (state, action) => {
      return {
        ...state,
        isProductViewMode: action.payload
      }
    },

    setisProductEditMode: (state, action) => {
      return {
        ...state,
        isProductEditMode: action.payload
      }
    },

    clearOnAddNewProduct: (state) =>{
      return{
        ...state,
        ...initialState,
      }
    }

  },
});

export default productMaster.reducer;

export const {
  setProductDetails,
  setLockInPeriod,
  setCategoryType,
  setStatusType,
  setLoanDetails,
  setisLoanAvailable,
  setProductCheckBox,
  setComissionGroth,
  setPreMatureStructure,
  updatePrematureInterestStructure,
  editPreMatureStructure,
  deletePreMatureStructure,
  isProductSpecificClient,
  setIsClientSpecific,
  setIsClientSpecificData,
  addToSpecificCLientArray,
  clearSpecificClientFields,
  deleteSpecificClientRequirement,
  saveErrors,
  clearErrors,

  /////////// view Product

  filterViewProduct,
  setFilterCategoryType,
  viewProductDataUpdate,
  setisProductViewMode,
  setisProductEditMode,
  clearOnAddNewProduct,

} = productMaster.actions;
