import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import TextFieldBase from "components/TextField";
import { useDispatch, useSelector } from "react-redux";

import { clearErrors, setWitnessGurantorRequests } from "../../reducers";
import SearchableSelectBase from "../../../../components/SearchableSelect/index";
import { MEMBER_DETAILS_BY_ID } from "store/types";
import WitnessCheckBox from "./WitnessGurantorCheckBox";
import Ujala from "services/api/ujala";
import ShowErrorMessage from "containers/members/showErrorMessage";

interface NomineeChangeDetails {
  name: string;
  value: string;
  index?: number;
  type?: any;
}
interface WitnessProps {
  witness: {
    memberID: string;
    fullName: string;
    mobileNo: string;
    name: string;
  };
  errors?: string;
  index: number;
  type: string;
}

const WitnessItem: React.FC<WitnessProps> = ({
  witness,
  errors,
  index,
  type,
}) => {
  const dispatch = useDispatch();
  const addNewMember = useSelector((state: any) => state.addNewMember);
  const [params, setParamss] = useState("");
  const [checked, setChecked] = React.useState<boolean>(false);

  const handleInputChnage = (
    value: string,
    name: any,
    index: any,
    type: any
  ) => {
    if (!name) return;
    if (name === "mobileNo" && value?.length > 10) return;
    dispatch(setWitnessGurantorRequests({ name, value, index, type }));
    dispatch(clearErrors({ name: `witness[${index}].${name}` }));
  };

  const onTextInputChange = (value: any) => {
    setParamss(value);
  };

  const onSearchableSelect = (selected: any, index: any, label?: string) => {
    dispatch(
      setWitnessGurantorRequests({
        name: "member",
        value: selected,
        index,
        type,
      })
    );

    const payload = {
      memberId: checked ? selected?.id : null,
      index: index,
      label,
    };

    if (selected?.id) {
      dispatch({
        type: MEMBER_DETAILS_BY_ID,
        payload: payload,
      });
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(!checked);
  };

  useEffect(() => {
    if (!checked) {
      setParamss("");
    }
  }, [checked, params]);

  useEffect(()=>{
    if(addNewMember[type][index].member){
      setChecked(true);
    }
  },[addNewMember[type][index].member])

  return (
    <>
      <React.Fragment key={index}>
        <Grid item xs={12} sm={4} md={3} lg={2}>
          <WitnessCheckBox
            label={"Existing Member"}
            onChange={handleChange}
            index={index}
            checked={checked}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={3} lg={2}>
          <SearchableSelectBase
            value={addNewMember[type][index]?.member}
            disabled={!checked}
            labelName={"referalMemberId"}
            onTextInputChange={onTextInputChange}
            onChangeSelect={(value) => onSearchableSelect(value, index, type)}
            placeHolder={"MemberId"}
            params={{ memberId: checked ? params : "" }}
            callApiOnFirstRender={true}
            api={!checked ? Ujala.getMemberList : () => {}}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={3} lg={3}>
          <TextFieldBase
            disabled={checked}
            key={index}
            name="fullName"
            placeHolder={"Full Name"}
            labelName={"Full Name"}
            onChange={(value, name) =>
              handleInputChnage(value, name, index, type)
            }
            value={addNewMember[type][index]?.fullName}
            showError={addNewMember.formErrors?.hasOwnProperty(
              `witness[${index}].fullName`
            )}
          />
          <ShowErrorMessage
            message={addNewMember.formErrors?.[`witness[${index}].fullName`]}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={3} lg={2}>
          <TextFieldBase
            disabled={checked}
            type={"number"}
            key={index}
            name="mobileNo"
            placeHolder={"Contact Number"}
            labelName={"Contact Number"}
            onChange={(value, name) =>
              handleInputChnage(value, name, index, type)
            }
            value={addNewMember[type][index]?.mobileNo}
            showError={addNewMember.formErrors?.hasOwnProperty(
              `witness[${index}].mobileNo`
            )}
          />
          <ShowErrorMessage
            message={addNewMember.formErrors?.[`witness[${index}].mobileNo`]}
          />
        </Grid>
        <Grid item xs={12}></Grid>
      </React.Fragment>
    </>
  );
};

export default WitnessItem;
