import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  labelContainer: {
    display: "flex",
    alignItems: "center",
    height: "20px",
    padding: "4px 12px",
    gap: "10px",
    borderRadius: "4px",
    top: "-10px",
    position: "absolute",
    left: "15px",
    backgroundColor: "#1F7A8C",
    color: "#fff",
    marginLeft: '10px',
    fontSize: '10px'
  },
});

interface CustomBoxProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  label?: string | React.ReactNode;
  disabled?: boolean
}

const CustomBox: React.FC<CustomBoxProps> = ({
  children,
  style,
  className,
  label,
  disabled
}) => {
  const classes = useStyles();
  return (
    <Box
      className={className}
      component={Paper}
      p={3}
      sx={{
        position: "relative",
        borderRadius: "6px",
        marginBottom: 1,
        boxShadow: "0px 4px 16px 0px #7C808126 inset",
        border: "1px solid #E3E3E3",
        ...(disabled && { pointerEvents: 'none', opacity: 0.7 }),
        ...style,
      }}
    >
      {label && <Box className={classes.labelContainer}>{label}</Box>}
      {children}
    </Box>
  );
};

export default CustomBox;
