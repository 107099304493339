import React from "react";
import CustomBox from "../../../../components/Box/CustomBox";
import { Grid } from "@mui/material";
import { SPACING } from "containers/members/constant";
import { useDispatch, useSelector } from "react-redux";
import WitnessGrantorItem from "./WitnessGurantorItem";

interface WitnessGurantorProps {
  type: string;
}

function WitnessGurantorComponent({ type }: WitnessGurantorProps) {
  const addNewMember = useSelector((state: any) => state.addNewMember);

  return (
    <div>
      <CustomBox style={{}} label={type}>
        <Grid container spacing={SPACING}>
          {addNewMember[type].map((item: any, index: any) => (
            <WitnessGrantorItem witness={item} index={index} type={type} />
          ))}
        </Grid>
      </CustomBox>
    </div>
  );
}

export default WitnessGurantorComponent;
