import { createSlice } from '@reduxjs/toolkit';
interface ApplicationStructure {
  pinCodeFilters :{},
  pinCodeDetails: {},
  activeBtnState: number;
  ifscFilter: {},
  ifscDetails: {},
  districtFilter: {},
  districtDetails: {}
}
const initialState: ApplicationStructure = {
  pinCodeFilters: {
    pinCode: '',
    districtId: '',
    state: '',
  },
  pinCodeDetails: {
    pinCode: '',
    district: '',
    status: {id: '', value: ''},
    officeName: '',
    taluka: '',
    officeType: '',
    longitude: '',
    latitude: '',
    division: '',
    regionName: '',
    circleName: '',
  },
  ifscFilter:{
    ifscCode: '',
    bankName: '',
    city: ''
  },
  ifscDetails:{
    bankIfscCode: '',
    bankName: '',
    branch: '',
    city: '',
    mobileNo: '',
    state: '',
    district: '',
    micr: '',
    pincode: '',
    address: ''
  },
  districtFilter :{
    districtId: '',
    districtName: '',
  },
  districtDetails: {
    state: '',
    districtId: '',
    DistrictName: '',
    status: {id: '', value: ''},
  },

 activeBtnState: 1,

};
const governmentStructure = createSlice({
  name: 'governmentStructure',
  initialState,
  reducers: {
    setOnChangeValues: (state: any, action) => {
      console.log(action.payload, '7777777777787878888888');
      const { name, value, key } = action.payload; 
      if (key) {
          return {
              ...state,
              [key]: { 
                  ...state[key],
                  [name]: value,
              }
          };
      } else {
          return {
              ...state,
              [name]: value,
          };
      }
    },
    setActiveBtnState  (state, action)  {
      console.log(action.payload, '(((((((((((((((((((((');
       state.activeBtnState = action.payload
    }
  },
});
export const { 
  setOnChangeValues,
  setActiveBtnState
 } = governmentStructure.actions;

export default governmentStructure.reducer;