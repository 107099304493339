import { calculateAge } from "utils/calculateAge";
import * as Yup from "yup";
// import { MARITAL_STATUS } from '../generalDetails/constant';
const phoneRegExp = /^[5-9]\d{9}$/;
const aadhaarRegExp = /^\d{4}\d{4}\d{4}$/;
const panRegExp = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
const pinCodeRegExp = /^\d{6}$/;
const bankAccountRegExp = /^\d{10,25}$/;
const ifscRegExp = /^[A-Z]{4}0[A-Z0-9]{6}$/;

export const personalDetailsValidationSchema = Yup.object().shape({
  aadhaarNo: Yup.string()
  .matches(aadhaarRegExp, "Invalid Aadhar number")
  .required("Aadhaar number is required"),
  salutation: Yup.string().required("Salutation is required"),
  fullName: Yup.string().typeError("Invalid name").max(50,"Name cannot exceed 40 characters ").required("Full Name is required"),
  mobileNumber: Yup.string().matches(phoneRegExp, "Invalid Mobile Number").required("Mobile number is required"),
  alternateMobileNumber: Yup.string().matches(phoneRegExp, "Invalid Mobile Number"),
  dob: Yup.date().typeError("DOB is required").required("DOB is required"),
  joiningDate: Yup.string().typeError("Joining date is required").required("Joining date is required"),

  panNo: Yup.string().typeError("Pan number is required")
    .matches(panRegExp, "PAN number must be in the format ABCDE1234F")
    .required("PAN number is required"),
  emailId: Yup.string().typeError("Email ID is required")
    .email("Invalid email address")
    .required("Email is required"),

  bloodGroup: Yup.string().required("Blood group is required"),
  gender: Yup.string().typeError("Invalid gender").required("Gender is required"),
  maritalStatus: Yup.string().required("Marital Status is required"),
  highestQualification: Yup.string().typeError("Qualification is required").required("Qualification is required"),
  occupationType: Yup.string().typeError("Occupation is required").required("Occupation is required"),

  // AADHAR ADDRESS VALIDATION
  aadhaarAddress: Yup.object().shape({
    address: Yup.string().required('Adhar address is required'),
    pincode: Yup.string().matches(pinCodeRegExp,"Invalid Pincode").required('Adhar pincode is required'),
    city: Yup.string().required('Adhar city is required'),
    country: Yup.string().default('India').nullable(),
    state: Yup.string().required('Adhar state is required'),
  }),
  // CURRENT ADDRESS VALIDATION
  currentAddress: Yup.object().shape({
    address: Yup.string().required('Current address is required'),
    pincode: Yup.string().matches(pinCodeRegExp,"Invalid Pincode").required('Current pincode is required'),
    city: Yup.string().required('Current city is required'),
    country: Yup.string().default('India').nullable(),
    state: Yup.string().required('Current state is required'),
  }),

  // BANK DETAILS VALIDATION
  bankDetails: Yup.object().shape({
    accountNumber: Yup.string().typeError("Account number is required").max(40,"Account number cannot exceed 40 characters").required('Account number is required'),
    accountHolderName: Yup.string().typeError("Account holder name is required").min(3,"Too short").max(40,"Name cannot exceed 40 characters").required('Account holder name is required'),
    bankName: Yup.string().typeError("Bank name is required'").required('Bank name is required'),
    branchName: Yup.string().typeError("Branch is required").required('Branch is required'),
    ifsc: Yup.string().typeError("IFSC code is required").matches(ifscRegExp,"Invalid IFSC Code").required('IFSC code is required'),
  }),

  childCount: Yup.string().when('maritalStatus', {
      is: (value:string) => ["Married", "Divorced", "Separated"].includes(value),
      then: Yup.string().required('Number of children is required'),
      otherwise: Yup.string().nullable()
    }),
    dom: Yup.string().when('maritalStatus', {
      is: (value:string) => ["Married", "Divorced", "Separated"].includes(value),
      then: Yup.string().required('DOM is required'),
      otherwise: Yup.string().nullable()
    }),
  permanentAddress: Yup.string(),
  permanentCity: Yup.string(),
  permanentState: Yup.string(),
  permanentPincode: Yup.string().matches(
    pinCodeRegExp,
    "PIN code must be exactly 6 digits"
  ),
  familyIncome: Yup.number().transform((val, value) => {
    return value === '' ? null : val;  // this return will Convert empty string into null or 0
  }).nullable().max(250000000, 'Reached max limit') .required('Family Income is required')

});

// NOMINEE SCHEMA VALIDATION
const nomineeSchema = Yup.object().shape({
  name: Yup.string().required('Nominee name is required'),
  percentage: Yup.number().typeError("Percentage is required") .required('Nominee percentage is required').min(1,"Minimum percentage 1 is required").max(100),
  relation: Yup.string().required('Nominee relation is required'),
  dob: Yup.string().required('Nominee DOB is required'),
  pincode: Yup.string().required('Nominee pincode is required'),
  state: Yup.string().required('Nominee state is required'),
  city: Yup.string().required('Nominee city is required'),
  address: Yup.string().required('Nominee address is required'),

  guardian: Yup.object().when('dob', {
    is: (dob:string) => {
      const age = calculateAge(dob);
      return age < 18;
    },
    then: Yup.object().shape({
      relationShip:Yup.string().required('Relationship is required'),
      name: Yup.string().required('Guardian name is required'),
      address: Yup.string().required('Guardian address is required'),
      state: Yup.string().required('Guardian state is required'),
      contactNo:Yup.string().required("Guardian contact No is required"),
      city: Yup.string().required('Guardian city is required'),
      pincode: Yup.string().required('Guardian pincode is required'),
      dob: Yup.string().required('Guardian DOB is required'),
    }).required('Guardian details are required'),
    otherwise: Yup.object().nullable(),
  }),
  // guardian: Yup.object().shape({
  //   name: Yup.string(),
  //   address: Yup.string(),
  //   state: Yup.string(),
  //   city: Yup.string(),
  //   pincode: Yup.string(),
  //   dob: Yup.string(),
  // }).nullable(),
});

//WITNESS SCHEMA VALIDATION
const witnessSchema = Yup.object().shape({
  memberID: Yup.number().nullable(),
  fullName: Yup.string().required('Full name is required'),
  mobileNo: Yup.string().matches(phoneRegExp, "Invalid Contact Number").required('Contact number is required'),
});

export const bankAndNomineeDetailsValidationSchema = Yup.object().shape({

  // REFERAL VALIDATION
  referral: Yup.object({
    member: Yup.object().required('Member ID is required').nullable(),
    firstName: Yup.string(),
    type: Yup.string().nullable(),
    level: Yup.string().nullable(),
    mobileNo: Yup.string().nullable(),
    branchId: Yup.string().nullable(),
  }),
  totalPercentage: Yup.number()
    .required("Total percentage is required")
    .test('is-100', 'Total percentage must be exactly 100', value => value === 100),
  nominee: Yup.array().of(nomineeSchema).min(1, 'At least one nominee is required'),
  witness: Yup.array().of(witnessSchema).min(1, 'At least one witness is required'),
});


// PAYMENT SCHEMA VALIDATION
export const paymentSchema = Yup.object().shape({
  applicationId: Yup.string().required('Application ID is required'),
  // paymentType: Yup.string().required('Payment type is required'),
  // membershipFee: Yup.number().required('Membership fee is required'),
  // noOfShares: Yup.number().nullable(),
  // totalAmount: Yup.number().required('Total amount is required'),
});
