// third-party
import { combineReducers } from "redux";
import { createReduxHistoryContext } from "redux-first-history";
import { createBrowserHistory } from "history";

// project import

import PromoteMemberMaster from "../../containers/memberMaster/reducers";
import auth from "containers/auth/reducers";
import drawer from "layouts/drawerAndHeader/reducers";
import addNewMember from "../../containers/members/reducers";
import governmentStructure from "containers/structure/master/govStructure/reducers";
import savingAccount from "../../containers/savingAccount/reducers";
import productMaster from "../../containers/productMaster/reducers";

// ==============================|| COMBINE REDUCERS ||============================== //

const {
  createReduxHistory,
  routerMiddleware,
  routerReducer
} = createReduxHistoryContext({ history: createBrowserHistory() });

const reducers = combineReducers({
  router: routerReducer,
  PromoteMemberMaster,
  auth,
  addNewMember,
  drawer,
  governmentStructure,
  savingAccount,
  productMaster,
});

export const rM = routerMiddleware;
export const createRH = createReduxHistory;

export default reducers;
