import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles } from '@mui/styles';
const useStyles = makeStyles({   
  agreeButton: {
      color: '#fff',
      border: '1px solid #5F46D4',
      backgroundColor: '#5F46D4',
  '&:hover': {
  color: '#fff', 
  border: '1px solid #5F46D4',
  backgroundColor: '#5F46D4',
}},
disAgreeButton: {
  color: '#8C8C8C',
  border: '1px solid #8C8C8C',
  // backgroundColor: '#5F46D4',
'&:hover': {
color: '#8C8C8C', 
border: '1px solid #8C8C8C',
// backgroundColor: '#5F46D4',
}},
});

const propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  alertMessage: PropTypes.string,
  alertTitle: PropTypes.string.isRequired,
  acceptButtonLabel: PropTypes.string,
  rejectButtonLabel: PropTypes.string,
  acceptButtonColor: PropTypes.string,
  rejectButtonColor: PropTypes.string,
  handleAgree: PropTypes.func.isRequired,
  handleDisAgree: PropTypes.func.isRequired,
  alertTitle: PropTypes.string,
};

const defaultProps = {
  open: false,
  handleClose: () => {},
  handleDisAgree: () => {},
  handleAgree: () => {},
  acceptButtonLabel: 'Agree',
  rejectButtonLabel: 'Disagree',
  alertTitle: 'Do you want to continue ?',
  alertMessage: '',
};

const PopUpAlertModal = props => {
  const {
    open,
    handleClose,
    handleAgree,
    handleDisAgree,
    alertMessage,
    rejectButtonLabel,
    acceptButtonLabel,
    alertTitle,
  } = props;
  const classes= useStyles();
  return (
  
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{alertTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {alertMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
          size="small"
          className={classes.disAgreeButton} 
          onClick={() => handleDisAgree()}
          >
            {rejectButtonLabel}
          </Button>
          <Button 
          size="small"
          className={classes.agreeButton}
          onClick={() => handleAgree()} 
          >
            {acceptButtonLabel}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

PopUpAlertModal.propTypes = propTypes;
PopUpAlertModal.defaultProps = defaultProps;

export default PopUpAlertModal;
