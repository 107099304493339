import { createSlice } from "@reduxjs/toolkit";
// import useCredentials from "hooks/useCredentials";

const initialState = {
  // form: {},
  loading: false,
  loginVerified: false,
  apiResponse: undefined,
  error: undefined,
  data: undefined,
  isPswdEditable: false,
  user: {},
  useIdPassword: {userId: "", password: "" },
  verifyOtpApiHit: false,
  jwtToken: null
};

const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserCredential(state, action){
      return {
        ...state,
        useIdPassword: {
          userId: action.payload.userId,
          password: action.payload.password
        }

      }
    },
    otpTrigger(state, action) {
      return {
        ...state,
        loading: true,
        // form: action.payload
      };
    },
    verifyOtpTrigger(state, action) {
      return {
        ...state,
        loading: false,
        verifyOtpApiHit: true,
      };
    },
    resetPasswordTrigger(state, action) {
      return {
        ...state,
        loading: true,
        isPswdEditable: false,
      };
    },
    // verifyResetPasswordTrigger(state, action) {
    //   return {
    //     ...state,
    //     loading: true,
    //     isPswdEditable: true,
    //   };
    // },
    loginVerification(state, action) {
      return {
        ...state,
        loading: false,
        loginVerified: true,
      };
    },
    loginSuccess(state, action) {
      return {
        ...state,
        apiResponse: action.payload,
        loading: false,
      };
    },
    loginFail(state, action) {
      return {
        ...state,
        error: action.payload.errorObject,
        loading: false,
      };
    },
    logout() {
      return {
        ...initialState,
      };
    },
    saveUser(state, action) {
      return {
        ...state,
        user: action.payload,
      };
    },

    pswdEditable(state, action) {
      return {
        ...state,
        isPswdEditable: action.payload,
      };
    },
    saveJwtToken(state, action) {
      return {
        ...state,
        jwtToken: action.payload
      };
    },
  },
});

export default auth.reducer;

export const {
  otpTrigger,
  verifyOtpTrigger,
  resetPasswordTrigger,
  // verifyResetPasswordTrigger,
  loginVerification,
  loginSuccess,
  loginFail,
  logout,
  saveUser,
  setUserCredential,
  pswdEditable,
  saveJwtToken
} = auth.actions;
