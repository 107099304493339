import * as React from "react";
import { Navigate } from "react-router-dom";
import useCredentials from "hooks/useCredentials";
import { useSelector } from "react-redux";
import { ROUTES } from "./constant";

export const ProtectedRoute = ({ children }) => {
  const accessToken = useCredentials();
  if (!accessToken) {
    // NAVIGATE TO LOGIN PAGE BASED ON CONDITION
    return <Navigate to={ROUTES.LOGIN} />;
  }
  return children;
};
