import React, {  lazy } from 'react';

// project import
import Loadable from 'components/Loadable';


const AuthLogin = Loadable(lazy(() => import('containers/auth/AuthLogin')));
const Forgotpassword = Loadable(lazy(() => import('containers/auth/Forgotpassword')));
const VerifyOTP = Loadable(lazy(() => import('containers/auth/VerifyOTP')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
    path: '/',
    // element: <MinimalLayout />,
    children: [
        {
            path: 'login',
            element: <AuthLogin />
        },
        {
            path: 'forgotpassword',
            element: <Forgotpassword />
        },
        {
            path: 'verifyotp',
            element: <VerifyOTP />
        },
    ]
};

export default LoginRoutes;
