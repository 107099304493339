import React from "react";

import { Grid } from "@mui/material";
import TextFieldBase from "components/TextField";
import { useDispatch, useSelector } from "react-redux";

import { clearErrors, setAddressByType } from "containers/members/reducers";
import { ADDRESS_BY_PINCODE } from "store/types";
import { SPACING } from "containers/members/constant";
import ShowErrorMessage from "containers/members/showErrorMessage";

interface AddressProps {
  type: string;
  label: string;
}

const Address: React.FC<AddressProps> = ({ type, label }) => {
  const dispatch = useDispatch();
  const addressData = useSelector((state: any) => state.addNewMember);

  const handleChange = (value: string, name: any) => {
    dispatch(setAddressByType({ value, name, type }));

    if (name == "pincode" && value.length == 6) {
      dispatch({
        type: ADDRESS_BY_PINCODE,
        payload: { pincode: value, addType: type },
      });
    }
    dispatch(clearErrors({ name: `${type}.${name}` }));
  };

  return (
    <Grid container spacing={SPACING}>
      <Grid item xs={12} sm={4} md={3} lg={4}>
        <TextFieldBase
         isMandatory={true}
          name="address"
          placeHolder={label}
          labelName={label}
          onChange={handleChange}
          value={addressData[type].address}
          showError={
            addressData?.formErrors &&
            addressData?.formErrors.hasOwnProperty(`${type}.address`)
          }
        />
        {addressData?.formErrors &&
          addressData?.formErrors.hasOwnProperty(`${type}.address`) && (
            <ShowErrorMessage
              message={addressData?.formErrors?.[`${type}.address`]}
            />
          )}
      </Grid>
      <Grid item xs={12} sm={4} md={3} lg={2}>
        <TextFieldBase
          disabled={true}
          name="country"
          placeHolder={"Country"}
          labelName={"Country"}
          value={addressData[type].country}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={3} lg={2}>
        <TextFieldBase
         isMandatory={true}
          type="number"
          name="pincode"
          placeHolder={"Pincode"}
          labelName={"Pincode"}
          onChange={handleChange}
          value={addressData[type].pincode}
        />
        <ShowErrorMessage
          message={addressData?.formErrors?.[`${type}.pincode`]}
        />
      </Grid>

      <Grid item xs={12} sm={4} md={3} lg={2}>
        <TextFieldBase
         isMandatory={true}
          name="state"
          placeHolder={"State"}
          labelName={"State"}
          onChange={handleChange}
          value={addressData[type].state}
        />
        <ShowErrorMessage
          message={addressData?.formErrors?.[`${type}.state`]}
        />
      </Grid>

      <Grid item xs={12} sm={4} md={3} lg={2}>
        <TextFieldBase
         isMandatory={true}
          name="city"
          placeHolder={"City"}
          labelName={"City"}
          onChange={handleChange}
          value={addressData[type].city}
        />
        <ShowErrorMessage message={addressData?.formErrors?.[`${type}.city`]} />
      </Grid>
    </Grid>
  );
};

export default Address;
