export const SEND_OTP = "SEND_OTP";
export const VERIFY_OTP = "VERIFY_OTP";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const FETCH_MEMBER_PROMOTE_Details = "FETCH_MEMBER_PROMOTE_Details";
export const SUBMIT_PROMOTE_MEMBER_DETAILS = "SUBMIT_PROMOTE_MEMBER_DETAILS";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const VERIFY_RESET_PASSWORD = "VERIFY_RESET_PASSWORD";
export const RESET_SEND_RESET_PASSWORD_FLAG = "RESET_SEND_RESET_PASSWORD_FLAG";
export const SIDEMENU_REQUEST = "SIDEMENU_REQUEST";

//ADD MEMBER
export const ADD_MEMBER = "ADD_MEMBER";
export const REGISTER_MEMBER = "REGISTER_MEMBER";
export const FINALISE_APPLICATION = "FINALISE_APPLICATION";
export const EDIT_MEMBER = "EDIT_MEMBER";
export const SET_FORM_DATA = "SET_FORM_DATA";
export const ADDRESS_BY_PINCODE = "ADDRESS_BY_PINCODE";
export const BANK_DETAILS_BY_IFSC = "BANK_DETAILS_BY_IFSC";
export const MEMBER_LIST = "MEMBER_LIST";
export const MEMBER_DETAILS_BY_ID = "MEMBER_DETAILS_BY_ID";
export const AADHAR_VALIDATION_AND_SET_ADDRESS =
  "AADHAR_VALIDATION_AND_SET_ADDRESS";
export const CHANGE_DOC_STATUS = "CHANGE_DOC_STATUS";

export const WITNESS = "witness";
export const GURANTOR = "gurantor";

export const MEMBER_DETAILS_BY_APPLICATION_ID =
  "MEMBER_DETAILS_BY_APPLICATION_ID";

// PAYMENT_METHOD
export const GET_PAYMENT_DETAILS = "GET_PAYMENT_DETAILS";
export const PAYMENT_METHOD = "PAYMENT_METHOD";

// DOCUMENTS
export const GET_DOCUMENTS_DETAILS = "GET_DOCUMENTS_DETAILS";
export const UPLOAD_DOCUMENT = "UPLOAD_DOCUMENT";
export const UPLOAD_DOCUMENT_URL = "UPLOAD_DOCUMENT_URL";

// SAVING_ACCOUNT

export const SAVING_ACCOUNT_FILTER = "SAVING_ACCOUNT_FILTER";
export const GET_PRODUCT_CATEGORY_TYPES = "GET_PRODUCT_CATEGORY_TYPES";
export const GET_PRODUCT_NAME = "GET_PRODUCT_NAME";
export const GET_PRODUCT_DETAILS = "GET_PRODUCT_DETAILS";
export const GET_COMMISSION_AGENT_DETAILS = "GET_COMMISSION_AGENT_DETAILS";

export const CREATE_SAVING_ACCOUNT = "CREATE_SAVING_ACCOUNT";
export const VIEW_SAVING_ACCOUNT = "VIEW_SAVING_ACCOUNT";
export const EDIT_SAVING_ACCOUNT = "EDIT_SAVING_ACCOUNT";

export const GET_INVESTMENT_MASTER_TABLE_DATA =
  "GET_INVESTMENT_MASTER_TABLE_DATA";

export const GET_ENTROLLED_PRODUCT_LIST = "GET_ENTROLLED_PRODUCT_LIST";
export const GET_NOMINEE_DETAILS_BY_MEMBERID_AND_PRODUCTID =
  "GET_NOMINEE_DETAILS_BY_MEMBERID_AND_PRODUCTID";
export const GET_PRODUCT_MEMBER_DETAILS_BY_ID =
  "GET_PRODUCT_MEMBER_DETAILS_BY_ID";

// Product Master
export const ADD_SPECIFICATION = "ADD_SPECIFICATION";
export const GET_PRODUCT_MASTER_DETAILS = "GET_PRODUCT_MASTER_DETAILS";
export const IS_PRODUCT_FOR_SPECIFI_CLIENT = "IS_PRODUCT_FOR_SPECIFI_CLIENT";
export const CREATE_OR_EDIT_PRODUCTMASTER = "CREATE_OR_EDIT_PRODUCTMASTER";
export const VIEW_PRODUCTMASTER = "VIEW_PRODUCTMASTER";
export const GET_PRODUCT_DETAILS_BY_ACCOUNTNUMBER =
  "GET_PRODUCT_DETAILS_BY_ACCOUNTNUMBER";
