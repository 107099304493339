import React from "react";
import Box from "@mui/material/Box";

const BoxBase = (props) => {
  const { show = true, ...restProps } = props;

  if (!show) return null;

  return <Box {...props}>{props.children}</Box>;
};

export default BoxBase;
