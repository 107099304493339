import React from "react";
import Grid2 from '@mui/material/Unstable_Grid2';
import { Typography, Box ,Avatar } from '@mui/material';
import { makeStyles  } from '@mui/styles';
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded';


const useStyles = makeStyles({
  container:{
    flexGrow: 1,
    display: 'flex',
    padding: 'px 4px 0px 4px',
    marginTop: '5px'
  },
  employeeName: {
    font: 'DM Sans',
    fontWeight: 500,
    fontSize: '16px',
    color: '#17181A',
    marginLeft: '8px'
  },
  employeeId: {
    font: 'DM Sans',
    fontWeight: 400,
    fontSize: '12px',
    color: '#2E3033',
    margin: '5px 0px  0px 5px'
  },
  avatarStyle: {
    border: '3px solid #5F46D4',
    backgroundColor: '#fff'
  }
});


const EmployeesDetails = ({data, employeeId,employeeName}) => {
  const classes = useStyles();
  return(
    <Box className={classes.container}>
      <Grid2 container>
       
        <Typography className={classes.employeeName}>
        {data.employeeName || employeeName}
        </Typography>
            <Typography className={classes.employeeId}>
            ({data.employeeId || employeeId})
            </Typography>
            </Grid2>
            {/* </Grid2> */}
    </Box>
  )
};
export default EmployeesDetails;