import React, {useEffect} from "react";
import CustomBox from "../../components/Box/CustomBox";
import { Grid, Select, TextFieldBase, Typography } from "components";
import { PAYMENT_TYPES, SPACING } from "./constant";
import { useSelector } from "react-redux";
import ShowErrorMessage from "./showErrorMessage";
import { Button, Stack, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { dispatch } from "store";
import { setFormData, setPaymentType } from "./reducers";
import { PAYMENT_METHOD, GET_PAYMENT_DETAILS } from "store/types";

interface PaymentTypeProps {
  value: string;
}

const useStyles = makeStyles({
  payNowButton: {
    backgroundColor: "#1F7A8C",
    "&:hover": {
      background: "#1F7A7C",
    },
    color: "#fff",
  },
  nomineeTextLabel: {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "15px",
    textAlign: "left",
    color: "#1F7A8C",
  },
});

function Payment() {
  const classess = useStyles();
  const addNewMember = useSelector((state: any) => state.addNewMember);
  const { paymentType, membershipFee, noOfShares, totalAmount, shareFee, paid } = useSelector((state: any) => state.addNewMember.payment);

  const handleChange = (value: any, name: any) => {
    let paymentDetails = {...addNewMember.payment};
    if(name == 'noOfShares'){
      paymentDetails['totalAmount'] = (Number(value)*shareFee)+membershipFee;
    }
    paymentDetails[name] = value;
    dispatch(setFormData({ name: "payment", value: {...paymentDetails} }));
  };

  const selectPaymentTypeChange = (value: PaymentTypeProps) => {
    dispatch(setPaymentType({ paymentType: value }));
  };

  const handlePayment = () => {
    dispatch({
      type: PAYMENT_METHOD,
      payload: {
        applicationId: addNewMember.applicationId ? addNewMember.applicationId : addNewMember.memberId,
        membershipFee,
        paymentType,
        noOfShares,
        totalAmount,
        shareFee
      },
    });
  };

  useEffect(()=>{
    dispatch({ type: GET_PAYMENT_DETAILS });
  },[])

  return (
    <>
      <Stack spacing={2}>
        <CustomBox style={{}} label={"Payment"}>
          <Grid container spacing={SPACING}>
            <Grid item xs={12} sm={4} md={3} lg={2}>
              <Select
                multi={false}
                name="paymentType"
                labelName={"Payment Type"}
                value={paymentType}
                data={PAYMENT_TYPES}
                onChangeSelect={selectPaymentTypeChange}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={3} lg={2}>
              <TextFieldBase
                disabled
                name="membershipFee"
                placeHolder={"Fees"}
                labelName={"Fees"}
                onChange={handleChange}
                value={membershipFee}

              />

            </Grid>

            <Grid item xs={12} sm={4} md={3} lg={2}>
              <TextFieldBase
                name="noOfShares"
                labelName={`No. Of Shares (${shareFee}/ share)`}
                onChange={handleChange}
                value={noOfShares}
               
              />
            </Grid>

            <Grid item xs={12} sm={4} md={3} lg={2}>
              <TextFieldBase
                disabled
                name="totalAmount"
                placeHolder={"Total Amount"}
                labelName={"Total Amount"}
                onChange={handleChange}
                value={totalAmount}
              />
              
            </Grid>

            {paid != true ?
            <Grid item xs={12} sm={4} md={3} lg={2}>
              <Button
                className={classess.payNowButton}
                onClick={handlePayment}
                variant="contained"
              >
                Pay Now
              </Button>
            </Grid>:<Typography style={{ marginLeft: 20, color: "green", fontSize: 18, fontWeight: "bold", alignSelf: "center", textDecoration: "underline" }}>Success</Typography>}
          </Grid>
        </CustomBox>
      </Stack>
    </>
  );
}
export default Payment;
