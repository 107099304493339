import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = {
  member: null,
  productCategoryValue: "",
  productCategoryId: null,
  productNameValue: null,
  productId: null,
  selectedEntrolledProductId: 0,
  selectedEntrolledProductValue: "",
  ePassbook: "",
  insurable: "Yes",
  productDetail: {
    creationDate: "",
    amount: null,
  },
  commssionAgentForAccount: null,
  selectProductDetails: {
    minPrincipalAmount: null,
    MaxPrincipalAmount: null,
    maxCashDisb: null,
    interest: null,
    isPassbookReq: null,
    isSecured: null,
  },
  commissionAgent: {
    memberId: null,
    name: "",
    branch: "",
    mobileNo: "",
    type: "",
    level: "",
    status: "",
  },
  productMemberDetails: {
    memberId: null,
    name: "",
    branchName: "",
    mobileNo: "",
    type: "",
    level: "",
    status: "",
  },
  selectedOwnProductName: "",

  nomineeDetails: [
    {
      fullName: "",
      nomPercentage: "",
      relation: "",
      dob: "",
      pincode: "",
      state: "",
      city: "",
      address: "",
      guardian: {
        memberId: "",
        name: "",
        address: "",
        state: "",
        city: "",
        pincode: "",
        dob: "",
      },
    },
  ],
  totalPercentage: 0,
  noOfNominees: 1,
  validationError: null,
  isNomineeExit: false,
  isSelfExistingNominee: false,
  isAddNewProduct: false,
  filters: {
    memberId: "",
    name: "",
    mobileNo: "",
    savingAccountNumber: "",
    aadharNumber: "",
  },
  isProductDetailsView: false,
  isProductPageNominee: false,
};

const savingAccount = createSlice({
  name: "savingAccount",
  initialState,
  reducers: {
    setFormData: (state, action) => {
      const { name, value } = action.payload;
      if (name === "productCategotyList") {
        return {
          ...state,
          productCategotyList: value,
        };
      }
      if (name === "productNameList") {
        return {
          ...state,
          productNameList: value,
        };
      }
      return {
        ...state,
        [name]: value,
      };
    },

    setFilterData: (state, action) => {
      const { name, value } = action.payload;
      return {
        ...state,
        filters: {
          ...state.filters,
          [name]: value,
        },
      };
    },

    setSelectProduct: (state, action) => {
      const { name, value } = action.payload;

      return {
        ...state,
        selectProductDetails: {
          ...state.selectProductDetails,
          [name]: value,
        },
      };
    },

    setProductDetails: (state, action) => {
      const { name, value } = action.payload;
      return {
        ...state,
        productDetail: {
          ...state.productDetail,
          // [name]: value,
          [name]: value?.value ? value?.value : value,
        },
      };
    },

    saveErrors: (state, action) => {
      return {
        ...state,
        formErrors: {
          ...action.payload,
        },
      };
    },

    setIntroMember: (state, action) => {
      return {
        ...state,
        referMemberId: action.payload,
      };
    },

    setCommissionAgentData: (state, action) => {
      const {
        commissionAgentId,
        name,
        memberType,
        designationLevel,
        branch,
        mobileNo,
        status,
      } = action.payload;

      return {
        ...state,
        commissionAgent: {
          ...state.commissionAgent,
          memberId: commissionAgentId,
          name: name,
          branch: branch,
          mobileNo: mobileNo,
          level: designationLevel,
          type: memberType,
          status,
        },
      };
    },

    setProductMemberDetailsData: (state, action) => {
      const { firstName, mobileNo, memberType, memberStatus } = action.payload;

      return {
        ...state,
        productMemberDetails: {
          ...state.productMemberDetails,
          name: firstName,
          mobileNo,
          level: memberType,
          status: memberStatus,
        },
      };
    },

    updateCommissionAgentData: (state, action) => {
      const { firstName, mobileNo, memberType, status } = action.payload;
      return {
        ...state,
        commissionAgent: {
          ...state.commissionAgent,
          name: firstName,
          mobileNo,
          level: memberType,
          type: memberType,
          status: status,
        },
      };
    },

    setMemberDetails: (state, action) => {
      const { firstName, mobileNo, memberType, memberStatus } = action.payload;
      return {
        ...state,
        productMemberDetails: {
          ...state.productMemberDetails,
          name: firstName,
          mobileNo,
          level: memberType,
          type: memberType,
          status: memberStatus,
        },
      };
    },
    setNoOfNominees: (state, action) => {
      if (action.payload?.value) {
        return {
          ...state,
          noOfNominees: action.payload?.value,
        };
      }
      return {
        ...state,
        noOfNominees: action.payload?.value,
      };
    },

    // setNomineeDetails: (state, action) => {
    //   const { name, value, index, thirdParam } = action.payload;

    //   const updatedNomineeDetails = Array.from(state.nomineeDetails);
    //   if (thirdParam !== undefined && thirdParam === "guardian") {
    //     updatedNomineeDetails[index] = {
    //       ...updatedNomineeDetails[index],
    //       guardian: {
    //         ...updatedNomineeDetails[index].guardian,
    //         [name]: value?.value ? value.value : value,
    //       },
    //     };
    //   } else {
    //     updatedNomineeDetails[index] = {
    //       ...updatedNomineeDetails[index],
    //       [name]: value?.value ? value.value : value,
    //     };
    //   }

    //   return {
    //     ...state,
    //     nomineeDetails: Array.from(updatedNomineeDetails),
    //   };
    // },

    setNomineeDetails: (state, action) => {
      const { name, value, index, thirdParam } = action.payload;

      console.log("Action Payload:", action.payload); // Debugging

      // Copy the nomineeDetails array to ensure immutability
      const updatedNomineeDetails = [...state.nomineeDetails];

      if (thirdParam !== undefined && thirdParam === "guardian") {
        // Ensure guardian property exists
        // if (!updatedNomineeDetails[index].guardian) {
        //   updatedNomineeDetails[index]?.guardian = {

        //   };
        // }

        updatedNomineeDetails[index] = {
          ...updatedNomineeDetails[index],
          guardian: {
            ...updatedNomineeDetails[index].guardian,
            [name]: value?.value !== undefined ? value.value : value,
          },
        };
      } else {
        updatedNomineeDetails[index] = {
          ...updatedNomineeDetails[index],
          [name]: value?.value !== undefined ? value.value : value,
        };
      }

      console.log("Updated Nominee Details:", updatedNomineeDetails); // Debugging

      // Return the new state
      return {
        ...state,
        nomineeDetails: updatedNomineeDetails,
      };
    },

    clearErrors: (state, action) => {
      delete state.validationError?.[action.payload?.name];
    },

    setTotalNomineePercentage: (state, action) => {
      const totalPercentage = state.nomineeDetails?.reduce((total, request) => {
        return total + (parseInt(request?.nomPercentage) || 0);
      }, 0);
      return {
        ...state,
        totalPercentage,
      };
    },

    updateselectProductDetails: (state, action) => {
      const {
        minPrincipalAmount,
        MaxPrincipalAmount,
        maxCashDisb,
        interest,
        isPassbookReq,
        isSecured,
      } = action.payload;

      return {
        ...state,
        selectProductDetails: {
          ...state.selectProductDetails,
          minPrincipalAmount,
          MaxPrincipalAmount,
          maxCashDisb,
          interest,
          isPassbookReq,
          isSecured,
        },
      };
    },

    updateNomineeDetail: (state, action) => {
      const { nomineeCount } = action.payload;
      if (state.nomineeDetails?.length > nomineeCount) {
        const updatedNominee = [...state.nomineeDetails];
        const updatedNewNominee2 = updatedNominee?.splice(0, nomineeCount);
        return {
          ...state,
          nomineeDetails: updatedNewNominee2,
        };
      } else {
        const nomineeObj = {
          name: "",
          percentage: "",
          relation: "",
          dob: "",
          pincode: "",
          state: "",
          city: "",
          address: "",
          guardian: null,
        };

        const newArray = new Array(nomineeCount - 1)
          .fill(nomineeObj)
          .map((obj) => {
            return {
              ...obj,
            };
          });

        const newNominees = [...state.nomineeDetails, ...newArray];
        return {
          ...state,
          nomineeDetails: newNominees,
        };
      }
    },

    updateGurdianStateAndCity: (state, action) => {
      const { state: gurdianState, city, index } = action?.payload;

      const updatedNomineeDetails = Array.from(state.nomineeDetails);
      updatedNomineeDetails[index] = {
        ...updatedNomineeDetails[index],
        guardian: {
          ...updatedNomineeDetails[index].guardian,
          state: gurdianState,
          city: city,
        },
      };

      return {
        ...state,
        nominee: Array.from(updatedNomineeDetails),
      };
    },

    updateNomineeDetailsWithResponse: (state, action) => {
      const { nomineeList, name } = action.payload;

      if (name === "initializeNominee") {
        return {
          ...state,
          ...state.nomineeDetails,
          nominee: Array.from(initialState.nomineeDetails),
        };
      }

      return {
        ...state,
        ...state.nomineeDetails,
        nomineeDetails: nomineeList,
        noOfNominees: nomineeList?.length,
      };
    },
    setViewProductDetails: (state, action) => {
      const {
        member,
        commissionAgent,
        nomineeDetail,
        productResponse,
        productId,
        principalAmount,
        commissionAgentForAccount,
        memberResponse,
        startDate,
      } = action.payload?.productDetails;

      return {
        ...state,
        member: memberResponse,
        commssionAgentForAccount: commissionAgentForAccount,
        nomineeDetails: nomineeDetail,
        productCategoryId: productResponse?.prodCategoryId,
        productCategoryValue:
          productResponse?.prodCategoryId === 11
            ? "FD"
            : productResponse?.prodCategoryId === 12
            ? "RD"
            : productResponse?.prodCategoryId === 13
            ? "DDS"
            : productResponse?.prodCategoryId === 14
            ? "MIS"
            : "SA",
        productId: productId,
        productDetail: {
          creationDate: startDate,
          amount: principalAmount,
        },
      };
    },

    updateNomineeAddressDetail: (state, action) => {
      const { index, city, state: NomineeState } = action.payload;

      const updatedWitnessRequests = state.nomineeDetails.map((request, i) => {
        if (i === index) {
          return {
            ...request,
            city: city,
            state: NomineeState,
          };
        }
        return request;
      });
      return {
        ...state,
        nomineeDetails: updatedWitnessRequests,
      };
    },
  },
});

export default savingAccount.reducer;

export const {
  setFormData,
  setSelectProduct,
  setProductDetails,
  setCommissionAgentData,
  updateCommissionAgentData,
  setNoOfNominees,
  setNomineeDetails,
  clearErrors,
  setTotalNomineePercentage,
  updateselectProductDetails,
  updateNomineeDetail,
  updateGurdianStateAndCity,
  setFilterData,
  setProductMemberDetailsData,
  setMemberDetails,
  updateNomineeDetailsWithResponse,
  setViewProductDetails,
  updateNomineeAddressDetail,
} = savingAccount.actions;
