import * as _ from 'lodash';
import { defaultErrorMessage } from 'services/apiHandler/handleError';

export const createApiError = (errorObject) => {
    if (errorObject.status) {
        return errorObject;
    }
    return {
        message: errorObject.message,
        // represents a JS error
        status: 720
    };
};

export const isAuthError = (errorObject) => {
    return _.get(errorObject, 'status') === 401;
};

export const uiError = (error) => (defaultMessage) => {
    const errorCodesToPrevent = [701, 702];
    if (error.message && new RegExp('failed to connect', 'i').test(error.message)) {
        return 'Please check your internet connection';
    }

    if (
        error &&
        error.status &&
        error.message &&
        !errorCodesToPrevent.includes(Number(error.status)) &&
        !(defaultErrorMessage === error.message)
    ) {
        return error.message;
    }

    return defaultMessage;
};
