import React, { useCallback, useEffect, useMemo, useState } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import { Typography } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import Palette from "../../themes/palette";
import { styled } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";
import createUseGet from "hooks/createUseGet";
import InputLabel from "@mui/material/InputLabel";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import PropTypes from "prop-types";
import * as _ from "lodash";

const propTypes = {
  showToolTip: PropTypes.bool,
  data: PropTypes.array,
  onChangeSelect: PropTypes.func,
  name: PropTypes.string,
  api: PropTypes.func,
  params: PropTypes.object,
  disabled: PropTypes.bool,
  showError: PropTypes.bool,
  labelName: PropTypes.string.isRequired,
  multi: PropTypes.bool,
  isMandatory: PropTypes.bool,
  callApiOnFirstRender: PropTypes.bool,
  value: PropTypes.string,
};
const defaultProps = {
  api: () => {},
  showToolTip: false,
  data: [],
  onChangeSelect: () => {},
  name: "",
  params: {},
  disabled: false,
  showError: false,
  multi: false,
  isMandatory: false,
  callApiOnFirstRender: true,

};
const SelectBase = (props) => {
  const {
    showToolTip,
    name,
    isMandatory,
    showError,
    api,
    labelName,
    disabled,
    multi,
    value = props.multi ? [] : "",
    IconComponent,
    callApiOnFirstRender,
    ...restProps
  } = props;

  const { palette } = Palette();
  const [data, setData] = useState(props.data ? props.data : []);

  const textStyle = {
    color: palette.error.main,
    fontSize: "14px",
  };

  const handleChange = (event) => {
    const selectedValue = data.filter((item) => {
      if (item.value === event.target.value) return true;
    });

    props.onChangeSelect(
      multi ? event.target.value : selectedValue[0],
      props.name
    );
  };

  const useGet = createUseGet({
    api,
  });

  const { request } = useGet({
    onSuccess: ({ data: apiData }) => {
      setData((prev) => {
        if (!apiData) {
          return [...prev];
        }
        return [...apiData];
      });
    },
    onFail: (e) => {
      console.log("error is", e);
    },
  });

  const getDependencyArray = () => {
    let dependencyArray = [];

    if (api) {
      dependencyArray.push(api);
      if (props.params) {
        const apiParamsKeys = Object.keys(props.params);
        apiParamsKeys.map((key) => {
          dependencyArray.push(props.params[key]);
        });
      }
    }
    return dependencyArray;
  };

  useEffect(() => {
    if (api && callApiOnFirstRender) {
      request({
        ...props.params,
      });
    }
  }, getDependencyArray());

  const shouldShowError = () => {
    if (showError && !disabled) return true;
    return false;
  };
  return (
    <>
      <TextField
        {...restProps}
        id="filled-select-currency"
        label={ <>
        {labelName}
        {isMandatory && <sup style={textStyle}>*</sup>}
      </>
      }
        select
        value={value}
        onChange={handleChange}
        variant="outlined"
        size="small"
        fullWidth
        InputLabelProps={{ shrink: true }}
        SelectProps={{
          multiple: multi,
        }}
        error={shouldShowError()}
        {...(shouldShowError() && { helperText: showError })}
        disabled={disabled || data.length <= 0}
        placeholder={
          data.length <= 0 ? "No values to display" : "Please select value"
        }
      >
        {data.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.value}
          </MenuItem>
        ))}
      </TextField>
    </>
  );
};

const areEqual = (prevProps, nextProps) => {
  const preventRender =
    _.isEqual(prevProps.params, nextProps.params) &&
    prevProps.value == nextProps.value &&
    prevProps.showError == nextProps.showError &&
    prevProps.disabled == nextProps.disabled;
  return preventRender;
};
SelectBase.propTypes = propTypes;
SelectBase.defaultProps = defaultProps;
export default React.memo(SelectBase, areEqual);
