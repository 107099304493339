import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PersonIcon from '@mui/icons-material/Person';
const icons = {
HomeOutlinedIcon,
DashboardCustomizeOutlinedIcon,
PeopleAltOutlinedIcon,
AccountBalanceWalletIcon
  };

export const MenuItemss = [
    {
        "label": "Member",
        "url": null,
        "Icon":icons.PeopleAltOutlinedIcon,
        "MenuType" : "MainMenu",
        "list": [
            {
                "label": "Master",
                "url": null,
                "list": [
                    {
                        "label": "View Member",
                        "url": "member/master/view-member"
                    },
                    {
                        "label": "Cadre Structure",
                        "url": "member/master/cadre-structure"
                    },
                    {
                        "label": "Promotion Structure",
                        "url": "member/master/promotion-structure"
                    }
                ]
            },
            {
                "label": "Transaction",
                "url": null,
                "list": [
                    {
                        "label": "Add Member",
                        "url": "member/transaction/add-member"
                    },
                    {
                        "label": "Edit Member",
                        "url": "member/transaction/edit-application"
                    }
                ]
            }
        ]
    },
    {
        "label": "Investment",
        "url": null,
        "Icon" : icons.AccountBalanceWalletIcon,
        "MenuType" : "MainMenu",
        "list": [
            {
                "label": "Investment Master",
                "url": null,
                "list": [
                    {
                        "label": "View FD",
                        "url": "investment/master/view-fd"
                    },
                    {
                        "label": "View RD",
                        "url": "investment/master/view-rd"
                    },
                    {
                        "label": "View Savings",
                        "url": "investment/master/view-savings"
                    }
                ]
            },
            {
                "label": "Investment Transaction",
                "url": null,
                "list": [
                    {
                        "label": "Add FD",
                        "url": "investment/master/add-fd"
                    },
                    {
                        "label": "Add RD",
                        "url": "investment/master/add-rd"
                    },
                    {
                        "label": "Add Savings",
                        "url": "investment/master/add-savings"
                    }
                ]
            }
        ]
    },
]