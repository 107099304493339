import { fork, call, put, takeLatest, select } from "redux-saga/effects";
import { toast } from "react-toastify";
import { FETCH_MEMBER_PROMOTE_Details, SUBMIT_PROMOTE_MEMBER_DETAILS } from "store/types";
import { fetchMemberPromoteDetailsSuccess, setFetchError } from "./reducers";
// import { setGuarantorMemberId } from "../reducers";
import { dispatch } from "store";
import { getCredentials } from "utils/helper";

export function* fetchMemberPromoteDetailsFlow(value: any):any {
  const memberId = value.value.id;
  const guarantorNumber = value.value.guarantorNumber
  // console.log("SagamemberId", memberId)
  const payload = {
    memberId,
  };

  try {
    
    const { accessToken } = yield select(getCredentials);
    const response: Response = yield call(
      fetch,
      `${process.env.REACT_APP_BASE_URL}/cos-user-onboarding/member/getMemberMasterByMemberId`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(payload),
      }
    );

    const MemberdetailsResponse: any = yield response.json();
    if (MemberdetailsResponse.code !== 200) {
      throw new Error(MemberdetailsResponse.message);
    } else {
      const newObj = {
        ...MemberdetailsResponse.responseObject,
        guarantorNumber: guarantorNumber
      }
      yield dispatch(fetchMemberPromoteDetailsSuccess(newObj));
      toast.success(MemberdetailsResponse.message);
    }
  } catch (e: any) {
    yield put(setFetchError(e.message || "Something went wrong !!"));
    toast.error(e.message || "Something went wrong !!");
  }
}

function* submitPromoteMemberDetailsFlow(value: any):any{

  const memberId = value.value.id;
  // const guarantorNumber = value.value.guarantorNumber
  console.log("SagamemberId", memberId)
  const payload = {
    memberId,
  };

  try {
    const { accessToken } = yield select(getCredentials);
    const response: Response = yield call(
      fetch,
      `${process.env.REACT_APP_BASE_URL}/cos-user-onboarding/advisor/getPromotionDetailsBymemberId`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(payload),
      }
    );
  } catch(e:any) {
    console.log("Error",e)
  }
}

function* checkForDuplicateMember(memberId:any):any {
  const checkPoints = ["guarantor1", "guarantor2", "TaggedTo"];
  //fn to check if payload member id is not present in checkpoints
  const memberPromotionData:any = yield select(state => state.PromoteMemberMaster);
  console.log('aesrdtfgyh', memberPromotionData);
  let flag = true;
  checkPoints.forEach(point => {
    // eslint-disable-next-line eqeqeq
    if(memberPromotionData[point].memberId == memberId){
      flag = false;
    }
  })

  return !flag;
}

function* fetchMemberPromoteDetailsSaga({ payload: value }: any):any {
  const isMemberDuplicate = yield call(checkForDuplicateMember, value.id);

  // if true  then return error and don't run below function
  if(isMemberDuplicate === true){
    // yield put(setError("Member already exists"))
    toast.error("MemberAlreadyexist")
    return
  } else {
    yield call(fetchMemberPromoteDetailsFlow, {
      value,
    });
    
  }
  
  console.log("dfsghjmgfd",value)
}

function* submitPromoteMemberDetailsSaga({payload: value}: any) {
  yield call(submitPromoteMemberDetailsFlow, {
    value,
  });
}

function* fetchMemberPromoteDetails(action: any) {
  yield fork(fetchMemberPromoteDetailsSaga, action);
}

function* submitPromoteMemberDetails(action: any) {
  yield fork(submitPromoteMemberDetailsSaga, action)
}

export default function* saga() {
  yield takeLatest(FETCH_MEMBER_PROMOTE_Details, fetchMemberPromoteDetails);
  yield takeLatest(SUBMIT_PROMOTE_MEMBER_DETAILS, submitPromoteMemberDetails);
}
