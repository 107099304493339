import { calculateAge } from 'utils/calculateAge';
import * as yup from 'yup';

const productDetailSchema = yup.object().shape({
    creationDate: yup.string().typeError("Creation date is required").required('Creation date is required'),
    amount: yup.number().typeError("Amount is required").required('Amount is required').min(1, 'Amount must be greater than or equal to 0').max(1000000000,"Enter less amount"),
  });

  const nomineeSchema = yup.object().shape({
    name: yup.string().required('Nominee name is required'),
    percentage: yup.number().typeError("Percentage is required") .required('Nominee percentage is required').min(1,"Minimum percentage 1 is required").max(100),
    relation: yup.string().required('Nominee relation is required'),
    dob: yup.string().required('Nominee DOB is required'),
    pincode: yup.string().required('Nominee pincode is required'),
    state: yup.string().required('Nominee state is required'),
    city: yup.string().required('Nominee city is required'),
    address: yup.string().required('Nominee address is required'),
  
    guardian: yup.object().when('dob', {
      is: (dob:string) => {
        const age = calculateAge(dob);
        return age < 18;
      },
      then: yup.object().shape({
        relationShip:yup.string().required('Relationship is required'),
        name: yup.string().required('Guardian name is required'),
        address: yup.string().required('Guardian address is required'),
        state: yup.string().required('Guardian state is required'),
        contactNo:yup.string().required("Guardian contact No is required"),
        city: yup.string().required('Guardian city is required'),
        pincode: yup.string().required('Guardian pincode is required'),
        dob: yup.string().required('Guardian DOB is required'),
      }).required('Guardian details are required'),
      otherwise: yup.object().nullable(),
    }),
    // guardian: yup.object().shape({
    //   name: yup.string(),
    //   address: yup.string(),
    //   state: yup.string(),
    //   city: yup.string(),
    //   pincode: yup.string(),
    //   dob: yup.string(),
    // }).nullable(),
  });

export const productMemberDetailsSchema = yup.object().shape({
  member: yup.mixed().nullable().required('Member ID is required'),
  productCategoryId:yup.mixed().nullable().required('Category type is required'),
  productNameValue:yup.mixed().nullable().required('Product name is required'),
  productDetail: productDetailSchema,
  commssionAgentForAccount:yup.mixed().nullable().required('Commission Agent is required'),
  // nomineeDetails: yup.array().of(nomineeSchema).min(1, 'At least one nominee is required'),
  totalPercentage: yup.number()
  .required("Total percentage is required")
  .test('is-100', 'Total percentage must be exactly 100', value => value === 100),

});
