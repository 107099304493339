import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Grid from "components/Grid";
import Checkbox from "components/Checkbox";
import FormControlLabel from "components/FormControlLabel";

interface WitnessCheckBoxProps {
  label: string;
  onChange: any;
  index: any;
  checked: boolean;
}

const WitnessCheckBox: React.FC<WitnessCheckBoxProps> = ({
  label,
  onChange,
  index,
  checked,
}) => {
  const dispatch = useDispatch();
  const addNewMember = useSelector((state: any) => state.addNewMember);
  return (
    <FormControlLabel
      sx={{ minWidth: "100%" }}
      control={
        <Checkbox
          disableRipple
          onChange={onChange}
          checked={checked}
          size="small"
        />
      }
      label={label}
    />
  );
};

export default WitnessCheckBox;
