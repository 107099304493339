import React, { useEffect } from "react";
import { CustomBox, Grid, Select, TextFieldBase } from "components";
import { useSelector, useDispatch } from "react-redux";
import { SPACING } from "containers/members/constant";
import { clearErrors, setFormData, setSelectProduct } from "./reducers";
import { GET_PRODUCT_NAME, GET_PRODUCT_DETAILS } from "store/types";
import Investment from "services/api/investment";
import ShowErrorMessage from "containers/members/showErrorMessage";

function SelectProduct() {
  const savingAccount = useSelector((state: any) => state.savingAccount);
  const dispatch = useDispatch();

  const selectProductCategory = (value: any) => {
    dispatch({
      type: GET_PRODUCT_NAME,
      payload: { productCategoryId: value?.id },
    });
    dispatch(
      setFormData({ name: "productCategoryValue", value: value?.value })
    );
    dispatch(setFormData({ name: "productCategoryId", value: value?.id }));
    dispatch(clearErrors({ name: "productCategoryId" }));
  };

  const selectProductName = (value: any) => {
    dispatch({
      type: GET_PRODUCT_DETAILS,
      payload: {
        productCategoryId: savingAccount?.productCategoryId,
        productId: value?.id,
      },
    });
    dispatch(setFormData({ name: "productNameValue", value: value?.value }));
    dispatch(setFormData({ name: "productId", value: value?.id }));
    dispatch(clearErrors({ name: "productNameValue" }));
  };
  const handleInputChnage = (value: string, name: any) => {
    dispatch(setSelectProduct({ name, value }));
  };

  return (
    <div>
      <CustomBox label="Select Product">
        <Grid container spacing={SPACING}>
          <Grid item xs={12} lg={3}>
            <Select
              disabled={savingAccount?.isProductDetailsView}
              multi={false}
              name="productCategoryId"
              labelName={"Category Type"}
              api={Investment.getProductCategoryTypeList}
              onChangeSelect={selectProductCategory}
              value={savingAccount?.productCategoryValue}
            />
            {savingAccount?.validationError &&
              savingAccount?.validationError.hasOwnProperty(
                "productCategoryId"
              ) && (
                <ShowErrorMessage
                  message={savingAccount?.validationError?.productCategoryId}
                />
              )}
          </Grid>
          <Grid item xs={12} lg={3}>
            <Select
              name="productId"
              multi={false}
              labelName={"Product Name"}
              api={Investment.getProductNameList}
              params={{ productCategoryId: savingAccount?.productCategoryId }}
              data={savingAccount?.productNameList}
              onChangeSelect={selectProductName}
              value={savingAccount?.productNameValue}
              disabled={savingAccount?.isProductDetailsView}
            />
            {savingAccount?.validationError &&
              savingAccount?.validationError.hasOwnProperty(
                "productNameValue"
              ) && (
                <ShowErrorMessage
                  message={savingAccount?.validationError?.productNameValue}
                />
              )}
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextFieldBase
              disabled
              name="minPrincipalAmount"
              type="number"
              placeHolder={"₹5000"}
              labelName={"Minimum Amount"}
              onChange={handleInputChnage}
              value={savingAccount?.selectProductDetails?.minPrincipalAmount}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextFieldBase
              disabled
              name="MaxPrincipalAmount"
              type="number"
              placeHolder={"₹50,000"}
              labelName={"Maximum Amount"}
              onChange={handleInputChnage}
              value={savingAccount?.selectProductDetails?.MaxPrincipalAmount}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextFieldBase
              disabled
              name="maxCashDisb"
              type="number"
              placeHolder={"₹5000"}
              labelName={"Maximum Withdrawal"}
              onChange={handleInputChnage}
              value={savingAccount?.selectProductDetails?.maxCashDisb}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextFieldBase
              disabled
              name="interest"
              type="number"
              placeHolder={"4.5%"}
              labelName={"Interest Rate"}
              onChange={handleInputChnage}
              value={savingAccount?.selectProductDetails?.interest}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextFieldBase
              disabled
              name="isPassbookReq"
              placeHolder={"4.5%"}
              labelName={"E-Passbook"}
              onChange={handleInputChnage}
              value={savingAccount?.selectProductDetails?.isPassbookReq}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextFieldBase
              disabled
              name="isSecured"
              placeHolder={"4.5%"}
              labelName={"Insurable"}
              onChange={handleInputChnage}
              value={savingAccount?.selectProductDetails?.isSecured}
            />
          </Grid>
        </Grid>
      </CustomBox>
    </div>
  );
}

export default SelectProduct;
