// import { StrictMode } from 'react';
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

// scroll bar
import "simplebar/src/simplebar.css";

// third-party
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { HistoryRouter as Router } from "redux-first-history/rr6";

// apex-chart
import "assets/third-party/apex-chart.css";

// project import
import App from "./App";
import { store, persistor, history } from "store";
import reportWebVitals from "./reportWebVitals";

// ==============================|| MAIN - REACT DOM RENDER  ||============================== //

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!)
root.render(
  // <StrictMode>
  <ReduxProvider store={store}>
    <Router history={history}>
      <PersistGate loading={null} persistor={persistor}>
        {/* <BrowserRouter> */}
          <App />
        {/* </BrowserRouter>   */}
      </PersistGate>
    </Router>
  </ReduxProvider>
  // </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
