import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import FiltersContainer from "components/FilterContainer";

import {
  Box,
  Button,
  CustomBox,
  Grid,
  TextFieldBase,
  Typography,
} from "components";
import SearchButton from "components/SearchButton";
import { setFilterData, setFormData } from "../reducers";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  nextNumber: {
    color: "#fff",
    border: "1px solid #1F7A8C",
    backgroundColor: "#1F7A8C",
    marginLeft: "5px",
    maxWidth: "150px",
    width: "100%",
    "&:hover": {
      color: "#fff",
      border: "1px solid #1F7A8C",
      backgroundColor: "#1F7A8C",
    },
  },
});

function FilterCom({ handleOpenTable }: any) {
  const dispatch = useDispatch();
  const classess = useStyles();
  const savingAccount = useSelector((state: any) => state.savingAccount);

  const handleFilterInputChange = (value: string, name: any) => {
    dispatch(setFilterData({ name: name, value: value }));
  };

  const handleAddNewProduct = () => {
    dispatch(setFormData({ name: "isAddNewProduct", value: true }));
  };

  return (
    <FiltersContainer label="Filters" style={{ padding: "8px 24px" }}>
      <Grid container spacing={1.5} sx={{ paddingTop: "8px" }}>
        <Grid item xs={12} sm={4} md={3} lg={2}>
          <TextFieldBase
            labelName="Member Id"
            name="memberId"
            onChange={handleFilterInputChange}
            value={savingAccount.filters.memberId}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={3} lg={2}>
          <TextFieldBase
            labelName="Account No."
            name="savingAccountNumber"
            onChange={handleFilterInputChange}
            value={savingAccount.filters.savingAccountNumber}
          />
        </Grid>

        <Grid item xs={12} sm={4} lg={2}>
          <TextFieldBase
            labelName="Mobile No."
            name="mobileNo"
            onChange={handleFilterInputChange}
            value={savingAccount.filters.mobileNo}
          />
        </Grid>

        <Grid item xs={12} sm={4} lg={2}>
          <TextFieldBase
            labelName="Range"
            name="adhaar"
            onChange={handleFilterInputChange}
            value={savingAccount.filters.adhaar}
          />
        </Grid>

        <Grid item xs={12} sm={4} lg={2} sx={{ paddingTop: "10px" }}>
          <SearchButton handleOpenTable={handleOpenTable} />
        </Grid>
        <Grid item xs={12} sm={4} lg={2}>
          <Button
            className={classess.nextNumber}
            size="medium"
            onClick={handleAddNewProduct}
            startIcon={<AddIcon />}
            fullWidth
            variant="contained"
          >
            Add Product
          </Button>
        </Grid>
      </Grid>
      {}
    </FiltersContainer>
  );
}

export default FilterCom;
