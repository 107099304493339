export const ROUTES = Object.freeze({
    LOGIN: '/login',
    SAMPLE: '/sample-page',
    VERIFY_OTP: '/verifyotp',
    FORGOT_PASSWORD: '/forgotpassword',
    MEMBERS: "/members",
    ADD_MEMBER: "/member/transaction/add-member",
    VIEW_MEMBER: "/member/master/member",
    VIEW_APPLICATION: "/member/transaction/application",
    HOME: '/Home',
    GOV_STRUCTURE: '/Structure/master/view-gen-structure',
    PINCODE: '/add-pincode',
    INVESTMENT_TRANSACTION_CREATE_PRODUCT:'/Investment/transaction/Create-product',
    VIEW_PRODUCT: "/Investment/master/view-product",
    ADD_PRODUCT: "/Investment/master/add-product"
});