import * as _ from "lodash";
import { toast } from "react-toastify";
import moment from "moment";

export const getCredentials = (storeState) => {
  return {
    accessToken: _.get(storeState, "auth.jwtToken", null)
  };
};

export const downloadFile = ({ uri, name = "", extension = "" }) => {
  try {
    const downloadLink = document.createElement("a");
    downloadLink.href = uri;
    const Extension = extension || uri.split(";")[0].split("/")[1];
    const Name = name || `file_${new Date().getTime()}`;
    downloadLink.download = `${Name}.${Extension}`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  } catch (error) {
    toast.error("Unable to download file!", error);
  }
};

export const getCurrentFinancialYear = () => {
  const currentDate = moment();
  const startOfFinancialYear = currentDate
    .clone()
    .subtract(currentDate.month() < 3 ? 1 : 0, "year")
    .startOf("year")
    .add(3, "month");
  const currentFinancialYear =
    startOfFinancialYear.format("YYYY") +
    "-" +
    startOfFinancialYear.clone().add(1, "year").format("YYYY");
  return currentFinancialYear;
};
