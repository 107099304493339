/* eslint-disable no-unused-vars */
// material-ui
import { useState, useCallback, useEffect } from 'react';
import * as React from 'react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import Dropzone from 'react-dropzone';
import Grid2 from '@mui/material/Unstable_Grid2';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { makeStyles } from '@mui/styles';
import { Tooltip } from '@mui/material';

// project import
import Palette from 'themes/palette';
import Preview from './Preview';

const useStyles = makeStyles(() => ({
    centerText: {
        textAlign: 'center',
        justifyContent: 'center',
        marginTop: '10px'
    },
    button: {
        backgroundColor: '#1F6A93',
        color: '#fff',
        border: '1px solid #1F6A93',
        '&:hover': {
            backgroundColor: '#fff',
            color: '#1F6A93'
        }
    }
}));

const FileUploader = (props) => {
    const saveImages = props.value || [];
    const [showImage, setShowImage] = useState(false);
    const [myDetails, setMyDetails] = useState('');

    const { palette } = Palette();
    const classes = useStyles();

    const boxStyledDropZone = {
        border: `1px solid ${palette.grey[300]}`,
        backgroundColor: '#fff',
        borderRadius: '6px',
        height: '252px',
        display: 'flex',
        alignItems: 'stretch',
        width: '100%'
    };
    const textStyle = {
        color: '#595959'
    };
    const handleShowImageUp = (image) => {
        setShowImage((prev) => !prev);
        setMyDetails(
            Object.assign(image, {
                preview: URL.createObjectURL(image)
            })
        );
    };

    const onDrop = useCallback(
        (acceptedFiles) => {
            console.log('current files', saveImages.length, acceptedFiles);
            acceptedFiles.forEach((file, i) => {
                if (saveImages.length > 4) {
                    return;
                }
                props.onChange([...saveImages, file], props.name);

                const reader = new FileReader();
                reader.onabort = () => console.log('file reading was aborted');
                reader.onerror = () => console.log('file reading has failed');
                reader.onload = () => {
                    const binaryStr = reader.result;
                };
                reader.readAsArrayBuffer(file);
            });
        },
        [saveImages]
    );

    const deleteImage = (idx) => {
        console.log(idx);
        let imagesPresent = Array.from(saveImages);
        imagesPresent.splice(idx, 1);
        props.onChange(imagesPresent, props.name);
    };

    const handlePaste = (event) => {
        const items = event.clipboardData.items;
        for (let i = 0; i < items.length; i++) {
            const item = items[i];

            const blob = item.getAsFile();
            if (blob !== null) {
                if (saveImages.length > 4) {
                    return;
                }
                console.log('Pasted file:', blob);
                props.onChange([...saveImages, blob], props.name);
            }
        }
    };

    useEffect(() => {
        document.addEventListener('paste', handlePaste);
        return () => {
            document.removeEventListener('paste', handlePaste);
        };
    }, [saveImages]);
    console.log(saveImages, '%%%%%%%%%%%%%%%%');

    return (
        <>
            {showImage && <Preview myDetails={myDetails} showImage={showImage} setShowImage={setShowImage} />}
            {props.type == 'button' && (
                <>
                    <Dropzone onDrop={onDrop}>
                        {({ getRootProps, getInputProps }) => (
                            <Box {...getRootProps()}>
                                <input {...getInputProps()} />
                                <Button
                                    variant="contained"
                                    size={'small'}
                                    className={classes.button}
                                    startIcon={<AttachFileIcon style={{ fontSize: '14px' }} />}
                                >
                                    Attachments
                                </Button>
                            </Box>
                        )}
                    </Dropzone>
                </>
            )}
            {props.type == 'box' && (
                <>
                    <Typography sx={textStyle}>{'Attachments'}</Typography>
                    <Box style={boxStyledDropZone} component="form" sx={{ p: '2px 4px', textAlign: 'justify', mt: 1 }}>
                        <Dropzone onDrop={onDrop} accept="image/png, image/gif" multiple={true}>
                            {({ getRootProps, getInputProps }) => (
                                <>
                                    <Grid2 container spacing={2} style={{ width: '100%' }}>
                                        <Grid2
                                            xs={12}
                                            md={12}
                                            {...getRootProps()}
                                            style={{
                                                backgroundColor: palette.secondary.lighter,
                                                borderRadius: '8px',
                                                padding: '10px',
                                                height: 'calc(100% - 24px)',
                                                width: 170,
                                                display: 'grid',
                                                justifyContent: 'center',
                                                margin: 12
                                            }}
                                        >
                                            <input {...getInputProps()} />
                                            <p style={{ margin: 0, display: 'flex', alignItems: 'end' }}>
                                                <CloudUploadIcon
                                                    fontSize="small"
                                                    style={{ marginRight: '0.3rem', transform: 'translateY(-2px)' }}
                                                />
                                                <span>Drag and Drop</span>
                                            </p>

                                            <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Or</span>
                                            <Button style={{ maxHeight: 30 }} variant="contained" size={'small'} className={classes.button}>
                                                Upload File
                                            </Button>
                                        </Grid2>
                                        <Box
                                            style={{
                                                width: 'calc(100% - 245px)',
                                                marginTop: '10px'
                                            }}
                                        >
                                            {saveImages.length === 0 ? (
                                                <Typography
                                                    variant="caption"
                                                    sx={{
                                                        fontSize: '12px',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        textAlign: 'center',
                                                        color: palette.secondary.main,
                                                        marginTop: 14
                                                    }}
                                                >
                                                    Click here to copy-paste
                                                </Typography>
                                            ) : (
                                                <>
                                                    {saveImages &&
                                                        saveImages.map((file, fileIndex) => {
                                                            return (
                                                                <Typography variant="h6">
                                                                    <Box
                                                                        style={{
                                                                            display: 'flex'
                                                                        }}
                                                                    >
                                                                        <Box
                                                                            style={{
                                                                                width: '75%',
                                                                                textOverflow: 'ellipsis',
                                                                                overflow: 'hidden',
                                                                                whiteSpace: 'nowrap',
                                                                                lineHeight: 2,
                                                                                marginTop: '10px'
                                                                            }}
                                                                        >
                                                                            <Tooltip title={file.name} placement="top">
                                                                                <span>{file.name}</span>
                                                                            </Tooltip>
                                                                        </Box>
                                                                        <Box
                                                                            style={{
                                                                                width: '20%',
                                                                                display: 'flex',
                                                                                marginTop: '10px'
                                                                            }}
                                                                        >
                                                                            <IconButton
                                                                                variant="outlined"
                                                                                style={{ color: palette.span.lightBlue }}
                                                                                onClick={() => handleShowImageUp(file)}
                                                                            >
                                                                                <VisibilityOutlinedIcon />
                                                                            </IconButton>
                                                                            <IconButton
                                                                                // className={classes.cancelIcon}
                                                                                variant="outlined"
                                                                                style={{ color: palette.span.lightRed }}
                                                                                onClick={() => deleteImage(fileIndex)}
                                                                            >
                                                                                <ClearIcon />
                                                                            </IconButton>
                                                                        </Box>
                                                                    </Box>
                                                                </Typography>
                                                            );
                                                        })}
                                                </>
                                            )}
                                        </Box>
                                    </Grid2>
                                </>
                            )}
                        </Dropzone>
                    </Box>
                </>
            )}{' '}
        </>
    );
};
export default FileUploader;
