import { Grid } from "@mui/material";
import CustomBox from "../../../../components/Box/CustomBox";
import SearchableSelectBase from "../../../../components/SearchableSelect/index";
import React, { useEffect, useState } from "react";
import { SPACING } from "containers/members/constant";
import Ujala from "services/api/ujala";
import TextFieldBase from "components/TextField";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, setReferalData } from "containers/members/reducers";
import { MEMBER_DETAILS_BY_ID } from "store/types";
import ShowErrorMessage from "containers/members/showErrorMessage";

interface NomineeChangeDetails {
  name: string;
  value: string;
  index?: number;
  thirdParam?: any;
}

function ReferalComponent() {
  const dispatch = useDispatch();
  const addNewMember = useSelector((state: any) => state.addNewMember);
  const [params, setParamss] = useState("");

  const handleNomineeInputChange = (value: string, name: any) => {
    if (!name) return;
    dispatch(setReferalData({ name, value }));
    dispatch(clearErrors({ name: `referral.${name}` }));
  };

  const onTextInputChange = (value: any) => {
    setParamss(value);
  };

  const onSearchableSelect = (selected: any, index?: any, label?: string) => {
    dispatch(setReferalData({ name: "member", value: selected }));
    const payload = {
      memberId: selected?.id,
      index: index,
      label,
    };

    if (selected?.id) {
      dispatch({
        type: MEMBER_DETAILS_BY_ID,
        payload: payload,
      });
    }
  };

  return (
    <div>
      <CustomBox style={{}} label={"Referral"}>
        <Grid container spacing={SPACING}>
          <Grid item xs={12} sm={4} md={3} lg={2}>
            <SearchableSelectBase
              value={addNewMember.referral.member}
              labelName={"MemberId"}
              onTextInputChange={onTextInputChange}
              onChangeSelect={(value) =>
                onSearchableSelect(value, "", "referalMemberId")
              }
              placeHolder={"MemberId"}
              params={{ memberId: params }}
              callApiOnFirstRender={true}
              api={Ujala.getMemberList}
            />
            {addNewMember?.formErrors &&
              addNewMember?.formErrors.hasOwnProperty("referral.memberId") && (
                <div style={{ marginTop: "8px" }}>
                  <ShowErrorMessage
                    message={addNewMember?.formErrors["referral.memberId"]}
                  />
                </div>
              )}
          </Grid>
          <Grid item xs={12} sm={4} md={3} lg={2}>
            <TextFieldBase
              disabled
              name="firstName"
              placeHolder={"Name"}
              labelName={"Name"}
              onChange={handleNomineeInputChange}
              value={addNewMember?.referral?.firstName}
              showError={
                addNewMember?.formErrors &&
                addNewMember?.formErrors.hasOwnProperty("referral.firstName")
              }
            />
            {addNewMember?.formErrors &&
              addNewMember?.formErrors.hasOwnProperty("referral.firstName") && (
                <ShowErrorMessage
                  message={addNewMember?.formErrors["referral.firstName"]}
                />
              )}
          </Grid>
          <Grid item xs={12} sm={4} md={3} lg={2}>
            <TextFieldBase
              disabled
              name="type"
              placeHolder={"Type"}
              labelName={"Type"}
              onChange={handleNomineeInputChange}
              value={addNewMember.referral.type}
              showError={
                addNewMember?.formErrors &&
                addNewMember?.formErrors.hasOwnProperty("referral.type")
              }
            />
            {addNewMember?.formErrors &&
              addNewMember?.formErrors.hasOwnProperty("referral.type") && (
                <ShowErrorMessage
                  message={addNewMember?.formErrors["referral.type"]}
                />
              )}
          </Grid>
          <Grid item xs={12} sm={4} md={3} lg={2}>
            <TextFieldBase
              disabled
              name="level"
              placeHolder={"Level"}
              labelName={"Level"}
              onChange={handleNomineeInputChange}
              value={addNewMember?.referral?.level}
              showError={
                addNewMember?.formErrors &&
                addNewMember?.formErrors.hasOwnProperty("referral.level")
              }
            />
            {addNewMember?.formErrors &&
              addNewMember?.formErrors.hasOwnProperty("referral.level") && (
                <ShowErrorMessage
                  message={addNewMember?.formErrors["referral.level"]}
                />
              )}
          </Grid>
          <Grid item xs={12} sm={4} md={3} lg={2}>
            <TextFieldBase
              disabled
              name="mobileNo"
              type="number"
              placeHolder={"Mobile No"}
              labelName={"Mobile No"}
              onChange={handleNomineeInputChange}
              value={addNewMember?.referral?.mobileNo}
              showError={
                addNewMember?.formErrors &&
                addNewMember?.formErrors.hasOwnProperty("referral.mobileNo")
              }
            />
            {addNewMember?.formErrors &&
              addNewMember?.formErrors.hasOwnProperty("referral.mobileNo") && (
                <ShowErrorMessage
                  message={addNewMember?.formErrors["referral.mobileNo"]}
                />
              )}
          </Grid>
          <Grid item xs={12} sm={4} md={3} lg={2}>
            <TextFieldBase
              disabled
              name="branchId"
              placeHolder={"BranchId"}
              labelName={"BranchId"}
              onChange={handleNomineeInputChange}
              value={addNewMember?.referral?.branchId}
              showError={
                addNewMember?.formErrors &&
                addNewMember?.formErrors.hasOwnProperty("referral.branchId")
              }
            />
            {addNewMember?.formErrors &&
              addNewMember?.formErrors.hasOwnProperty("referral.branchId") && (
                <ShowErrorMessage
                  message={addNewMember?.formErrors["referral.branchId"]}
                />
              )}
          </Grid>
        </Grid>
      </CustomBox>
    </div>
  );
}

export default ReferalComponent;
